import { call, put, takeLatest } from 'redux-saga/effects';
import { CategoryApi } from '../../api/category/categoryApi';
import { CategoryActionTypesUnion, CategoryActionTypes } from './ICategoryActionTypes';
import { categoryActions } from './categoryActions';
import { CategoryRequest } from '../../models/category/CategoryRequest';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseResponse } from '../../base/BaseResponse';

// Saga for creating or updating a category
function* handleCreateOrUpdateCategory(action: CategoryActionTypesUnion) {
	try {
		// Ensure the action type is for create or update
		if (action.type === CategoryActionTypes.CREATE_OR_UPDATE_REQUEST) {
			const response: BaseResponse = yield call(CategoryApi.CreateOrUpdateAsync, action.payload as CategoryRequest);
			yield put(categoryActions.createOrUpdateSuccess(response.result));
		}
	} catch (error: any) {
		yield put(categoryActions.createOrUpdateFailure(error.message));
	}
}

// Saga for fetching category pagination
function* handleFetchCategoryPagination(action: any) {
	try {
		console.log('action',action.type)
		if (action.type === CategoryActionTypes.FETCH_PAGINATION_REQUEST) {
			const response: BaseResponse = yield call(CategoryApi.SelectPaginationAsync, action.payload);
			console.log('response',response)
			yield put(categoryActions.fetchPaginationSuccess(response.result));
		}
	} catch (error: any) {
		console.log('error', error)
		yield put(categoryActions.fetchPaginationFailure(error.message));
	}
}

export function* categorySaga() {
	yield takeLatest(CategoryActionTypes.CREATE_OR_UPDATE_REQUEST, handleCreateOrUpdateCategory);
	yield takeLatest(CategoryActionTypes.FETCH_PAGINATION_REQUEST, handleFetchCategoryPagination);
}
