import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { IActionTypeBase } from "../../base/IBaseActionTypes";
import { ProductCategoryModel } from "../../models/products-category/ProductCategoryModel";

export enum eProductCategoryActionTypeIds {
    GET_ALL_REQUEST = "ProductCategory_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "ProductCategory_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "ProductCategory_GET_ALL_FAILURE",

    GET_PAGINATION_REQUEST = "ProductCategory_GET_PAGINATION_REQUEST",
    GET_PAGINATION_SUCCESS = "ProductCategory_GET_PAGINATION_SUCCESS",
    GET_PAGINATION_FAILURE = "ProductCategory_GET_PAGINATION_FAILURE",

    SAVE_REQUEST = "ProductCategory_SAVE_REQUEST",
    SAVE_SUCCESS = "ProductCategory_SAVE_SUCCESS",
    SAVE_FAILURE = "ProductCategory_SAVE_FAILURE",

    DELETE_REQUEST = "ProductCategory_DELETE_REQUEST",
    DELETE_SUCCESS = "ProductCategory_DELETE_SUCCESS",
    DELETE_FAILURE = "ProductCategory_DELETE_FAILURE",

    NEED_RELOAD = "ProductCategory_NEED_RELOAD",

    CHANGE_SELECTED_IDS = "ProductCategory_CHANGE_SELECTED_IDS",
    SHOW_DETAIL = "ProductCategory_SHOW_DETAIL",
    SHOW_CONFIRM = "ProductCategory_SHOW_CONFIRM",
}

export interface ProductCategoryAction_GETALL_Request extends IActionTypeBase<eProductCategoryActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface ProductCategoryAction_GETALL_Success extends IActionTypeBase<eProductCategoryActionTypeIds.GET_ALL_SUCCESS, ProductCategoryModel[]> { }
export interface ProductCategoryAction_GETALL_Failure extends IActionTypeBase<eProductCategoryActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export interface ProductCategoryAction_GETPAGINATION_Request extends IActionTypeBase<eProductCategoryActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> { }
export interface ProductCategoryAction_GETPAGINATION_Success extends IActionTypeBase<eProductCategoryActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<ProductCategoryModel>> { }
export interface ProductCategoryAction_GETPAGINATION_Failure extends IActionTypeBase<eProductCategoryActionTypeIds.GET_PAGINATION_FAILURE, Error | string> { }

export interface ProductCategoryAction_SAVE_Request extends IActionTypeBase<eProductCategoryActionTypeIds.SAVE_REQUEST, ProductCategoryModel> { }
export interface ProductCategoryAction_SAVE_Success extends IActionTypeBase<eProductCategoryActionTypeIds.SAVE_SUCCESS, string> { }
export interface ProductCategoryAction_SAVE_Failure extends IActionTypeBase<eProductCategoryActionTypeIds.SAVE_FAILURE, Error | string> { }

export interface ProductCategoryAction_DELETE_Request extends IActionTypeBase<eProductCategoryActionTypeIds.DELETE_REQUEST, number[]> { }
export interface ProductCategoryAction_DELETE_Success extends IActionTypeBase<eProductCategoryActionTypeIds.DELETE_SUCCESS, string> { }
export interface ProductCategoryAction_DELETE_Failure extends IActionTypeBase<eProductCategoryActionTypeIds.DELETE_FAILURE, Error | string> { }

export interface ProductCategoryAction_RELOAD extends IActionTypeBase<eProductCategoryActionTypeIds.NEED_RELOAD, undefined> { }

export interface ProductCategoryAction_SHOW_DETAIL extends IActionTypeBase<eProductCategoryActionTypeIds.SHOW_DETAIL, BaseShowDetail<ProductCategoryModel>> { }
export interface ProductCategoryAction_CHANGE_SELECTED_IDS extends IActionTypeBase<eProductCategoryActionTypeIds.CHANGE_SELECTED_IDS, number[]> { }
export interface ProductCategoryAction_SHOW_CONFIRM extends IActionTypeBase<eProductCategoryActionTypeIds.SHOW_CONFIRM, boolean> { }

export type ProductCategoryActionTypes = ProductCategoryAction_GETALL_Request | ProductCategoryAction_GETALL_Success | ProductCategoryAction_GETALL_Failure |
    ProductCategoryAction_GETPAGINATION_Request | ProductCategoryAction_GETPAGINATION_Success | ProductCategoryAction_GETPAGINATION_Failure |
    ProductCategoryAction_SAVE_Request | ProductCategoryAction_SAVE_Success | ProductCategoryAction_SAVE_Failure |
    ProductCategoryAction_DELETE_Request | ProductCategoryAction_DELETE_Success | ProductCategoryAction_DELETE_Failure | ProductCategoryAction_RELOAD |
    ProductCategoryAction_SHOW_DETAIL | ProductCategoryAction_CHANGE_SELECTED_IDS | ProductCategoryAction_SHOW_CONFIRM 