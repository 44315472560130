import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupKeywordSeo } from '../useLookupKeywordSeo';

type IComboBoxKeywordSeoProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value?: any) => void;
	onDataChanged: (value?: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
};

const ComboBoxKeywordSeo = ({ onValueChanged, onDataChanged, placeholder, ...rest }: IComboBoxKeywordSeoProps) => {
	const { keyword_seo_all } = useLookupKeywordSeo();

	return (
		<DxSelectBox
			dataSource={keyword_seo_all}
			valueExpr={'idHash'}
			displayExpr={'name'}
			{...rest}
			placeholder={placeholder || 'Chọn từ khóa'}
			isClearable={true}
			onValueChanged={(value) => {
				onValueChanged(value);
				const dataSelected = keyword_seo_all?.find((x) => x.idHash == value);
				onDataChanged(dataSelected);
			}}
		/>
	);
};
export default ComboBoxKeywordSeo;
