import { BasePagination } from "../../base/BasePaginationRespone";
import { RoleScopeModel } from "../../models/roles/RoleScopeModel";
import { IActionTypeBase } from "../../base/IBaseActionTypes";

export enum eRoleScopesActionTypeIds {
    GET_ALL_REQUEST = "RoleScopes_GET_ALL_REQUEST",
    GET_ALL_SUCCESS = "RoleScopes_GET_ALL_SUCCESS",
    GET_ALL_FAILURE = "RoleScopes_GET_ALL_FAILURE",
}

export interface RoleScopesAction_GETALL_Request extends IActionTypeBase<eRoleScopesActionTypeIds.GET_ALL_REQUEST, undefined> { }
export interface RoleScopesAction_GETALL_Success extends IActionTypeBase<eRoleScopesActionTypeIds.GET_ALL_SUCCESS, { data: RoleScopeModel[], pagination?: BasePagination }> { }
export interface RoleScopesAction_GETALL_Failure extends IActionTypeBase<eRoleScopesActionTypeIds.GET_ALL_FAILURE, Error | string> { }

export type RoleScopesActionTypes = RoleScopesAction_GETALL_Request | RoleScopesAction_GETALL_Success | RoleScopesAction_GETALL_Failure 