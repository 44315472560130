import * as Yup from 'yup';

export type StoreLocationModel = {
	id: number;
	name: string;
	address: string;
	lat: number;
	long: number;
	phone_Number: string;
	open_Hour: string;
	close_Hour: string;
	image: string;
};

export const dfStoreLocationModel: StoreLocationModel = {
	id: 0,
	name: '',
	address: '',
	lat: 0,
	phone_Number: '',
	long: 0,
	open_Hour: '',
	close_Hour: '',
	image: '',
};

export const validStoreLocationSchema = () =>
	Yup.object().shape({
		name: Yup.string().required('Vui lòng nhập tên.'),
		address: Yup.string().required('Vui lòng nhập địa chỉ.'),
		lat: Yup.number().required('Vui lòng toạ độ.'),
		long: Yup.number().required('Vui lòng toạ độ.'),
		phone_Number: Yup.string().required('Vui lòng nhập số điện thoại.'),
		open_Hour: Yup.string().required('Vui lòng nhập giờ mở cửa.'),
		close_Hour: Yup.string().required('Vui lòng nhập giờ đóng cửa.'),
	});
