import React from 'react'
import error404 from '../../assets/images/error-404.svg'
import { NavLink } from 'react-router-dom'


export const NotFound = () => {
    return (
        <div className='nk-block nk-block-middle wide-md mx-auto'>
            <div className='nk-block-content nk-error-ld text-center'>
                <img className='nk-error-gfx' src={error404} alt='' />
                <div className='wide-xs mx-auto'>
                    <h3 className='nk-error-title'>
                        Nội dung truy cập không tồn tại hoặc đang phát triển !!!
                    </h3>
                    <NavLink to='/' className='btn btn-md btn-primary mt-2'>
                        Trở lại trang chủ
                    </NavLink>
                </div>
            </div>
        </div>
    )
}