import * as Yup from 'yup';
export type CollectionGroupModel = {
    id: number;
    name: string;
    title: string;
    sapo: string;
    icon: string;
    image: string;
    type: number;
    stt:number
}

export const dfCollectionGroupModel: CollectionGroupModel = {
    id: 0,
    name: '',
    title: '',
    sapo: '',
    icon: '',
    image: '',
    type:   0,
    stt:0
}

export const validCollectionGroupSchema = () => Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập tên nhóm Bộ sưu tập.'),
    type: Yup.number().required('Vui lòng nhập vị trí.'),
});