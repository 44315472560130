import * as Yup from 'yup';

export type ProductImageModel = {
    productId: number;
    id: number;
    isDefault: string;
    title: string;
    description: string;
    inGallery: boolean;
    sortOrder: number;
    attachFile: boolean;
    fileName: string;
    filePath: string;
    fileDescription: string;
    extension: string;
    size: string;
    path: string;
};

export const dfProductImageModel: ProductImageModel = {
    productId: 0,
    id: 0,
    isDefault: '',
    title: '',
    description: '',
    inGallery: false,
    sortOrder: 0,
    attachFile: false,
    fileName: '',
    filePath: '',
    fileDescription: '',
    extension: '',
    size: '',
    path: '',
};

export const validProductImageSchema = () => Yup.object().shape({
    path: Yup.string().required('Vui lòng upload ảnh.'),
});