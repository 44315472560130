import { ApiClient } from "../../base/_ApiClient";
import { MenuRemoveRoleRequest, MenuUpdateRoleMultipleRequest, MenuUpdateRoleRequest } from "../../models/menus/MenuUpdateRoleRequest";

export const MenuApi = {
    GetAllAsync: () => {
        return ApiClient.GET('/menus');
    },
    ChangeRoleForMenuAsync: (data: MenuUpdateRoleRequest) => {
        return ApiClient.POST(`/menus/update-role-for-menu`, data);
    },
    ChangeRoleForMenuMultipleAsync: (data: MenuUpdateRoleMultipleRequest) => {
        return ApiClient.POST(`/menus/update-role-for-menu-multiple`, data);
    },
    RemoveRoleForMenusAsync: (data: MenuRemoveRoleRequest) => {
        return ApiClient.POST(`/menus/remove-role-for-menu`, data);
    },
};