import React from 'react';

interface INumberBoxProps {
	name: string;
	value: number;
	onValueChanged: (value: number) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
}

export const NumberBox = (props: INumberBoxProps) => {
	const { value, error, name, label, sizeClass, isDisabled, helpBlock, labelSmall, hasValid, placeholder, onValueChanged } = props;
	const showError = error ? true : false;
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<input
						id={name}
						type={'number'}
						name={name}
						value={value}
						disabled={isDisabled}
						placeholder={placeholder}
						onChange={(e) => onValueChanged(parseInt(e.target.value || '0'))}
						className={`form-control form-control-${sizeClass || 'xs'} ${showError ? 'error' : ''}`}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
