import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
import { ArticleChangeStatusRequest } from "../../models/articles/ArticleChangeStatusRequest";
import { ArticleModel } from "../../models/articles/ArticleModel";
import { ArticleSelectRequest } from "../../models/articles/ArticleSelectRequest";

export const ArticleApi = new class ArticleApi extends ApiBase<ArticleModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    ChangeStatusAsync(request: ArticleChangeStatusRequest) {
        return ApiClient.POST(`${this._baseUrl}/change-article-status`, request);
    }
    SelectPaginationByActionAsync(request: ArticleSelectRequest) {
        const params: any = request;
        return ApiClient.GET(`${this._baseUrl}/select-by-action${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
    }
    SelectPaginationByActionOwnerAsync(request: ArticleSelectRequest) {
        const params: any = request;
        return ApiClient.GET(`${this._baseUrl}/select-by-owner-action${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
    }
    SelectPaginationPublishedAsync(request: ArticleSelectRequest) {
        const params: any = request;
        return ApiClient.GET(`${this._baseUrl}/select-published${request ? `?${new URLSearchParams(params).toString()}` : ''}`);
    }
}({ baseUrl: '/articles' });