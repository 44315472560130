import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { AppState } from '../../../context/stores/rootReducers';
import { useEffect } from 'react';
import { actions } from '../../../context/stores/rootActions';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';

export function useLookupCollection() {
	const dispatch = useDispatch();
	const { status, allCollections } = useSelector((state: AppState) => state.collection);
	useEffect(() => {
		if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload){
		const data = {groupID:1}
		dispatch(actions.collection.getAllRequest(data));
		//eslint-disable-next-line react-hooks/exhaustive-deps
		}
	}, []);
	return { collection_all: allCollections };
}
