import { BasePaginationRespone } from "../../base/BasePaginationRespone";
import { BaseSelectRequest } from "../../base/BaseSelectRequest";
import { BaseShowDetail } from "../../base/BaseShowDetail";
import { ProductCategoryModel } from "../../models/products-category/ProductCategoryModel";
import { BaseAction } from "../../base/IBaseActionRespone";
import { ProductCategoryAction_CHANGE_SELECTED_IDS, ProductCategoryAction_DELETE_Failure, ProductCategoryAction_DELETE_Request, ProductCategoryAction_DELETE_Success, ProductCategoryAction_GETALL_Failure, ProductCategoryAction_GETALL_Request, ProductCategoryAction_GETALL_Success, ProductCategoryAction_GETPAGINATION_Failure, ProductCategoryAction_GETPAGINATION_Request, ProductCategoryAction_GETPAGINATION_Success, ProductCategoryAction_RELOAD, ProductCategoryAction_SAVE_Failure, ProductCategoryAction_SAVE_Request, ProductCategoryAction_SAVE_Success, ProductCategoryAction_SHOW_CONFIRM, ProductCategoryAction_SHOW_DETAIL, eProductCategoryActionTypeIds } from "./IProductCategoryActionsTypes";

export const productCategoryActions = {
    //ALL
    getAllRequest: (): ProductCategoryAction_GETALL_Request => BaseAction(eProductCategoryActionTypeIds.GET_ALL_REQUEST, undefined),
    getAllSuccess: (data: ProductCategoryModel[]): ProductCategoryAction_GETALL_Success => BaseAction(eProductCategoryActionTypeIds.GET_ALL_SUCCESS, data),
    getAllFailure: (error: Error | string): ProductCategoryAction_GETALL_Failure => BaseAction(eProductCategoryActionTypeIds.GET_ALL_FAILURE, error),
    //LIST
    getPaginationRequest: (request: BaseSelectRequest): ProductCategoryAction_GETPAGINATION_Request => BaseAction(eProductCategoryActionTypeIds.GET_PAGINATION_REQUEST, request),
    getPaginationSuccess: (data: BasePaginationRespone<ProductCategoryModel>): ProductCategoryAction_GETPAGINATION_Success => BaseAction(eProductCategoryActionTypeIds.GET_PAGINATION_SUCCESS, data),
    getPaginationFailure: (error: Error | string): ProductCategoryAction_GETPAGINATION_Failure => BaseAction(eProductCategoryActionTypeIds.GET_PAGINATION_FAILURE, error),
    //SAVE
    saveRequest: (entity: ProductCategoryModel): ProductCategoryAction_SAVE_Request => BaseAction(eProductCategoryActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): ProductCategoryAction_SAVE_Success => BaseAction(eProductCategoryActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): ProductCategoryAction_SAVE_Failure => BaseAction(eProductCategoryActionTypeIds.SAVE_FAILURE, error),
    //DELETE
    deleteRequest: (ids: number[]): ProductCategoryAction_DELETE_Request => BaseAction(eProductCategoryActionTypeIds.DELETE_REQUEST, ids),
    deleteSuccess: (message: string): ProductCategoryAction_DELETE_Success => BaseAction(eProductCategoryActionTypeIds.DELETE_SUCCESS, message),
    deleteFailure: (error: Error | string): ProductCategoryAction_DELETE_Failure => BaseAction(eProductCategoryActionTypeIds.DELETE_FAILURE, error),
    //RELOAD
    needReload: (): ProductCategoryAction_RELOAD => BaseAction(eProductCategoryActionTypeIds.NEED_RELOAD, undefined),
    //ACTIONs
    changeSelectedIds: (ids: number[]): ProductCategoryAction_CHANGE_SELECTED_IDS => BaseAction(eProductCategoryActionTypeIds.CHANGE_SELECTED_IDS, ids),
    showDetail: (detail: BaseShowDetail<ProductCategoryModel>): ProductCategoryAction_SHOW_DETAIL => BaseAction(eProductCategoryActionTypeIds.SHOW_DETAIL, detail),
    showConfirm: (show: boolean): ProductCategoryAction_SHOW_CONFIRM => BaseAction(eProductCategoryActionTypeIds.SHOW_CONFIRM, show)
}