import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { RolesAction_DELETE_Request, RolesAction_GETALL_Request, RolesAction_GETPAGINATION_Request, RolesAction_SAVE_Request, eRolesActionTypeIds } from "./IRolesActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { RoleResponse } from "../../models/roles/RoleModel";

function* onLoadAllRoles(action: RolesAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.role, rootApi.role.GetAllAsync]);
        if (res.isSuccess && res.result) {
            const listRoles = res.result as RoleResponse[];
            yield put(actions.roles.getAllSuccess(listRoles));
        }
        else {
            yield put(actions.roles.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.roles.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationRoles(action: RolesAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.role, rootApi.role.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listRoles = res.result as RoleResponse[];
            yield put(actions.roles.getPaginationSuccess({ listDatas: listRoles, pagination: res.pagination }));
        }
        else {
            yield put(actions.roles.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.roles.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveRoles(action: RolesAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([rootApi.role, rootApi.role.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.roles.saveSuccess(res.message));
                yield put(actions.roles.needReload());
            }
            else {
                yield put(actions.roles.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([rootApi.role, rootApi.role.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.roles.saveSuccess(res.message));
                yield put(actions.roles.needReload());
            }
            else {
                yield put(actions.roles.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.roles.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteRoles(action: RolesAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.role, rootApi.role.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.roles.deleteSuccess(res.message));
            yield put(actions.roles.needReload());
        }
        else {
            yield put(actions.roles.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.roles.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllRoles() {
    yield takeEvery(eRolesActionTypeIds.GET_ALL_REQUEST, onLoadAllRoles);
}

function* watchOnLoadPaginationRoles() {
    yield takeEvery(eRolesActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationRoles);
}

function* watchOnSaveRoles() {
    yield takeEvery(eRolesActionTypeIds.SAVE_SAVING, onSaveRoles);
}

function* watchOnDeleteRoles() {
    yield takeEvery(eRolesActionTypeIds.DELETE_SAVING, onDeleteRoles);
}

function* rolesSaga() {
    yield all([fork(watchOnLoadAllRoles)]);
    yield all([fork(watchOnLoadPaginationRoles)]);
    yield all([fork(watchOnSaveRoles)]);
    yield all([fork(watchOnDeleteRoles)]);
}

export default rolesSaga;