import React from 'react';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { eProductStatus, getProductStatusName } from '../../../context/models/products/eProductStatus';

type IChangeStatusConfirmProps = {
    isShow: boolean;
    isLoading: boolean;
	mesageText?: string;
    actionStatus: eProductStatus;
    selectedIds: any[];
    onClose: () => void;
    onConfirmStatus: () => void;
}

export const ChangeStatusConfirm = (props: IChangeStatusConfirmProps) => {
    const { isShow, isLoading, actionStatus, selectedIds, onConfirmStatus, onClose, mesageText } = props;

	return (
		<>
			<ConfirmModal
				show={isShow}
				innerText={mesageText ? mesageText : `Bạn chắc chắn muốn ${getProductStatusName(actionStatus)} ${selectedIds.length} sản phẩm đã chọn ?`}
				type='primary'
				onClose={() => onClose()}
				onConfirmed={() => onConfirmStatus()}
				isLoading={isLoading}
				btnConfirmText={getProductStatusName(actionStatus)}
				btnCloseText='Hủy'
			/>
		</>
	);
};
