import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupCollectionGroup } from '../useLookupCollectionGroup';

type IComboBoxCollectionGroupProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
};

const ComboBoxCollectionGroup = (props: IComboBoxCollectionGroupProps) => {
	const { collection_group_all } = useLookupCollectionGroup();

	return (
		<DxSelectBox
			dataSource={collection_group_all}
			valueExpr={'id'}
			displayExpr={'name'}
			{...props}
			placeholder={props.placeholder || 'Chọn danh mục'}
			isClearable={true}
		/>
	);
};
export default ComboBoxCollectionGroup;