import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ArticleActionConfirmRequest } from '../../../context/models/articles/ArticleActionConfirmRequest';
import { ArticleItemResponse, ArticleModel } from '../../../context/models/articles/ArticleModel';
import { ArticleSelectRequest } from '../../../context/models/articles/ArticleSelectRequest';
import { eArticleStatus, getArticleStatusName } from '../../../context/models/articles/eArticleStatus';
import { BasePaginationRespone } from '../../../context/base/BasePaginationRespone';
import { BaseShowDetailId } from '../../../context/base/BaseShowDetail';
import { ArticleActionTypes } from '../../../context/stores/articles/IArticleActionsTypes';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';
import ArticleTable from '../article-table';

type IArticlePublishedPageProps = {
	titlePage: string;
	articleStatus: eArticleStatus;
	langCode: string;
	isOwner: boolean;
	status: eBaseActionStatus;
	paginationResponse: BasePaginationRespone<ArticleItemResponse>;
	selectedIds: string[];
	showDetail: BaseShowDetailId<string>;
	showConfirm: ArticleActionConfirmRequest;
	handleReloadData: (request: ArticleSelectRequest) => void;
	handleGetDetail: (id: string) => void;
	handleSaveData: (data: ArticleModel) => void;
	handleDeleteData: (data: string[]) => void;
	onChangeSelectedIds: (ids: string[]) => void;
	handleChangeStatus: (data: ArticleActionConfirmRequest) => void;
	onShowDetail: (data: BaseShowDetailId<string>) => void;
	onShowConfirm: (data: ArticleActionConfirmRequest) => void;
};

const ArticlePublishedPage = (props: IArticlePublishedPageProps) => {
	return <ArticleTable {...props}  />
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Bài viết ${getArticleStatusName(eArticleStatus.PUBLISHED)}`,
		articleStatus: eArticleStatus.PUBLISHED,
		isOwner: false,
		langCode: jwtTokenHelper.LANGCODE(),
		status: state.article.status,
		paginationResponse: state.article.paginationResponse,
		selectedIds: state.article.selectedIds,
		showDetail: state.article.showDetail,
		showConfirm: state.article.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<ArticleActionTypes>) => ({
	handleReloadData: (request: ArticleSelectRequest) => {
		dispatch(actions.article.getPublishedRequest(request));
	},
	handleGetDetail: (id: string) => {
		dispatch(actions.article.detailRequest(id));
	},
	handleSaveData: (data: ArticleModel) => {
		dispatch(actions.article.saveRequest(data));
	},
	handleDeleteData: (ids: string[]) => {
		dispatch(actions.article.deleteRequest(ids));
	},
	handleChangeStatus: (detail: ArticleActionConfirmRequest) => {
		dispatch(
			actions.article.changeStatusRequest({
				actionStatus: detail.actionStatus,
				articleIds: detail.ids || [],
				receivedBy: detail.receivedBy || '',
				message: detail.message || '',
				subject: detail.subject || '',
			})
		);
	},
	onChangeSelectedIds: (ids: string[]) => {
		dispatch(actions.article.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetailId<string>) => {
		dispatch(actions.article.showDetail(data));
	},
	onShowConfirm: (detail: ArticleActionConfirmRequest) => {
		dispatch(actions.article.showConfirm(detail));
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticlePublishedPage);
