import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { rootApi } from "../../api/rootApi";
import { BaseResponse } from "../../base/BaseResponse";
import { ProductModel } from "../../models/products/ProductModel";
import { actions } from "../rootActions";
import { ProductAction_CHANGE_STATUS_Request, ProductAction_DELETE_Request, ProductAction_GETALL_Request, ProductAction_GETBY_ACTION_Request, ProductAction_GETBY_OWNER_ACTION_Request, ProductAction_GETPAGINATION_Request, ProductAction_GETPUBLISHED_Request, ProductAction_GET_DETAIL_Request, ProductAction_SAVE_Request, ProductAction_SHOW_DETAIL, eProductActionTypeIds } from "./IProductActionsTypes";

const _sagaApi = rootApi.product;

function* onLoadAllProduct(action: ProductAction_GETALL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listProduct = res.result as ProductModel[];
			yield put(actions.product.getAllSuccess(listProduct));
		} else {
			yield put(actions.product.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.product.getAllFailure(error || 'Có lỗi'));
	}
}

function* onLoadPaginationProduct(action: ProductAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listProduct = res.result as ProductModel[];
            yield put(actions.product.getPaginationSuccess({ listDatas: listProduct, pagination: res.pagination }));
        }
        else {
            yield put(actions.product.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onLoadDetailProduct(action: ProductAction_GET_DETAIL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetByIdAsync], action.payload);
        if (res.isSuccess && res.result) {
            const detail = res.result as ProductModel;
            yield put(actions.product.detailSuccess(detail));
        }
        else {
            yield put(actions.product.detailFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.detailFailure(error || 'Có lỗi'));
    }
}

function* onShowDetailProduct(action: ProductAction_SHOW_DETAIL) {
    try {
        if(action.payload.id){
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetByIdAsync], action.payload.id);
            if (res.isSuccess && res.result) {
                const detail = res.result as ProductModel;
                yield put(actions.product.detailSuccess(detail));
            }
            else {
                yield put(actions.product.detailFailure(res.message));
            }
        }else{
            yield put(actions.product.detailSuccess());
        }
    } catch (error: any) {
        yield put(actions.product.detailFailure(error || 'Có lỗi'));
    }
}

function* onLoadByActionProduct(action: ProductAction_GETBY_ACTION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectPaginationByActionAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listProduct = res.result as ProductModel[];
            yield put(actions.product.getByActionSuccess({ listDatas: listProduct, pagination: res.pagination }));
        }
        else {
            yield put(actions.product.getByActionFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.getByActionFailure(error || 'Có lỗi'));
    }
}

function* onLoadByOwnerActionProduct(action: ProductAction_GETBY_OWNER_ACTION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectPaginationByActionOwnerAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listProduct = res.result as ProductModel[];
            yield put(actions.product.getByOwnerActionSuccess({ listDatas: listProduct, pagination: res.pagination }));
        }
        else {
            yield put(actions.product.getByOwnerActionFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.getByOwnerActionFailure(error || 'Có lỗi'));
    }
}

function* onLoadPublishedProduct(action: ProductAction_GETPUBLISHED_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.SelectPaginationPublishedAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listProduct = res.result as ProductModel[];
            yield put(actions.product.getPublishedSuccess({ listDatas: listProduct, pagination: res.pagination }));
        }
        else {
            yield put(actions.product.getPublishedFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.getPublishedFailure(error || 'Có lỗi'));
    }
}

function* onSaveProduct(action: ProductAction_SAVE_Request) {
    try {
        if (BigInt(action.payload.id) > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.product.saveSuccess(res.message));
                yield put(actions.product.needReload());
            }
            else {
                yield put(actions.product.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.product.saveSuccess(res.message));
                yield put(actions.product.needReload());
            }
            else {
                yield put(actions.product.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.product.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteProduct(action: ProductAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.product.deleteSuccess(res.message));
            yield put(actions.product.needReload());
        }
        else {
            yield put(actions.product.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.deleteFailure(error || 'Có lỗi'));
    }
}

function* onChangeStatusProduct(action: ProductAction_CHANGE_STATUS_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.ChangeStatusAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.product.changeStatusSuccess(res.message));
            yield put(actions.product.needReload());
        }
        else {
            yield put(actions.product.changeStatusFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.product.changeStatusFailure(error || 'Có lỗi'));
    }
}
function* watchOnLoadAllProduct() {
	yield takeEvery(eProductActionTypeIds.GET_ALL_REQUEST, onLoadAllProduct);
}

function* watchOnLoadPaginationProduct() {
    yield takeEvery(eProductActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationProduct);
}

function* watchOnLoadDetailProduct() {
    yield takeEvery(eProductActionTypeIds.GET_DETAIL_REQUEST, onLoadDetailProduct);
}

function* watchOnLoadByActionProduct() {
    yield takeEvery(eProductActionTypeIds.GET_BY_ACTION_REQUEST, onLoadByActionProduct);
}

function* watchOnShowDetailProduct() {
    yield takeEvery(eProductActionTypeIds.SHOW_DETAIL, onShowDetailProduct);
}

function* watchOnLoadByOwnerActionProduct() {
    yield takeEvery(eProductActionTypeIds.GET_BY_OWNER_ACTION_REQUEST, onLoadByOwnerActionProduct);
}

function* watchOnLoadPublishedProduct() {
    yield takeEvery(eProductActionTypeIds.GET_PUBLISHED_REQUEST, onLoadPublishedProduct);
}

function* watchOnSaveProduct() {
    yield takeEvery(eProductActionTypeIds.SAVE_REQUEST, onSaveProduct);
}

function* watchOnDeleteProduct() {
    yield takeEvery(eProductActionTypeIds.DELETE_REQUEST, onDeleteProduct);
}

function* watchOnChangeStatusProduct() {
    yield takeEvery(eProductActionTypeIds.CHANGE_STATUS_REQUEST, onChangeStatusProduct);
}

function* productSaga() {
    yield all([fork(watchOnLoadAllProduct)]);
    yield all([fork(watchOnLoadPaginationProduct)]);
    yield all([fork(watchOnLoadDetailProduct)]);
    yield all([fork(watchOnShowDetailProduct)]);
    yield all([fork(watchOnLoadByActionProduct)]);
    yield all([fork(watchOnLoadByOwnerActionProduct)]);
    yield all([fork(watchOnLoadPublishedProduct)]);
    yield all([fork(watchOnSaveProduct)]);
    yield all([fork(watchOnDeleteProduct)]);
    yield all([fork(watchOnChangeStatusProduct)]);
}

export default productSaga;