import * as Yup from 'yup';
export type BannerPositionModel = {
	id: number;
	code: string;
	name: string;
	width: number;
	height: number;
	bannerCount: number;
	inActive: boolean;
	langCode: string;
};

export const dfBannerPositionModel: BannerPositionModel = {
	id: 0,
	code: '',
	name: '',
	width: 0,
	height: 0,
	bannerCount: 0,
	inActive: false,
	langCode: '',
};

export const validBannerPositionSchema = () =>
	Yup.object().shape({
		id: Yup.number(),
		code: Yup.string().required('Vui lòng nhập mã vị trí.'),
		name: Yup.string().required('Vui lòng nhập tên vị trí.'),
		width: Yup.number().required('Vui lòng nhập chiều dài banner.'),
		height: Yup.number(),
		bannerCount: Yup.number().required('Vui lòng nhập chiều rộng banner.'),
		inActive: Yup.bool(),
		langCode: Yup.string(),
	});
