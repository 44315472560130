import React from 'react';

interface IBlockCardProps {
	children?: any;
}

export const BlockCard = ({ children }: IBlockCardProps) => {
	return (
		<>
			<div className='nk-block'>
				<div className='card'>
					<div className='card-inner'>{children}</div>
				</div>
			</div>
		</>
	);
};
