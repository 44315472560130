import { useMemo, useState } from 'react';
import { MenuResponse } from '../../../context/models/accounts/MenuResponse';
import { useAuth } from '../../../utils/AuthProvider';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

interface MenuTree {
	id: number;
	name: string;
	path: string;
	icon: string;
	parentId: number;
	sortOrder: number;
	isActive: boolean;
	childs?: MenuTree[];
}

// Xây dựng cây menu với danh sách con (childs)
const buildTree = (parentId: number, menus: MenuResponse[]): MenuTree[] => {
	return menus
		.filter((menu) => menu.parentId === parentId) // Tìm các menu có parentId đúng
		.map((menu) => {
			// Tạo đối tượng MenuTree với childs
			const menuTree: MenuTree = {
				...menu,
				isActive: false,
				childs: buildTree(menu.id, menus), // Đệ quy để tìm các menu con
			};
			return menuTree;
		});
};

// Thêm menu "Cấu hình" với submenu "Banner"
const additionalMenus: MenuTree[] = [
	{
		id: 9999,
		name: 'Cấu hình',
		path: '/config',
		icon: 'icon ni ni-setting',
		parentId: 0,
		sortOrder: 999,
		isActive: false,
	},
	{
		id: 10000,
		name: 'Cấu hình đối tác',
		path: '/config/partner',
		icon: 'icon ni ni-setting',
		parentId: 9999,
		sortOrder: 1,
		isActive: false,
	},
	{
		id: 10001,
		name: 'Cấu hình địa điểm',
		path: '/config/store-location',
		icon: 'icon ni ni-setting',
		parentId: 9999,
		sortOrder: 1,
		isActive: false,
	},
	{
		id: 10002,
		name: 'Cấu hình khuyễn mại',
		path: '/config/promotion',
		icon: 'icon ni ni-setting',
		parentId: 9999,
		sortOrder: 1,
		isActive: false,
	},
];

export const MenuRender = () => {
	const { menus } = useAuth();

	// Kết hợp menu từ API với menu bổ sung
	const combinedMenus = menus ? [...menus, ...additionalMenus] : additionalMenus;

	// Tạo cây menu
	const menuTrees: MenuTree[] = useMemo(() => {
		const tree = buildTree(0, combinedMenus);
		console.log('tree', tree);
		return tree;
	}, [combinedMenus]);

	return <>{menuTrees && menuTrees.length > 0 && menuTrees.map((group) => <MenuGroup key={group.id} group={group} />)}</>;
};

const MenuGroup = ({ group }: { group: MenuTree }) => {
	return <>{group && <MenuItem menu={group} />}</>;
};

const MenuSub = ({ menus }: { menus: MenuTree[] }) => {
	return (
		<ul className='nk-menu-sub'>
			{menus.map((menu) => (
				<MenuItem key={menu.id} menu={menu} />
			))}
		</ul>
	);
};

const MenuItem = ({ menu }: { menu: MenuTree }) => {
	const [isActive, setIsExpanded] = useState(menu.isActive);
	const location = useLocation();
	const currentActive = useMemo(() => {
		return matchPath(location.pathname, menu.path)?.pathname === menu.path;
	}, [location, menu]);

	const toggleMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (menu.childs && menu.childs.length > 0) {
			e.preventDefault();
			setIsExpanded((prev) => !prev); // Mở rộng hoặc thu gọn menu con
		}
	};

	return (
		<li
			className={`nk-menu-item ${menu.childs && menu.childs.length > 0 ? 'has-sub' : ''} ${
				isActive || currentActive ? 'active current-page' : ''
			}`}
		>
			<NavLink
				to={menu.path}
				className={`nk-menu-link ${menu.childs && menu.childs.length > 0 ? 'nk-menu-toggle' : ''}`}
				title={menu.name}
				onClick={toggleMenu}
			>
				<span className='nk-menu-icon'>
					<em className={menu.icon || 'icon ni ni-list-thumb'}></em>
				</span>
				<span className='nk-menu-text'>{menu.name}</span>
			</NavLink>
			{/* Hiển thị menu con nếu có */}
			{isActive && menu.childs && <MenuSub menus={menu.childs} />}
		</li>
	);
};
