import { KeywordActionTypes, eKeywordActionTypeIds } from "./IKeywordActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { IKeywordState } from "./IKeywordState"
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";
import { KeywordModel } from "../../models/keyword/KeywordModel";

const initPaginationResponse: BasePaginationRespone<KeywordModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IKeywordState = {
    status: eBaseActionStatus.idle,
    allKeywords: [],
    selectedIds: [],
    showConfirm: false,
    paginationResponse: initPaginationResponse,
    showDetail: dfBaseShowDetail
}
const keywordReducer = (state: IKeywordState = initialState, action: KeywordActionTypes): IKeywordState => {
    switch (action.type) {
        case eKeywordActionTypeIds.GET_ALL_REQUEST:
        case eKeywordActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allKeywords: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                paginationResponse: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allKeywords: [],
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                paginationResponse: initPaginationResponse,
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eKeywordActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eKeywordActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eKeywordActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eKeywordActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eKeywordActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eKeywordActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eKeywordActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eKeywordActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default keywordReducer;