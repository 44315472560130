import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducers from "./rootReducers";
import rootSagas from "./rootSagas";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware]

const store = configureStore({
  reducer: rootReducers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    thunk: false,
    immutableCheck: false,
  }).concat(middleware)
});

sagaMiddleware.run(rootSagas);

export default store;