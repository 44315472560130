import { CheckBoxList } from '../../../base';
import { useLookupRoles } from '../useLookupRoles';

type ICheckedBoxListRoleProps = {
	name: string;
	selectedValue: any[];
	onSelectionChanged: (values: any[]) => void;
	label?: string;
	labelSmall?: boolean;
	hasValid?: boolean;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
};

export const CheckedBoxListRole = (props: ICheckedBoxListRoleProps) => {
	const { name, label, labelSmall, hasValid } = props;
	const { roles_lookup } = useLookupRoles();
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap`}>
					<CheckBoxList dataSource={roles_lookup} displaySubExpr='description' displayExpr='name' keyExpr='id' {...props} />
				</div>
			</div>
		</>
	);
};