import React, { useEffect, useState } from 'react';
import {
	ButtonCancel,
	ButtonSave,
	BaseForm,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	SwitchBox,
	TextBox,
} from '../../components/base';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import {
	CollectionGroupModel,
	dfCollectionGroupModel,
	validCollectionGroupSchema,
} from '../../context/models/collections/CollectionGroupModel';
import ComboBoxCollectionType from '../../components/shared/hoc-collection-type/ComboBoxCollectionType';
import { ImageBox } from '../../components/base/ImageBox';

type ICollectionGroupDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: CollectionGroupModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: CollectionGroupModel) => void;
};

const CollectionGroupDetail = (props: ICollectionGroupDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<CollectionGroupModel>(dfCollectionGroupModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<CollectionGroupModel>({
		//@ts-ignore
		resolver: yupResolver(validCollectionGroupSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: CollectionGroupModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<ComboBoxCollectionType
							name={'type'}
							error={errors.type?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, type: value }));
							}}
							value={initialValues.type}
							hasValid={true}
							label='Vị trí'
						/>
						<NumberBox
							name={'stt'}
							error={errors.stt?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, stt: value }));
							}}
							value={initialValues.stt}
							hasValid={true}
							label='Số thứ tự'
						/>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên vị trí'
						/>
						<TextBox
							name={'title'}
							error={errors.title?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, title: value }));
							}}
							value={initialValues.title}
							hasValid={true}
							label='Tiêu đề'
						/>
						<TextBox
							name={'sapo'}
							error={errors.sapo?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, sapo: value }));
							}}
							value={initialValues.sapo}
							hasValid={true}
							label='Sapo'
						/>
						<ImageBox
							name='icon'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.icon}
							onDataChanged={(icon) => {
								setInitialValues((prev) => ({
									...prev,
									icon: icon?.path || '',
								}));
							}}
							hasValid={true}
							label='Icon'
						/>
						<ImageBox
							name='image'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={initialValues.image}
							onDataChanged={(image) => {
								setInitialValues((prev) => ({
									...prev,
									image: image?.path || '',
								}));
							}}
							hasValid={true}
							label='Ảnh'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default CollectionGroupDetail;
