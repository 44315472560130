import React from 'react';

export type IComboBoxProps = {
	name: string;
	dataSource: any[];
	value: any;
	hasDefault?: boolean;
	defaultValue?: any;
	onValueChanged: (value: any) => void;
	keyExpr: string;
	displayExpr: string;
	displaySubExpr?: string;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	defaultText?: string;
};

export const ComboBox = (props: IComboBoxProps) => {
	const {
		value,
		hasDefault,
		defaultValue,
		error,
		name,
		label,
		defaultText,
		sizeClass,
		isDisabled,
		helpBlock,
		labelSmall,
		hasValid,
		dataSource,
		onValueChanged,
		keyExpr,
		displayExpr,
		displaySubExpr,
	} = props;
	const showError = error ? true : false;

	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<select
						className={`form-select form-control-${sizeClass || 'xs'} ${showError ? 'error' : ''}`}
						id={name}
						disabled={isDisabled}
						name={name}
						value={value}
						onChange={(e) => onValueChanged(e.target.value)}
					>
						{hasDefault && <option value={defaultValue || 0}>Chọn {defaultText}</option>}
						{dataSource &&
							dataSource.map((item, index) => {
								return (
									<option key={index} value={item[keyExpr]}>
										{displaySubExpr ? (
											<>
												{item[displayExpr]} {item[displaySubExpr]}
											</>
										) : (
											<>{item[displayExpr]}</>
										)}
									</option>
								);
							})}
					</select>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
