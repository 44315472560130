import { BasePagination, BasePaginationRespone } from '../../base/BasePaginationRespone'
import { BaseSelectRequest } from '../../base/BaseSelectRequest'
import { BaseShowDetail } from '../../base/BaseShowDetail'
import { BaseAction } from '../../base/IBaseActionRespone'
import { CollectionTypeModel } from '../../models/collections/CollectionTypeModel'
import { CollectionTypeAction_CHANGE_SELECTED_IDS, CollectionTypeAction_DELETE_Failure, CollectionTypeAction_DELETE_Request, CollectionTypeAction_DELETE_Success, CollectionTypeAction_GETALL_Failure, CollectionTypeAction_GETALL_Request, CollectionTypeAction_GETALL_Success, CollectionTypeAction_GETPAGINATION_Failure, CollectionTypeAction_GETPAGINATION_Request, CollectionTypeAction_GETPAGINATION_Success, CollectionTypeAction_RELOAD, CollectionTypeAction_SAVE_Failure, CollectionTypeAction_SAVE_Request, CollectionTypeAction_SAVE_Success, CollectionTypeAction_SHOW_CONFIRM, CollectionTypeAction_SHOW_DETAIL, eCollectionTypeActionTypeIds } from './ICollectionTypeActionsTypes'

export const CollectionTypeActions = {
    //ALL
    getAllRequest: (): CollectionTypeAction_GETALL_Request => BaseAction(eCollectionTypeActionTypeIds.GET_ALL_REQUEST, undefined),
    getAllSuccess: (data: CollectionTypeModel[]): CollectionTypeAction_GETALL_Success => BaseAction(eCollectionTypeActionTypeIds.GET_ALL_SUCCESS, data),
    getAllFailure: (error: Error | string): CollectionTypeAction_GETALL_Failure => BaseAction(eCollectionTypeActionTypeIds.GET_ALL_FAILURE, error),
    //LIST
    getPaginationRequest: (request: BaseSelectRequest): CollectionTypeAction_GETPAGINATION_Request => BaseAction(eCollectionTypeActionTypeIds.GET_PAGINATION_REQUEST, request),
    getPaginationSuccess: (data: BasePaginationRespone<CollectionTypeModel>): CollectionTypeAction_GETPAGINATION_Success => BaseAction(eCollectionTypeActionTypeIds.GET_PAGINATION_SUCCESS, data),
    getPaginationFailure: (error: Error | string): CollectionTypeAction_GETPAGINATION_Failure => BaseAction(eCollectionTypeActionTypeIds.GET_PAGINATION_FAILURE, error),
    //SAVE
    saveRequest: (entity: CollectionTypeModel): CollectionTypeAction_SAVE_Request => BaseAction(eCollectionTypeActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): CollectionTypeAction_SAVE_Success => BaseAction(eCollectionTypeActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): CollectionTypeAction_SAVE_Failure => BaseAction(eCollectionTypeActionTypeIds.SAVE_FAILURE, error),
    //DELETE
    deleteRequest: (ids: number[]): CollectionTypeAction_DELETE_Request => BaseAction(eCollectionTypeActionTypeIds.DELETE_REQUEST, ids),
    deleteSuccess: (message: string): CollectionTypeAction_DELETE_Success => BaseAction(eCollectionTypeActionTypeIds.DELETE_SUCCESS, message),
    deleteFailure: (error: Error | string): CollectionTypeAction_DELETE_Failure => BaseAction(eCollectionTypeActionTypeIds.DELETE_FAILURE, error),
    //RELOAD
    needReload: (): CollectionTypeAction_RELOAD => BaseAction(eCollectionTypeActionTypeIds.NEED_RELOAD, undefined),
    //ACTIONs
    changeSelectedIds: (ids: number[]): CollectionTypeAction_CHANGE_SELECTED_IDS => BaseAction(eCollectionTypeActionTypeIds.CHANGE_SELECTED_IDS, ids),
    showDetail: (detail: BaseShowDetail<CollectionTypeModel>): CollectionTypeAction_SHOW_DETAIL => BaseAction(eCollectionTypeActionTypeIds.SHOW_DETAIL, detail),
    showConfirm: (show: boolean): CollectionTypeAction_SHOW_CONFIRM => BaseAction(eCollectionTypeActionTypeIds.SHOW_CONFIRM, show)
}