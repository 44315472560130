import { ArticleCategoryActionTypes, eArticleCategoryActionTypeIds } from "./IArticleCategoryActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { IArticleCategoryState } from "./IArticleCategoryState"
import { ArticleCategoryModel } from "../../models/articles-category/ArticleCategoryModel";
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";

const initPaginationResponse: BasePaginationRespone<ArticleCategoryModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IArticleCategoryState = {
    status: eBaseActionStatus.idle,
    allArticleCategorys: [],
    selectedIds: [],
    showConfirm: false,
    paginationResponse: initPaginationResponse,
    showDetail: dfBaseShowDetail
}
const articleCategoryReducer = (state: IArticleCategoryState = initialState, action: ArticleCategoryActionTypes): IArticleCategoryState => {
    switch (action.type) {
        case eArticleCategoryActionTypeIds.GET_ALL_REQUEST:
        case eArticleCategoryActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allArticleCategorys: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                paginationResponse: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allArticleCategorys: [],
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                paginationResponse: initPaginationResponse,
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eArticleCategoryActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eArticleCategoryActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eArticleCategoryActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eArticleCategoryActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eArticleCategoryActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eArticleCategoryActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eArticleCategoryActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eArticleCategoryActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default articleCategoryReducer;