import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	PasswordBox,
	SwitchBox,
	TextBox,
} from '../../../../components/base';
import { CheckedBoxListRole, SelectBoxUserGroup } from '../../../../components/shared/system';
import { UserGroupModel } from '../../../../context/models/users-group/UserGroupModel';
import { UserModel, df_UserModel, valid_UserModel } from '../../../../context/models/users/UserModel';

type IAccountDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: UserModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: UserModel) => void;
};

const AccountDetail = (props: IAccountDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<UserModel>(df_UserModel);
	const [userGroupSelected, setUserGroupSelected] = useState<UserGroupModel | undefined>();

	const {
		handleSubmit,
		reset,
		formState: { errors, isSubmitting },
	} = useForm<UserModel>({
		//@ts-ignore
		resolver: yupResolver(valid_UserModel(isAddMode)),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: UserModel) => {
		console.log(values);
		onSubmit({ ...initialValues, ...values });
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			const { avatar, email, firstName, fullName, id, isActive, lastName, midleName, phoneNumber, roles, userGroupId, userName } =
				detailModel;
			setInitialValues((prev) => ({
				...prev,
				avatar,
				email,
				firstName,
				fullName,
				id,
				isActive,
				lastName,
				midleName,
				phoneNumber,
				roles,
				userGroupId,
				userName,
			}));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	useEffect(() => {
		if (userGroupSelected) {
			setInitialValues((prev) => ({ ...prev, roles: userGroupSelected.roleIds?.split(',').map((x: any) => parseInt(x)) }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userGroupSelected]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='xl' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<div className='row g-gs'>
							<div className='col-lg-7'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Thông tin tài khoản</h5>
										</div>
										<div className='gy-3'>
											<div className='row g-3 align-center'>
												<div className='col-lg-5'>
													<div className='form-group'>
														<label className='form-label'>
															Họ và tên
															<em className='text-danger'>(*)</em>
														</label>
														<span className='form-note'>Tên đầy đủ của tài khoản.</span>
													</div>
												</div>
												<div className='col-lg-7'>
													<TextBox
														name='fullName'
														error={errors.fullName?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, fullName: value }));
														}}
														value={initialValues.fullName}
														hasValid={true}
													/>
												</div>
											</div>

											<div className='row g-3 align-center'>
												<div className='col-lg-5'>
													<div className='form-group'>
														<label className='form-label'>
															Email
															<em className='text-danger'>(*)</em>
														</label>
													</div>
												</div>
												<div className='col-lg-7'>
													<TextBox
														name='email'
														type='email'
														error={errors.email?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, email: value }));
														}}
														value={initialValues.email}
														hasValid={true}
													/>
												</div>
											</div>

											<div className='row g-3 align-center'>
												<div className='col-lg-5'>
													<div className='form-group'>
														<label className='form-label'>
															Tài khoản
															<em className='text-danger'>(*)</em>
														</label>
														<span className='form-note'>Tên tài khoản đăng nhập hệ thống.</span>
													</div>
												</div>
												<div className='col-lg-7'>
													<TextBox
														name='userName'
														error={errors.userName?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, userName: value }));
														}}
														isDisabled={!isAddMode}
														value={initialValues.userName}
														hasValid={true}
													/>
												</div>
											</div>
											<div className='row g-3 align-center'>
												<div className='col-lg-5'>
													<div className='form-group'>
														<label className='form-label'>
															Mật khẩu
															<em className='text-danger'>(*)</em>
														</label>
														<span className='form-note'>
															{isAddMode ? 'Mật khẩu đăng nhập hệ thống.' : 'Chỉ nhập khi cần thay mật khẩu.'}
														</span>
													</div>
												</div>
												<div className='col-lg-7'>
													<PasswordBox
														name='password'
														label='Mật khẩu mới'
														labelSmall={true}
														error={errors.password?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, password: value }));
														}}
														value={initialValues.password}
														hasValid={true}
													/>
													<PasswordBox
														name='passwordConfirmed'
														label='Xác nhận mật khẩu'
														labelSmall={true}
														error={errors.passwordConfirmed?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, passwordConfirmed: value }));
														}}
														value={initialValues.passwordConfirmed}
														hasValid={true}
													/>
												</div>
											</div>
											<div className='row g-3 align-center'>
												<div className='col-lg-5'>
													<div className='form-group'>
														<label className='form-label'>Trạng thái</label>
														<span className='form-note'>Kích hoạt để khóa tài khoản.</span>
													</div>
												</div>
												<div className='col-lg-7'>
													<SwitchBox
														name='isActive'
														label='Hoạt động'
														error={errors.isActive?.message}
														onValueChanged={(value) => {
															setInitialValues((prev) => ({ ...prev, isActive: value }));
														}}
														value={initialValues.isActive}
														hasValid={true}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='col-lg-5'>
								<div className='card card-bordered h-100'>
									<div className='card-inner'>
										<div className='card-head'>
											<h5 className='card-title'>Phân quyền</h5>
										</div>
										<SelectBoxUserGroup
											name='userGroupId'
											label='Tác nhân'
											error={errors.userGroupId?.message}
											hanldeOnValueChanged={(value, data) => {
												setInitialValues((prev) => ({ ...prev, userGroupId: value }));
												setUserGroupSelected(data);
											}}
											value={initialValues.userGroupId}
											hasValid={true}
											placeholder='Chọn tác nhân'
										/>
										<CheckedBoxListRole
											name='roles'
											label='Quyền hạn'
											hasValid={true}
											error={errors.roles?.message}
											onSelectionChanged={(value: number[]) => {
												setInitialValues((prev) => ({ ...prev, roles: value?.map((x: any) => parseInt(x)) }));
											}}
											selectedValue={initialValues.roles}
										/>
									</div>
								</div>
							</div>
						</div>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default AccountDetail;
