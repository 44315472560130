import moment from 'moment';
import * as Yup from 'yup';
export type BannerModel = {
	id: number;
	name: string;
	title: string;
	description: string;
	link: string;
	target: string;
	path: string;
	inActive: boolean;
	fileName: string;
	extension: string;
	lenght: number;
	width: number;
	height: number;
	size: string;
	viewCount: number;
	clickCount: number;
	beginDate: string;
	endDate: string;
	langCode: string;
};

export const dfBannerModel: BannerModel = {
	id: 0,
	name: '',
	title: '',
	description: '',
	link: '',
	target: '',
	path: '',
	inActive: false,
	fileName: '',
	extension: '',
	lenght: 0,
	width: 0,
	height: 0,
	size: '',
	viewCount: 0,
	clickCount: 0,
	beginDate:  moment().format(),
	endDate:  moment().format(),
	langCode: '',
};

export const validBannerSchema = () =>
	Yup.object().shape({
		id:Yup.number(),
        name:Yup.string().required('Vui lòng nhập tên banner'),
        title:Yup.string().required('Vui lòng nhập tiêu đề'),
        description:Yup.string(),
        link:Yup.string(),
        target:Yup.string(),
        path:Yup.string(),
        inActive:Yup.bool(),
        fileName:Yup.string(),
        extension:Yup.string(),
        lenght:Yup.number(),
        width:Yup.number(),
        height:Yup.number(),
        size:Yup.string(),
        viewCount:Yup.number(),
        clickCount:Yup.number(),
        beginDate:Yup.string().required('Vui lòng nhập ngày đăng banner'),
        endDate:Yup.string().required('Vui lòng nhập ngày kết thúc banner'),
        langCode:Yup.string(),
	});
