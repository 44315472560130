import React, { useMemo } from 'react';
import { Button } from '../../../components/base';
import { ArticleActionRule, eArticleStatus } from '../../../context/models/articles/eArticleStatus';
type IArticleHeaderButtonProps = {
	articleStatus: eArticleStatus;
	isOwner?: boolean;
	selectedIds: string[];
	onActionConfirm: (actionChange: eArticleStatus, selectedIds: string[]) => void;
};

export const ArticleHeaderButton = ({ articleStatus, selectedIds, onActionConfirm, isOwner }: IArticleHeaderButtonProps) => {
	const actionsRule = useMemo(() => {
		return ArticleActionRule({ status: articleStatus, isOwner: isOwner || false });
	}, [articleStatus, isOwner]);

	return (
		<>
			{actionsRule.includes(eArticleStatus.DELETED) && (
				<>
					<li>
						<Button
							text={`Xóa (${selectedIds.length})`}
							icon='icon ni ni-trash'
							className='d-md-inline-flex'
							theme='danger'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.DELETED, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.RESTORE) && (
				<>
					<li>
						<Button
							text={`Khôi phục (${selectedIds.length})`}
							icon='icon ni ni-history'
							className='d-md-inline-flex'
							theme='secondary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.RESTORE, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.RETURN) && (
				<>
					<li>
						<Button
							text={`Thu hồi (${selectedIds.length})`}
							icon='icon ni ni-wallet-in'
							className='d-md-inline-flex'
							theme='gray'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.RETURN, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.BACK) && (
				<>
					<li>
						<Button
							text={`Trả bài (${selectedIds.length})`}
							icon='icon ni ni-reply-fill'
							className='d-md-inline-flex'
							theme='secondary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.BACK, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.REMOVED) && (
				<>
					<li>
						<Button
							text={`Hủy xuất bản (${selectedIds.length})`}
							icon='icon ni ni-spark-off-fill'
							className='d-md-inline-flex'
							theme='danger'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.REMOVED, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.EDITING) && (
				<>
					<li>
						<Button
							text={`Nhận biên tập (${selectedIds.length})`}
							icon='icon ni ni-wallet-in'
							className='d-md-inline-flex'
							theme='info'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.EDITING, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.APPROVING) && (
				<>
					<li>
						<Button
							text={`Nhận duyệt (${selectedIds.length})`}
							icon='icon ni ni-wallet-in'
							className='d-md-inline-flex'
							theme='info'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.APPROVING, selectedIds)}
						/>
					</li>
				</>
			)}
			{actionsRule.includes(eArticleStatus.PUBLISHED) && (
				<>
					<li>
						<Button
							text={`Xuất bản (${selectedIds.length})`}
							icon='icon ni ni-spark-fill'
							className='d-md-inline-flex'
							theme='primary'
							isDisabled={selectedIds.length <= 0}
							onClick={() => onActionConfirm(eArticleStatus.PUBLISHED, selectedIds)}
						/>
					</li>
				</>
			)}
		</>
	);
};
