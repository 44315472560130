import moment from 'moment';
import * as Yup from 'yup';

export type PromotionModel = {
	id: number;
	name: string;
	code: string;
	permalink: string;
	image: string;
	type: number;
	value: number;
	startTime: string;
	endTime: string;
};

export const dfPromotionModel: PromotionModel = {
	id: 0,
	name: '',
	code: '',
	permalink: '',
	image: '',
	type: 1,
	value: 0,
	startTime: moment().format(),
	endTime: moment().format(),
};

export const validPromotionSchema = () =>
	Yup.object().shape({
		name: Yup.string().required('Vui lòng nhập tên.'),
		code: Yup.string().required('Vui lòng nhập mã.'),
		image: Yup.string().required('Vui lòng nhập ảnh.'),
		permalink: Yup.string().required('Vui lòng nhập link.'),
		startTime: Yup.string().required('Vui lòng nhập date.'),
		endTime: Yup.string().required('Vui lòng nhập date.'),
	});
