import { IUsersState } from "./IUsersState"
import { UsersActionTypes, eUsersActionTypeIds } from "./IUsersActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone"
import { UserModel } from "../../models/users/UserModel"

const initUsersPagination: BasePaginationRespone<UserModel> = { listDatas: [], pagination: dfBasePagination };

const initialState: IUsersState = {
    status: eBaseActionStatus.idle,
    allUsers: [],
    listUsersPagination: initUsersPagination,
    showDetail: { isShow: false },
    selectedIds: [],
    showConfirm: false
}
const usersReducer = (state: IUsersState = initialState, action: UsersActionTypes): IUsersState => {
    switch (action.type) {
        case eUsersActionTypeIds.GET_ALL_REQUEST:
        case eUsersActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allUsers: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                listUsersPagination: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allUsers: [],
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                listUsersPagination: initUsersPagination,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eUsersActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eUsersActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eUsersActionTypeIds.SAVE_SAVING:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.DELETE_SAVING:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Thành công !!!');
            return {
                ...state,
                showDetail: { isShow: false },
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa thành công !!!');
            return {
                ...state,
                status: BaseGetActionStatus(action),
                selectedIds: [],
                showConfirm: false
            }
        case eUsersActionTypeIds.SAVE_FAILURE:
        case eUsersActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString() || 'Không thành công !!!');
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eUsersActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default usersReducer;