export type ProductSelectRequest = {
    searchString: string;
    catId: number;
    status: string;
    langCode: string;
}

export const df_ProductSelectRequest: ProductSelectRequest = {
    catId: 0,
    langCode: '',
    searchString: '',
    status: ''
}