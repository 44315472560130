

import { RoleModel } from "../../models/roles/RoleModel";
import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
export const RoleApi = new class RoleApi extends ApiBase<RoleModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    SelectAllRoleScopesAsync() {
        return ApiClient.GET(`${this._baseUrl}/role-scopes`)
    }
}({ baseUrl: '/roles' });
