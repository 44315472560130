import { BaseShowDetail } from "../../base/BaseShowDetail";
import { BaseAction } from "../../base/IBaseActionRespone";
import { ProductImageModel } from "../../models/products-image/ProductImageModel";
import { ProductImageAction_CHANGE_SELECTED_IDS, ProductImageAction_DELETE_Failure, ProductImageAction_DELETE_Request, ProductImageAction_DELETE_Success, ProductImageAction_GETALL_Failure, ProductImageAction_GETALL_Request, ProductImageAction_GETALL_Success, ProductImageAction_RELOAD, ProductImageAction_SAVE_Failure, ProductImageAction_SAVE_Request, ProductImageAction_SAVE_Success, ProductImageAction_SHOW_CONFIRM, ProductImageAction_SHOW_DETAIL, eProductImageActionTypeIds } from "./IProductImageActionsTypes";


export const productImageActions = {
    //ALL
    getAllRequest: (product_id: number): ProductImageAction_GETALL_Request => BaseAction(eProductImageActionTypeIds.GET_ALL_REQUEST, product_id),
    getAllSuccess: (data: ProductImageModel[]): ProductImageAction_GETALL_Success => BaseAction(eProductImageActionTypeIds.GET_ALL_SUCCESS, data),
    getAllFailure: (error: Error | string): ProductImageAction_GETALL_Failure => BaseAction(eProductImageActionTypeIds.GET_ALL_FAILURE, error),
    //SAVE
    saveRequest: (entity: ProductImageModel): ProductImageAction_SAVE_Request => BaseAction(eProductImageActionTypeIds.SAVE_REQUEST, entity),
    saveSuccess: (message: string): ProductImageAction_SAVE_Success => BaseAction(eProductImageActionTypeIds.SAVE_SUCCESS, message),
    saveFailure: (error: Error | string): ProductImageAction_SAVE_Failure => BaseAction(eProductImageActionTypeIds.SAVE_FAILURE, error),
    //DELETE
    deleteRequest: (ids: number[]): ProductImageAction_DELETE_Request => BaseAction(eProductImageActionTypeIds.DELETE_REQUEST, ids),
    deleteSuccess: (message: string): ProductImageAction_DELETE_Success => BaseAction(eProductImageActionTypeIds.DELETE_SUCCESS, message),
    deleteFailure: (error: Error | string): ProductImageAction_DELETE_Failure => BaseAction(eProductImageActionTypeIds.DELETE_FAILURE, error),
    //RELOAD
    needReload: (): ProductImageAction_RELOAD => BaseAction(eProductImageActionTypeIds.NEED_RELOAD, undefined),
    //ACTIONs
    changeSelectedIds: (ids: number[]): ProductImageAction_CHANGE_SELECTED_IDS => BaseAction(eProductImageActionTypeIds.CHANGE_SELECTED_IDS, ids),
    showDetail: (detail: BaseShowDetail<ProductImageModel>): ProductImageAction_SHOW_DETAIL => BaseAction(eProductImageActionTypeIds.SHOW_DETAIL, detail),
    showConfirm: (show: boolean): ProductImageAction_SHOW_CONFIRM => BaseAction(eProductImageActionTypeIds.SHOW_CONFIRM, show)
}