import * as Yup from 'yup';

export type RoleResponse = {
    id: number;
    name: string;
    description: string;
    isActive: boolean;
    isProtected: boolean;
    roleScopes: string[];
    roleScopeIds: number[]
}

export type RoleModel = {
    id: number;
    name: string;
    description: string;
    isActive: boolean;
    isProtected: boolean;
    roleScopeIds: number[];
}

export const df_RoleModel: RoleModel = {
    id: 0,
    name: '',
    description: '',
    isActive: false,
    roleScopeIds: [],
    isProtected: false
}

export const valid_RoleModel = () => Yup.object().shape({
    id: Yup.number(),
    name: Yup.string().required('Vui lòng nhập tên quyền'),
    description: Yup.string().required('Vui lòng nhập mô tả quyền'),
    sortOrder: Yup.number(),
    roleScopeIds: Yup.array(Yup.number()).required('Vui lòng chọn quyền hạn'),
    isActive: Yup.boolean(),
});