import * as Yup from 'yup';

export type ConfigPartnerModel = {
	id: number;
	link: string;
	image: string;
	title: string;
};

export const dfConfigPartnerModel: ConfigPartnerModel = {
	id: 0,
	title: '',
	link: '',
	image: '',
};

export const validConfigPartnerSchema = () =>
	Yup.object().shape({
		title: Yup.string().required('Vui lòng nhập tên.'),
		link: Yup.string().required('Vui lòng nhập link.'),
		image: Yup.string().required('Vui lòng nhập ảnh.'),
	});
