import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetailId } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { ProductActionConfirmRequest } from '../../models/products/ProductActionConfirmRequest';
import { ProductChangeStatusRequest } from '../../models/products/ProductChangeStatusRequest';
import { ProductModel } from '../../models/products/ProductModel';
import { ProductSelectRequest } from '../../models/products/ProductSelectRequest';
import {
	ProductAction_CHANGE_SELECTED_IDS,
	ProductAction_CHANGE_STATUS_Failure,
	ProductAction_CHANGE_STATUS_Request,
	ProductAction_CHANGE_STATUS_Success,
	ProductAction_DELETE_Failure,
	ProductAction_DELETE_Request,
	ProductAction_DELETE_Success,
	ProductAction_GETALL_Failure,
	ProductAction_GETALL_Request,
	ProductAction_GETALL_Success,
	ProductAction_GETBY_ACTION_Failure,
	ProductAction_GETBY_ACTION_Request,
	ProductAction_GETBY_ACTION_Success,
	ProductAction_GETBY_OWNER_ACTION_Failure,
	ProductAction_GETBY_OWNER_ACTION_Request,
	ProductAction_GETBY_OWNER_ACTION_Success,
	ProductAction_GETPAGINATION_Failure,
	ProductAction_GETPAGINATION_Request,
	ProductAction_GETPAGINATION_Success,
	ProductAction_GETPUBLISHED_Failure,
	ProductAction_GETPUBLISHED_Request,
	ProductAction_GETPUBLISHED_Success,
	ProductAction_GET_DETAIL_Failure,
	ProductAction_GET_DETAIL_Request,
	ProductAction_GET_DETAIL_Success,
	ProductAction_RELOAD,
	ProductAction_SAVE_Failure,
	ProductAction_SAVE_Request,
	ProductAction_SAVE_Success,
	ProductAction_SHOW_CONFIRM,
	ProductAction_SHOW_DETAIL,
	eProductActionTypeIds,
} from './IProductActionsTypes';

export const productActions = {
	//ALL
	getAllRequest: (): ProductAction_GETALL_Request => BaseAction(eProductActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: ProductModel[]): ProductAction_GETALL_Success =>
		BaseAction(eProductActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): ProductAction_GETALL_Failure => BaseAction(eProductActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): ProductAction_GETPAGINATION_Request =>
		BaseAction(eProductActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationRespone<ProductModel>): ProductAction_GETPAGINATION_Success =>
		BaseAction(eProductActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): ProductAction_GETPAGINATION_Failure =>
		BaseAction(eProductActionTypeIds.GET_PAGINATION_FAILURE, error),
	//ACTION
	getByActionRequest: (request: ProductSelectRequest): ProductAction_GETBY_ACTION_Request =>
		BaseAction(eProductActionTypeIds.GET_BY_ACTION_REQUEST, request),
	getByActionSuccess: (data: BasePaginationRespone<ProductModel>): ProductAction_GETBY_ACTION_Success =>
		BaseAction(eProductActionTypeIds.GET_BY_ACTION_SUCCESS, data),
	getByActionFailure: (error: Error | string): ProductAction_GETBY_ACTION_Failure =>
		BaseAction(eProductActionTypeIds.GET_BY_ACTION_FAILURE, error),
	//OWNER ACTION
	getByOwnerActionRequest: (request: ProductSelectRequest): ProductAction_GETBY_OWNER_ACTION_Request =>
		BaseAction(eProductActionTypeIds.GET_BY_OWNER_ACTION_REQUEST, request),
	getByOwnerActionSuccess: (data: BasePaginationRespone<ProductModel>): ProductAction_GETBY_OWNER_ACTION_Success =>
		BaseAction(eProductActionTypeIds.GET_BY_OWNER_ACTION_SUCCESS, data),
	getByOwnerActionFailure: (error: Error | string): ProductAction_GETBY_OWNER_ACTION_Failure =>
		BaseAction(eProductActionTypeIds.GET_BY_OWNER_ACTION_FAILURE, error),

	//PUBLISHED
	getPublishedRequest: (request: ProductSelectRequest): ProductAction_GETPUBLISHED_Request =>
		BaseAction(eProductActionTypeIds.GET_PUBLISHED_REQUEST, request),
	getPublishedSuccess: (data: BasePaginationRespone<ProductModel>): ProductAction_GETPUBLISHED_Success =>
		BaseAction(eProductActionTypeIds.GET_PUBLISHED_SUCCESS, data),
	getPublishedFailure: (error: Error | string): ProductAction_GETPUBLISHED_Failure =>
		BaseAction(eProductActionTypeIds.GET_PUBLISHED_FAILURE, error),
	//GET_DETAIL
	detailRequest: (id: any): ProductAction_GET_DETAIL_Request => BaseAction(eProductActionTypeIds.GET_DETAIL_REQUEST, id),
	detailSuccess: (detail?: ProductModel): ProductAction_GET_DETAIL_Success => BaseAction(eProductActionTypeIds.GET_DETAIL_SUCCESS, detail),
	detailFailure: (error: Error | string): ProductAction_GET_DETAIL_Failure => BaseAction(eProductActionTypeIds.GET_DETAIL_FAILURE, error),
	//SAVE
	saveRequest: (entity: ProductModel): ProductAction_SAVE_Request => BaseAction(eProductActionTypeIds.SAVE_REQUEST, entity),
	saveSuccess: (message: string): ProductAction_SAVE_Success => BaseAction(eProductActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): ProductAction_SAVE_Failure => BaseAction(eProductActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: any[]): ProductAction_DELETE_Request => BaseAction(eProductActionTypeIds.DELETE_REQUEST, ids),
	deleteSuccess: (message: string): ProductAction_DELETE_Success => BaseAction(eProductActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): ProductAction_DELETE_Failure => BaseAction(eProductActionTypeIds.DELETE_FAILURE, error),
	//CHANGE_STATUS
	changeStatusRequest: (changeAction: ProductChangeStatusRequest): ProductAction_CHANGE_STATUS_Request =>
		BaseAction(eProductActionTypeIds.CHANGE_STATUS_REQUEST, changeAction),
	changeStatusSuccess: (message: string): ProductAction_CHANGE_STATUS_Success =>
		BaseAction(eProductActionTypeIds.CHANGE_STATUS_SUCCESS, message),
	changeStatusFailure: (error: Error | string): ProductAction_CHANGE_STATUS_Failure =>
		BaseAction(eProductActionTypeIds.CHANGE_STATUS_FAILURE, error),
	//RELOAD
	needReload: (): ProductAction_RELOAD => BaseAction(eProductActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): ProductAction_CHANGE_SELECTED_IDS => BaseAction(eProductActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetailId<number>): ProductAction_SHOW_DETAIL => BaseAction(eProductActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (detail: ProductActionConfirmRequest): ProductAction_SHOW_CONFIRM => BaseAction(eProductActionTypeIds.SHOW_CONFIRM, detail),
};
