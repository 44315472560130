import { eProductStatus } from "./eProductStatus";

export type ProductActionConfirmRequest = {
    ids: number[];
    actionStatus: eProductStatus;
    isShow: boolean;
    receivedBy?: string;
    subject?: string;
    message?: string;
}

export const dfProductActionConfirmRequest: ProductActionConfirmRequest = {
    ids: [],
    actionStatus: eProductStatus.DRAFT,
    isShow: false,
    receivedBy: '',
    subject: '',
    message: '',
}