import { useNavigate } from 'react-router-dom';
import { AccountInfo } from '../context/models/accounts';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { actions } from '../context/stores/rootActions';
import { MenuResponse } from '../context/models/accounts/MenuResponse';

type AuthContextType = {
	account: AccountInfo | null;
	menus: MenuResponse[];
	login: (acount: AccountInfo | null) => void;
	logout: () => void;
};

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider = ({ children, account }: { children: any; account: AccountInfo | null }) => {
	const dispatch = useDispatch();
	const [user, setUser] = useState<AccountInfo | null>(account);
	const navigate = useNavigate();

	useEffect(() => {
		if (!account || account === null) navigate('/login', { replace: false });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	// call this function when you want to authenticate the acount
	const login = (data: AccountInfo | null) => {
		setUser(data);
		navigate('/', { replace: true });
	};

	// call this function to sign out logged in user
	const logout = () => {
		dispatch(actions.account.logoutRequest());
		setUser(null);
		navigate('/login', { replace: true });
	};

	const value = useMemo(() => {
		return {
			account: user,
			menus: user?.menus || [],
			login,
			logout,
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
	const context = useContext(AuthContext);
	if (context === undefined) {
		throw new Error('useAuthState must be used within a AuthProvider');
	}
	return context;
};
