import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupArticleCategory } from '../useLookupArticleCategory';

type IComboBoxArticleCategoryProps = {
	name: string;
	value: any;
	valueExpr?: string;
	displayExpr?: string;
	onValueChanged: (value: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	hasValid?: boolean;
	placeholder?: string;
};

const ComboBoxArticleCategory = (props: IComboBoxArticleCategoryProps) => {
	const { article_category_all } = useLookupArticleCategory();

	return (
		<DxSelectBox
			dataSource={article_category_all}
			valueExpr={'id'}
			displayExpr={'name'}
			{...props}
			placeholder={props.placeholder || 'Chọn chuyên mục'}
			isClearable={true}
		/>
	);
};
export default ComboBoxArticleCategory;