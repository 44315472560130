import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';
import './Modal.css';

const modalRootEl = document.getElementById('modal-root') as HTMLElement;

type IModalProps = {
	show: boolean;
	children?: any;
	type: 'danger' | 'primary' | 'success';
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
	onClose: () => void;
};

export const Modal = (props: IModalProps) => {
	const closeOnEscapeKeyDown = (e: any) => {
		if ((e.charCode || e.keyCode) === 27) {
			props.onClose();
		}
	};

	useEffect(() => {
		document.body.addEventListener('keydown', closeOnEscapeKeyDown);
		return function cleanup() {
			document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
		};
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return ReactDOM.createPortal(
		<>
			{props.show && (
				<>
					<div className={`modal zoom fade show`}>
						<ModalDialog {...props}>{props.children}</ModalDialog>
					</div>
					<div className={`modal-backdrop fade show`}></div>
				</>
			)}
		</>,
		modalRootEl
	);
};

export default Modal;

export const ModalDraggable = (props: IModalProps) => {
	return ReactDOM.createPortal(
		<>
			{props.show && (
				<>
					<Draggable handle='.modal-header'>
						<div className={`modal zoom fade show`}>
							<ModalDialog {...props}>{props.children}</ModalDialog>
						</div>
					</Draggable>
					<div className={`modal-backdrop fade show`}></div>
				</>
			)}
		</>,
		modalRootEl
	);
};

const ModalDialog = ({ children, sizeClass }: { children?: any, sizeClass?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' }) => {
	return (
		<>
			<div className={`modal-dialog modal-${sizeClass || 'sm'}`} role='document'>
				<div className='modal-content'>{children}</div>
			</div>
		</>
	);
};

export const ModalHeader = ({ title, classDragg, onClose }: { title: string; classDragg?: boolean; onClose: () => void }) => {
	return (
		<>
			{/* <button type='button' className='btn nav-link close btn-xs' data-dismiss='modal' aria-label='Close' onClick={() => onClose()}>
				<em className='icon ni ni-cross fs-11px'></em>
			</button> */}
			<div className={`modal-header ${classDragg ? 'dragg' : ''}`}>
				<h5 className='modal-title'>{title}</h5>
			</div>
		</>
	);
};

export const ModalBody = ({ children }: { children?: any }) => {
	return (
		<>
			<div className='modal-body'>{children}</div>
		</>
	);
};

export const ModalFooter = ({ children }: { children?: any }) => {
	return (
		<>
			<div className='modal-footer bg-light'>{children}</div>
		</>
	);
};
