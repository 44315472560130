import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	DxDateBox,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	TextBox,
} from '../../components/base';
import { ImageBox } from '../../components/base/ImageBox';
import { dfPromotionModel, PromotionModel, validPromotionSchema } from '../../context/models/config/PromotionModel';
import { getImageByEnpoint } from '../../utils/slugHelper';

type IPromotionProps = {
	isShow: boolean;
	title: string;
	detailModel?: PromotionModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: PromotionModel) => void;
};

const PromotionDetail = (props: IPromotionProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<PromotionModel>(dfPromotionModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<PromotionModel>({
		//@ts-ignore
		resolver: yupResolver(validPromotionSchema()),
		mode: 'all',
		defaultValues: { ...initialValues },
	});

	const onSubmitHandler = (values: PromotionModel) => {
		onSubmit(values);
	};

	console.log('VALUE', errors);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên chương trình'
						/>
						<TextBox
							name={'code'}
							error={errors.code?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, code: value }));
							}}
							value={initialValues.code}
							hasValid={true}
							label='Mã chương trình'
						/>
						<TextBox
							name={'permalink'}
							error={errors.permalink?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, permalink: value }));
							}}
							value={initialValues.permalink}
							hasValid={true}
							label='Link'
						/>
						<NumberBox
							name='value'
							value={initialValues.value}
							error={errors.value?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, value: value }));
							}}
							hasValid={true}
							sizeClass='sm'
							label='Giá trị'
						/>
						<DxDateBox
							name='startTime'
							value={moment(initialValues.startTime).format()}
							displayFormat={'dd/MM/yyyy HH:mm'}
							typeDate='datetime'
							onValueChanged={(e) => {
								setInitialValues((prev) => ({ ...prev, startTime: e }));
							}}
							label='Thời gian bắt đầu'
						/>
						<DxDateBox
							name='endTime'
							value={moment(initialValues.endTime).format()}
							displayFormat={'dd/MM/yyyy HH:mm'}
							typeDate='datetime'
							onValueChanged={(e) => {
								setInitialValues((prev) => ({ ...prev, endTime: e }));
							}}
							label='Thời gian kết thúc'
						/>
						<ImageBox
							name='image'
							isDisabled={initialValues.name.length <= 0}
							file_name={initialValues.name}
							value={getImageByEnpoint(initialValues?.image)}
							onDataChanged={(image) => {
								setInitialValues((prev) => ({
									...prev,
									image: image?.path || '',
								}));
							}}
							hasValid={true}
							label='Ảnh'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={false}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default PromotionDetail;
