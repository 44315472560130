import { BannerPositionActionTypes, eBannerPositionActionTypeIds } from './IBannerPositionActionsTypes';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { IBannerPositionState } from './IBannerPositionState';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetail } from '../../base/BaseShowDetail';
import { BannerPositionModel } from '../../models/banners/BannerPositionModel';

const initPaginationResponse: BasePaginationRespone<BannerPositionModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IBannerPositionState = {
	status: eBaseActionStatus.idle,
	allBannerPositions: [],
	selectedIds: [],
	showConfirm: false,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetail,
};
const BannerPositionReducer = (state: IBannerPositionState = initialState, action: BannerPositionActionTypes): IBannerPositionState => {
	switch (action.type) {
		case eBannerPositionActionTypeIds.GET_ALL_REQUEST:
		case eBannerPositionActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.GET_ALL_SUCCESS:
			console.log('RESILT', {
				...state,
				allBannerPositions: action.payload,
				status: BaseGetActionStatus(action),
			});
			return {
				...state,
				allBannerPositions: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.GET_ALL_FAILURE:
			console.log('FALIE', {
				...state,
				allBannerPositions: action.payload,
				status: BaseGetActionStatus(action),
			});
			return {
				...state,
				allBannerPositions: [],
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eBannerPositionActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eBannerPositionActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eBannerPositionActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eBannerPositionActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default BannerPositionReducer;
