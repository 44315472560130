import React, { useEffect, useState } from 'react';
import { ProductImageModel, dfProductImageModel, validProductImageSchema } from '../../../context/models/products-image/ProductImageModel';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	FormGroup,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	SwitchBox,
	TextBox,
} from '../../../components/base';
import { ImageBox } from '../../../components/base/ImageBox';

type IProductImageDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: ProductImageModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: ProductImageModel) => void;
};

const ProductImageDetail = ({ isShow, title, detailModel, onSubmit, onClose, isSaving }: IProductImageDetailModalProps) => {
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<ProductImageModel>(dfProductImageModel);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<ProductImageModel>({
		//@ts-ignore
		resolver: yupResolver(validProductImageSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ProductImageModel) => {
		const data = { ...values, inGallery: true };
		console.log(data);
		onSubmit(data);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'title'}
							error={errors.title?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, title: value }));
							}}
							value={initialValues.title}
							hasValid={true}
							label='Tiêu đề'
						/>
						<TextBox
							name={'description'}
							error={errors.description?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, description: value }));
							}}
							value={initialValues.description}
							hasValid={true}
							label='Mô tả'
						/>
						<ImageBox
							label='Upload ảnh'
							name='path'
							value={initialValues.path}
							hasValid={true}
							file_name={initialValues.title}
							clearable={false}
							onDataChanged={(image) => {
								setInitialValues((prev) => ({
									...prev,
									path: image?.path || '',
									extension: image?.extension || '',
									size: image?.size || '',
								}));
							}}
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default ProductImageDetail;
