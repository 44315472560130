import { dfBaseShowDetail } from '../../base/BaseShowDetail';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { BannerConfigActionTypes, eBannerConfigActionTypeIds } from './IBannerConfigActionsTypes';
import { IBannerConfigState } from './IBannerConficState';
import { NotifyHelper } from '../../../utils/NotifyHelper';

const initialState: IBannerConfigState = {
	status: eBaseActionStatus.idle,
	allBannerConfigs: [],
	selectedIds: [],
	positionId:[],
	showConfirm: false,
	showDetail: dfBaseShowDetail,
};
const BannerConfigReducer = (state: IBannerConfigState = initialState, action: BannerConfigActionTypes): IBannerConfigState => {
	switch (action.type) {
		case eBannerConfigActionTypeIds.GET_ALL_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerConfigActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allBannerConfigs: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eBannerConfigActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allBannerConfigs: [],
				status: BaseGetActionStatus(action),
			};
		case eBannerConfigActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eBannerConfigActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eBannerConfigActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eBannerConfigActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerConfigActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eBannerConfigActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eBannerConfigActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default BannerConfigReducer;
