import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { PartnerApi } from '../../../context/api/config/partnerApi';
import { BaseSelectRequest } from '../../../context/base/BaseSelectRequest';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { CategoryModel } from '../../../context/models/category/CategoryModel';
import { ConfigPartnerModel } from '../../../context/models/config/PartnerModel';
import { categoryActions } from '../../../context/stores/category/categoryActions';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import PartnerPage from '../partner-page/PartnerPage';

const mapStateToProps = (state: AppState) => ({
	titlePage: 'Category',
	categories: state.category.categories,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({});

const PartnerTable = () => {
	const [partners, setPartners] = useState<ConfigPartnerModel[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedIds, setSelectedIds] = useState<number[]>([]);
	const [isShow, setShow] = useState<boolean>(false);
	const [loadingSave, setLoadingSave] = useState<boolean>(false);
	const [partner, setPartner] = useState<ConfigPartnerModel | undefined>();

	const getPartnerByAll = async () => {
		setLoading(true);
		setSelectedIds([]);
		try {
			const response = await PartnerApi.GetAllAsync();
			if (response?.isSuccess) {
				setPartners(response.result);
			}
		} catch (_error) {
			setPartners([]);
		} finally {
			setLoading(false);
		}
	};

	const handleSaveData = async (value: ConfigPartnerModel) => {
		setLoadingSave(true);
		try {
			if (partner) {
				await PartnerApi.PutAsync(value);
			} else {
				await PartnerApi.PostAsync(value);
			}
		} catch (_error) {
		} finally {
			setLoadingSave(false);
			setShow(false);
			getPartnerByAll();
			setSelectedIds([]);
		}
	};

	const handleDeletes = async () => {
		setLoading(true);
		try {
			if (selectedIds.length > 0) await PartnerApi.DeletesAsync(selectedIds);
		} catch (_error) {
		} finally {
			setLoading(false);
			getPartnerByAll();
			setSelectedIds([]);
		}
	};

	useEffect(() => {
		getPartnerByAll();
	}, []);

	// Cột của bảng
	const columns = useMemo((): any => {
		return [
			<DxTableColumn key={createUUID()} dataField='id' caption='ID' width={100} />,
			<DxTableColumn
				key={createUUID()}
				dataField='image'
				caption={`Ảnh`}
				width={100}
				fixed
				cellRender={(cell: any) => {
					return (
						<>
							<div className='user-card'>
								<div className='product-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={cell?.data?.image} alt='ảnh đại diện' />
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='title' caption='Tiêu đề' minWidth={100} />,
			<DxTableColumn key={createUUID()} dataField='link' caption='Link' minWidth={200} />,
			<DxTableColumn
				key={createUUID()}
				width={50}
				caption='Sửa'
				alignment='center'
				cellRender={(cell: any) => (
					<ButtonLink
						onClick={() => {
							setPartner(cell?.data);
							setShow(true);
						}}
						title='Sửa'
						icon='ni ni-edit'
						theme='link'
					/>
				)}
			/>,
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách đối tác`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => handleDeletes()}
								/>
							</li>
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => {
										setShow(true);
										setPartner(undefined);
									}}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{loading && <LoadingTable />}
						{!loading && (
							<DxTable
								dataSource={partners}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={loading}
								defaultPageSize={10}
								height={window.innerHeight - 300}
								filters={{
									refreshDataGrid: getPartnerByAll,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										setSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
				{isShow && (
					<>
						<PartnerPage
							isSaving={loadingSave}
							isShow={isShow}
							detailModel={partner}
							onClose={() => setShow(false)}
							onSubmit={(data) => handleSaveData(data)}
							title={`${partner ? `Cập nhật` : `Thêm mới`}`}
						/>
					</>
				)}
			</PageContainer>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerTable);
