import { CollectionGroupActionTypes, eCollectionGroupActionTypeIds } from "./ICollectionGroupActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { ICollectionGroupState } from "./ICollectionGroupState"
import { CollectionGroupModel } from "../../models/collections/CollectionGroupModel";
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";

const initPaginationResponse: BasePaginationRespone<CollectionGroupModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: ICollectionGroupState = {
    status: eBaseActionStatus.idle,
    allCollectionsGroup: [],
    selectedIds: [],
    showConfirm: false,
    paginationResponse: initPaginationResponse,
    showDetail: dfBaseShowDetail
}
const CollectionGroupReducer = (state: ICollectionGroupState = initialState, action: CollectionGroupActionTypes): ICollectionGroupState => {
    switch (action.type) {
        case eCollectionGroupActionTypeIds.GET_ALL_REQUEST:
        case eCollectionGroupActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allCollectionsGroup: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                paginationResponse: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allCollectionsGroup: [],
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                paginationResponse: initPaginationResponse,
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eCollectionGroupActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eCollectionGroupActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eCollectionGroupActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionGroupActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eCollectionGroupActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eCollectionGroupActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eCollectionGroupActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eCollectionGroupActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default CollectionGroupReducer;