import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { UsersAction_DELETE_Request, UsersAction_GETALL_Request, UsersAction_GETPAGINATION_Request, UsersAction_SAVE_Request, eUsersActionTypeIds } from "./IUsersActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { UserModel } from "../../models/users/UserModel";

function* onLoadAllUsers(action: UsersAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.user, rootApi.user.GetAllAsync]);
        if (res.isSuccess && res.result) {
            const listUsers = res.result as UserModel[];
            yield put(actions.users.getAllSuccess(listUsers));
        }
        else {
            yield put(actions.users.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.users.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationUsers(action: UsersAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.user, rootApi.user.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listUsers = res.result as UserModel[];
            yield put(actions.users.getPaginationSuccess({ listDatas: listUsers, pagination: res.pagination }));
        }
        else {
            yield put(actions.users.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.users.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveUsers(action: UsersAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([rootApi.user, rootApi.user.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.users.saveSuccess(res.message));
                yield put(actions.users.needReload());
            }
            else {
                yield put(actions.users.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([rootApi.user, rootApi.user.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.users.saveSuccess(res.message));
                yield put(actions.users.needReload());
            }
            else {
                yield put(actions.users.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.users.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteUsers(action: UsersAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([rootApi.user, rootApi.user.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.users.deleteSuccess(res.message));
            yield put(actions.users.needReload());
        }
        else {
            yield put(actions.users.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.users.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllUsers() {
    yield takeEvery(eUsersActionTypeIds.GET_ALL_REQUEST, onLoadAllUsers);
}

function* watchOnLoadPaginationUsers() {
    yield takeEvery(eUsersActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationUsers);
}

function* watchOnSaveUsers() {
    yield takeEvery(eUsersActionTypeIds.SAVE_SAVING, onSaveUsers);
}

function* watchOnDeleteUsers() {
    yield takeEvery(eUsersActionTypeIds.DELETE_SAVING, onDeleteUsers);
}

function* usersSaga() {
    yield all([fork(watchOnLoadAllUsers)]);
    yield all([fork(watchOnLoadPaginationUsers)]);
    yield all([fork(watchOnSaveUsers)]);
    yield all([fork(watchOnDeleteUsers)]);
}

export default usersSaga;