import React from 'react'
import TagBox from 'devextreme-react/tag-box';

export type IDxTagBoxProps = {
	name: string;
	dataSource: any[];
	value: any;
	onValueChanged: (value: any) => void;
	valueExpr: string;
	displayExpr: string;
	displaySubExpr?: string;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	hasValid?: boolean;
	itemRender?: (e: any) => React.ReactNode,
    maxDisplayedTags?: number;
};

export const DxTagBox = (props: IDxTagBoxProps) => {
	const {
		value,
		error,
		name,
		label,
		sizeClass,
		isDisabled,
		helpBlock,
		labelSmall,
		hasValid,
		dataSource,
		onValueChanged,
		valueExpr,
		displayExpr,
		isClearable,
		placeholder,
		itemRender,
        maxDisplayedTags
	} = props;
	const showError = error ? true : false;

	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className='form-control-wrap'>
					<TagBox
						className={`select-box form-control-${sizeClass || 'xs'} ${showError ? 'error' : ''}`}
						id={name}
						valueExpr={valueExpr}
						displayExpr={displayExpr}
						dataSource={dataSource}
						disabled={isDisabled}
						showClearButton={isClearable}
						searchEnabled={true}
						name={name}
						value={value}
                        maxDisplayedTags={maxDisplayedTags || 3}
                        showMultiTagOnly={false}
                        showSelectionControls={true}
                        hideSelectedItems={true}
						onValueChanged={(e) => {
							if(e.event){
								onValueChanged(e.value);
							}
						}}
						placeholder={placeholder}
						itemRender={itemRender}
					/>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};

