import React from 'react';
import { DxSelectBox } from '../../../base/DxSelectBox';
import { useLookupUserGroup } from '../useLookupUserGroup';

type ISelectBoxUserGroupProps = {
	name: string;
	value: any;
	hanldeOnValueChanged: (value: any, data?: any) => void;
	label?: string;
	error?: string;
	sizeClass?: 'xs' | 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	isDisabled?: boolean;
	isClearable?: boolean;
	isSearchable?: boolean;
	hasValid?: boolean;
	itemRender?: (e: any) => React.ReactNode;
};

export const SelectBoxUserGroup = (props: ISelectBoxUserGroupProps) => {
	const { users_group_lookup } = useLookupUserGroup();

	return (
		<DxSelectBox
			dataSource={users_group_lookup}
			onValueChanged={(value) => {
				const detail = users_group_lookup.find((x) => x.id === value);
				props.hanldeOnValueChanged(value, detail);
			}}
			{...props}
			valueExpr='id'
			displayExpr='name'
		/>
	);
};