import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../components/base';
import { ConfirmModal } from '../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn } from '../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { BaseShowDetail } from '../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { createUUID } from '../../utils/createUUID';
import { CollectionTypeModel } from '../../context/models/collections/CollectionTypeModel';
import CollectionTypeDetail from './CollectionTypeDetail';
import { CollectionTypeActionTypes } from '../../context/stores/collections-type/ICollectionTypeActionsTypes';
import { Navigate, useNavigate } from 'react-router-dom';
type ICollectionTypePageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allCollectionsType: CollectionTypeModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<CollectionTypeModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: CollectionTypeModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<CollectionTypeModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `nhóm vị trí`,
		status: state.collectionType.status,
		allCollectionsType: state.collectionType.allCollectionsType,
		selectedIds: state.collectionType.selectedIds,
		showDetail: state.collectionType.showDetail,
		showConfirm: state.collectionType.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<CollectionTypeActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.collectionType.getAllRequest());
	},
	handleSaveData: (data: CollectionTypeModel) => {
		dispatch(actions.collectionType.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.collectionType.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.collectionType.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<CollectionTypeModel>) => {
		dispatch(actions.collectionType.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.collectionType.showConfirm(isShow));
	},
});

const CollectionTypePage = (props: ICollectionTypePageProps) => {
	const navigate = useNavigate();
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allCollectionsType,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	allCollectionsType.sort((a, b) => a.stt - b.stt);

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='stt' caption={`Số thứ tự`} alignment='center' width={100} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='name' caption={`Tên vị trí`} minWidth={150} fixed></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='note' minWidth={150} caption={`Mô tả`}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={150}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				fixed
				cellRender={(cell: any) => {
					return (
						<>
							{cell.data.id > 0 && (
								<>
									<Button
										onClick={() => {
											localStorage.setItem('current_type_id', cell.data.id);
											navigate(`/collections-group`, { replace: true });
										}}
										text=''
										icon='ni ni-grid-box-alt-fill'
										preset='dimoutline'
										theme='info'
										title='Nhóm bộ sưu tập thuộc vị trí'
									/>{' '}
									<Button
										onClick={() => onShowDetail({ isShow: true, detailData: cell.data })}
										text=''
										icon='ni ni-edit'
										preset='dimoutline'
										theme='primary'
										title='Sửa'
									/>{' '}
								</>
							)}
						</>
					);
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							{selectedIds.length > 0 && (
								<li>
									<Button
										text={`Xóa (${selectedIds.length})`}
										icon='icon ni ni-trash'
										className='d-md-inline-flex'
										theme='danger'
										onClick={() => onShowConfirm(true)}
									/>
								</li>
							)}

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allCollectionsType}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<CollectionTypeDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectionTypePage);
