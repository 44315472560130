import { all, call, put, fork, takeEvery } from "redux-saga/effects";
import { actions } from "../rootActions";
import { ProductCategoryAction_DELETE_Request, ProductCategoryAction_GETALL_Request, ProductCategoryAction_GETPAGINATION_Request, ProductCategoryAction_SAVE_Request, eProductCategoryActionTypeIds } from "./IProductCategoryActionsTypes";
import { BaseResponse } from "../../base/BaseResponse";
import { rootApi } from "../../api/rootApi";
import { ProductCategoryModel } from "../../models/products-category/ProductCategoryModel";

const _sagaApi = rootApi.productCategory;

function* onLoadAllProductCategory(action: ProductCategoryAction_GETALL_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
        if (res.isSuccess && res.result) {
            const listProductCategory = res.result as ProductCategoryModel[];
            yield put(actions.productCategory.getAllSuccess(listProductCategory));
        }
        else {
            yield put(actions.productCategory.getAllFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productCategory.getAllFailure(error || 'Có lỗi'));
    }
}

function* onLoadPaginationProductCategory(action: ProductCategoryAction_GETPAGINATION_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
        if (res.isSuccess && res.result) {
            const listProductCategory = res.result as ProductCategoryModel[];
            yield put(actions.productCategory.getPaginationSuccess({ listDatas: listProductCategory, pagination: res.pagination }));
        }
        else {
            yield put(actions.productCategory.getPaginationFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productCategory.getPaginationFailure(error || 'Có lỗi'));
    }
}

function* onSaveProductCategory(action: ProductCategoryAction_SAVE_Request) {
    try {
        if (action.payload.id > 0) {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.productCategory.saveSuccess(res.message));
                yield put(actions.productCategory.needReload());
            }
            else {
                yield put(actions.productCategory.saveFailure(res.message));
            }
        } else {
            const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
            if (res.isSuccess) {
                yield put(actions.productCategory.saveSuccess(res.message));
                yield put(actions.productCategory.needReload());
            }
            else {
                yield put(actions.productCategory.saveFailure(res.message));
            }
        }
    } catch (error: any) {
        yield put(actions.productCategory.saveFailure(error || 'Có lỗi'));
    }
}

function* onDeleteProductCategory(action: ProductCategoryAction_DELETE_Request) {
    try {
        const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
        if (res.isSuccess) {
            yield put(actions.productCategory.deleteSuccess(res.message));
            yield put(actions.productCategory.needReload());
        }
        else {
            yield put(actions.productCategory.deleteFailure(res.message));
        }
    } catch (error: any) {
        yield put(actions.productCategory.deleteFailure(error || 'Có lỗi'));
    }
}

function* watchOnLoadAllProductCategory() {
    yield takeEvery(eProductCategoryActionTypeIds.GET_ALL_REQUEST, onLoadAllProductCategory);
}

function* watchOnLoadPaginationProductCategory() {
    yield takeEvery(eProductCategoryActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationProductCategory);
}


function* watchOnSaveProductCategory() {
    yield takeEvery(eProductCategoryActionTypeIds.SAVE_REQUEST, onSaveProductCategory);
}

function* watchOnDeleteProductCategory() {
    yield takeEvery(eProductCategoryActionTypeIds.DELETE_REQUEST, onDeleteProductCategory);
}

function* productCategorySaga() {
    yield all([fork(watchOnLoadAllProductCategory)]);
    yield all([fork(watchOnLoadPaginationProductCategory)]);
    yield all([fork(watchOnSaveProductCategory)]);
    yield all([fork(watchOnDeleteProductCategory)]);
}

export default productCategorySaga;