import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { BadgesRole } from '../../../components/shared/system/badges-role/BadgesRole';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { RoleResponse } from '../../../context/models/roles/RoleModel';
import { UserGroupModel } from '../../../context/models/users-group/UserGroupModel';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { UserGroupActionTypes } from '../../../context/stores/users-group/IUserGroupActionTypes';
import { createUUID } from '../../../utils/createUUID';
import UsersGroupDetailModal from './detail-modal';

type IUsersGroupPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	users_group_all: UserGroupModel[];
	allRoles: RoleResponse[];
	selectedIds: number[];
	showDetail: BaseShowDetail<UserGroupModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: UserGroupModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<UserGroupModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Tác nhân`,
		status: state.usersGroup.status,
		users_group_all: state.usersGroup.users_group_all,
		allRoles: state.roles.allRoles,
		selectedIds: state.usersGroup.selectedIds,
		showDetail: state.usersGroup.showDetail,
		showConfirm: state.usersGroup.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<UserGroupActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.usersGroup.getAllRequest());
	},
	handleSaveData: (data: UserGroupModel) => {
		dispatch(actions.usersGroup.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.usersGroup.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.usersGroup.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<UserGroupModel>) => {
		dispatch(actions.usersGroup.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.usersGroup.showConfirm(isShow));
	},
});

const UsersGroupPage = (props: IUsersGroupPageProps) => {
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		users_group_all,
		allRoles,
		handleReloadAllData,
		onChangeSelectedIds,
		handleDeleteData,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const calculateFilterExpression = (filterValue: any, selectedFilterOperation: any, target: any) => {
		if (target === 'search' && typeof filterValue === 'string') {
			return [target.dataField, 'contains', filterValue];
		}
		return function (data: any) {
			const list = (data.roleids ? data.roleids : '0').split(',').map((x: any) => parseInt(x));
			return list.indexOf(filterValue) !== -1;
		};
	};

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn allowHeaderFiltering={false} key={createUUID()} dataField='name' caption={`Tên`} width={150}></DxTableColumn>,
			<DxTableColumn
				allowHeaderFiltering={false}
				key={createUUID()}
				dataField='description'
				minWidth={150}
				caption={`Mô tả`}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='roleidsView'
				width={250}
				caption={`Quyền hạn`}
				dataType={'object'}
				cellRender={(cell: any) => {
					return <BadgesRole roles_all={allRoles} value={cell.value?.map((x: any) => parseInt(x))} />;
				}}
				calculateCellValue={(rowData: any) => {
					return rowData?.roleids?.split(',').map((x: any) => parseInt(x));
				}}
				calculateFilterExpression={calculateFilterExpression}
			>
				<DxLookup dataSource={allRoles} valueExpr='id' displayExpr='name' />
			</DxTableColumn>,
			<DxTableColumn allowHeaderFiltering={false} key={createUUID()} dataField='isActive' caption={`Áp dụng`} width={80}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoles]);

	const toolBars = useMemo(() => {
		let result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<span>
					Tổng số: <b>{allRoles.length}</b>{' '}
				</span>
			</DxToolbarItem>,
		];
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoles]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={users_group_all}
								keyExpr='id'
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								isHeaderFilter={false}
								exportTitle={titlePage}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								toolbars={toolBars}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<UsersGroupDetailModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersGroupPage);