import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';


export function useLookupRoleScopes() {
    const dispatch = useDispatch();
    const { status, allRoleScopes } = useSelector((state: AppState) => state.roleScopes);
    useEffect(() => {
        if (status === eBaseActionStatus.idle || status === eBaseActionStatus.reload) dispatch(actions.roles.getAllRequest());
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return { role_scopes_lookup: allRoleScopes };
}
