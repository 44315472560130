import React from 'react';
import { NavLink } from 'react-router-dom';

export const NotAccess = () => {
	return (
		<div className='nk-block nk-block-middle wide-md mx-auto'>
			<div className='nk-block-content nk-error-ld text-center'>
				<div className='wide-xs mx-auto'>
					<div className='nk-error-gfx-401'>
						<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 90 90'>
							<rect
								x='13'
								y='16'
								width='68'
								height='66'
								rx='6'
								ry='6'
								fill='#fff'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></rect>
							<rect
								x='24'
								y='82'
								width='11'
								height='5'
								fill='#fff'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></rect>
							<rect
								x='60'
								y='82'
								width='11'
								height='5'
								fill='#fff'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></rect>
							<path
								d='M47,70.15S61.89,62.49,61.89,51V37.6L47,31.85,32.11,37.6V51C32.11,62.49,47,70.15,47,70.15Z'
								fill='#eff1ff'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></path>
							<path
								d='M41.56,48H52.44A1.6,1.6,0,0,1,54,49.59v5.73A1.6,1.6,0,0,1,52.44,57H41.56A1.6,1.6,0,0,1,40,55.32V49.59A1.6,1.6,0,0,1,41.56,48Z'
								fill='#6576ff'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></path>
							<path
								d='M43,48V45a4,4,0,0,1,8,0v3'
								fill='none'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></path>
							<circle
								cx='46.67'
								cy='52.79'
								r='0.91'
								fill='#fff'
								stroke='#fff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></circle>
							<circle
								cx='23'
								cy='17'
								r='14'
								fill='#fff'
								stroke='#6576ff'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
							></circle>
							<circle cx='23' cy='17' r='10.5' fill='#e3e7fe'></circle>
							<path
								d='M28.46,20.47l-4.41-4.41a3.4,3.4,0,0,0,.26-1.31A3.34,3.34,0,1,0,21,18.1a3.41,3.41,0,0,0,1.31-.27l1.44,1.45a.33.33,0,0,0,.23.09l.79,0,0,.79a.32.32,0,0,0,.09.23.27.27,0,0,0,.23.08l.79,0,0,.79a.31.31,0,0,0,.09.22.29.29,0,0,0,.22.09l.79,0,0,.79a.3.3,0,0,0,.09.24.32.32,0,0,0,.21.08h0l1.21-.14a.3.3,0,0,0,.27-.33l-.13-1.48Z'
								fill='#6576ff'
							></path>
							<path d='M20.56,14.09a1,1,0,0,1-1.34,0,1,1,0,0,1,0-1.35,1,1,0,1,1,1.34,1.35Z' fill='#fff'></path>
							<path d='M23.72,16.39h0l3.79,3.79a.22.22,0,0,1,0,.32h0a.24.24,0,0,1-.32,0l-3.75-3.76Z' fill='#fff'></path>
							<rect x='17.32' y='11.6' width='11' height='11' fill='none'></rect>
						</svg>
					</div>
					<h3 className='nk-error-title'>Bạn không có quyền truy cập nội dung này !!!</h3>
					<NavLink to='/' className='btn btn-md btn-primary mt-2'>
						Trở lại trang chủ
					</NavLink>
				</div>
			</div>
		</div>
	);
};
