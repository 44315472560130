import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { CollectionItemModel } from '../../models/collections/CollectionItemModel';
import { CollectionItemRequest } from '../../models/collections/CollectionItemRequest';
import { CollectionItemResponse } from '../../models/collections/CollectionItemResponse';
import { KeywordSeoModel } from '../../models/crm/KeywordSeoModel';

export enum eCollectionItemActionTypeIds {
	GET_ALL_REQUEST = 'CollectionItem_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'CollectionItem_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'CollectionItem_GET_ALL_FAILURE',

	GET_KEYWORD_REQUEST = 'CollectionItem_GET_KEYWORD_REQUEST',
	GET_KEYWORD_SUCCESS = 'CollectionItem_GET_KEYWORD_SUCCESS',
	GET_KEYWORD_FAILURE = 'CollectionItem_GET_KEYWORD_FAILURE',

	GET_PAGINATION_REQUEST = 'CollectionItem_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'CollectionItem_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'CollectionItem_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'CollectionItem_SAVE_REQUEST',
	SAVE_SUCCESS = 'CollectionItem_SAVE_SUCCESS',
	SAVE_FAILURE = 'CollectionItem_SAVE_FAILURE',

	DELETE_REQUEST = 'CollectionItem_DELETE_REQUEST',
	DELETE_SUCCESS = 'CollectionItem_DELETE_SUCCESS',
	DELETE_FAILURE = 'CollectionItem_DELETE_FAILURE',

	NEED_RELOAD = 'CollectionItem_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'CollectionItem_CHANGE_SELECTED_IDS',
	SHOW_DETAIL = 'CollectionItem_SHOW_DETAIL',
	SHOW_CONFIRM = 'CollectionItem_SHOW_CONFIRM',
}

export interface CollectionItemAction_GETALL_Request
	extends IActionTypeBase<eCollectionItemActionTypeIds.GET_ALL_REQUEST, CollectionItemRequest> {}
export interface CollectionItemAction_GETALL_Success
	extends IActionTypeBase<eCollectionItemActionTypeIds.GET_ALL_SUCCESS, CollectionItemResponse[]> {}
export interface CollectionItemAction_GETALL_Failure
	extends IActionTypeBase<eCollectionItemActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface CollectionItemAction_GETPAGINATION_Request
	extends IActionTypeBase<eCollectionItemActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface CollectionItemAction_GETPAGINATION_Success
	extends IActionTypeBase<eCollectionItemActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<CollectionItemModel>> {}
export interface CollectionItemAction_GETPAGINATION_Failure
	extends IActionTypeBase<eCollectionItemActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface CollectionItemAction_SAVE_Request
	extends IActionTypeBase<eCollectionItemActionTypeIds.SAVE_REQUEST, CollectionItemModel> {}
export interface CollectionItemAction_SAVE_Success extends IActionTypeBase<eCollectionItemActionTypeIds.SAVE_SUCCESS, string> {}
export interface CollectionItemAction_SAVE_Failure extends IActionTypeBase<eCollectionItemActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface CollectionItemAction_DELETE_Request extends IActionTypeBase<eCollectionItemActionTypeIds.DELETE_REQUEST, number[]> {}
export interface CollectionItemAction_DELETE_Success extends IActionTypeBase<eCollectionItemActionTypeIds.DELETE_SUCCESS, string> {}
export interface CollectionItemAction_DELETE_Failure extends IActionTypeBase<eCollectionItemActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface CollectionItemAction_RELOAD extends IActionTypeBase<eCollectionItemActionTypeIds.NEED_RELOAD, undefined> {}

export interface CollectionItemAction_SHOW_DETAIL
	extends IActionTypeBase<eCollectionItemActionTypeIds.SHOW_DETAIL, BaseShowDetail<CollectionItemModel>> {}
export interface CollectionItemAction_CHANGE_SELECTED_IDS
	extends IActionTypeBase<eCollectionItemActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface CollectionItemAction_SHOW_CONFIRM extends IActionTypeBase<eCollectionItemActionTypeIds.SHOW_CONFIRM, boolean> {}

export type CollectionItemActionTypes =
	| CollectionItemAction_GETALL_Request
	| CollectionItemAction_GETALL_Success
	| CollectionItemAction_GETALL_Failure
	| CollectionItemAction_GETPAGINATION_Request
	| CollectionItemAction_GETPAGINATION_Success
	| CollectionItemAction_GETPAGINATION_Failure
	| CollectionItemAction_SAVE_Request
	| CollectionItemAction_SAVE_Success
	| CollectionItemAction_SAVE_Failure
	| CollectionItemAction_DELETE_Request
	| CollectionItemAction_DELETE_Success
	| CollectionItemAction_DELETE_Failure
	| CollectionItemAction_RELOAD
	| CollectionItemAction_SHOW_DETAIL
	| CollectionItemAction_CHANGE_SELECTED_IDS
	| CollectionItemAction_SHOW_CONFIRM;
