import { CollectionModel } from '../../models/collections/CollectionModel';
import { ApiBase } from '../../base/_ApiBase';
import { CollectionRequest } from '../../models/collections/CollectionRequest';
import { ApiClient } from '../../base/_ApiClient';
import { ConfigPartnerModel } from '../../models/config/PartnerModel';

export const PartnerApi = new (class PartnerApi extends ApiBase<ConfigPartnerModel> {
	constructor({ baseUrl }: { baseUrl: string }) {
		super(baseUrl);
	}
})({ baseUrl: '/partner' });
