import { useMemo } from 'react';
import { Badges, IBadgesData } from '../../../base/Badges';

type IBadgesRoleScopeNameProps = {
	value: string[];
};

export const BadgesRoleScopeName = (props: IBadgesRoleScopeNameProps) => {
	const { value } = props;

	const dataSource: IBadgesData[] = useMemo(() => {
		return value?.map((data) => ({ text: data })) || [];
	}, [value]);

	return <Badges dataSource={dataSource} preset='dimoutline' bgClass='primary' />;
};
