import moment from 'moment';
import * as Yup from 'yup';
import { eProductStatus } from './eProductStatus';

export type ProductModel = {
	id: number;
	sku: string;
	barcode: string;
	name: string;
	alias: string;
	initContent: string;
	summary: string;
	contentHtml: string;
	image: string;
	catId: number;
	supplierId: number;
	price: number;
	priceOnSale: number;
	videoPath: string;
	publishedOnDate: string;
	relationProductIds: string;
	relationArticleIds: string;
	tags: string;
	status: string;
	actionStatus: string;
	metaTitle: string;
	metaDescription: string;
	lockedBy: string;
	lockedOnDate: string;
	model: string;
	nameBT: string;
	modelColor: string;
	brandName: string;
	productDesc: ProductDescModel[];
	// productProps: ProductProps[];
	// productProp: ProductProps[];
	isNew: boolean;
	isBestSale: boolean;
	discount: number;
	numberRate: number;
	numberReview: number;
	numberSold: number;
	promotionID?: number;
};

export type ProductDescModel = {
	id?: number;
	productDescCategoryId: number;
	content: string;
};

export type ProductProps = {
	// productPropValueId: 0;
	productPropId: number;
	value: string;
};

export const dfProductModel: ProductModel = {
	id: 0,
	sku: '',
	barcode: '',
	name: '',
	alias: '',
	initContent: '',
	summary: '',
	contentHtml: 'Content',
	image: '',
	catId: 0,
	supplierId: 0,
	price: 0,
	priceOnSale: 0,
	videoPath: '',
	publishedOnDate: moment().format(),
	relationProductIds: '',
	relationArticleIds: '',
	tags: '',
	isNew: false,
	isBestSale: false,
	status: eProductStatus.DRAFT,
	actionStatus: eProductStatus.DRAFT,
	metaDescription: '',
	metaTitle: '',
	lockedBy: '',
	model: '',
	nameBT: '',
	modelColor: '',
	brandName: '',
	discount: 0,
	numberRate: 0,
	numberReview: 0,
	numberSold: 0,
	promotionID: undefined,
	lockedOnDate: moment().format(),
	productDesc: [
		{
			productDescCategoryId: 0,
			content: '',
			id: 0,
		},
	],
};

export const validProductSchema = () =>
	Yup.object().shape({
		name: Yup.string().required('Vui lòng nhập tên sản phẩm.'),
		alias: Yup.string().required('Vui lòng nhập link sản phẩm.'),
		catId: Yup.number().required('Vui lòng chọn nhóm sản phẩm.').min(1, 'Vui lòng chọn nhóm sản phẩm'),
		sku: Yup.string().required('Vui lòng nhập SKU sản phẩm.'),
		barcode: Yup.string().optional(),
		initContent: Yup.string().optional(),
		sumary: Yup.string().optional(),
		contentHtml: Yup.string().optional(),
		image: Yup.string(),
		supplierId: Yup.number(),
		price: Yup.number().required('Vui lòng nhập giá bán').min(1, 'Vui lòng nhập giá bán'),
		priceOnSale: Yup.number(),
		videoPath: Yup.string(),
		publishedOnDate: Yup.string(),
		relationProductIds: Yup.string(),
		relationArticleIds: Yup.string(),
		tags: Yup.string(),
		status: Yup.string(),
		lockedBy: Yup.string(),
		lockedOnDate: Yup.string(),
	});
