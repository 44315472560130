import React from 'react';
interface ITextAreaBoxProps {
	name: string;
	value: string;
	onValueChanged: (value: string) => void;
	label?: string;
	error?: string;
	rows?: number;
	sizeClass?: 'sm' | 'md' | 'lg';
	helpBlock?: string;
	placeholder?: string;
	labelSmall?: boolean;
	disabled?: boolean;
	hasValid?: boolean;
	className?: string;
	classWrapper?: string;
}

export const TextAreaBox = (props: ITextAreaBoxProps) => {
	const {
		error,
		name,
		value,
		label,
		rows,
		sizeClass,
		disabled,
		helpBlock,
		labelSmall,
		hasValid,
		placeholder,
		className,
		classWrapper,
		onValueChanged,
	} = props;
	const showError = error ? true : false;
	return (
		<>
			<div className='form-group'>
				{label && (
					<div className='form-label-group'>
						<label className={`form-label ${labelSmall ? 'form-label-small' : ''}`} htmlFor={name}>
							{label} {hasValid && <em className='text-danger'>(*)</em>}
						</label>
					</div>
				)}
				<div className={`form-control-wrap ${classWrapper}`}>
					<textarea
						id={name}
						name={name}
						disabled={disabled}
						placeholder={placeholder}
						rows={rows}
						value={value}
                        onChange={(e) => onValueChanged(e.target.value)}
						className={`form-control form-control-${sizeClass || 'sm'} textarea-${sizeClass || 'sm'} ${showError ? 'error' : ''} ${className}`}
					></textarea>
					{showError && <span className='form-note invalid'>{error}</span>}
					{helpBlock && <span className='form-note text-muted' dangerouslySetInnerHTML={{ __html: helpBlock }} />}
				</div>
			</div>
		</>
	);
};
