import * as Yup from 'yup';
export type ProductCategoryModel = {
    id: number;
    name: string;
    slug: string;
    icon: string;
    keyWords: string;
    description: string;
    parentId: number;
    catTree: string;
    genre: string;
    inActive: boolean;
    sortOrder: number;
    isHidden: boolean;
    sortOrderSub: number;
    isHiddenSub: boolean;
    sortOrderFooter: number;
    isHiddenFooter: boolean;
}

export const dfProductCategoryModel: ProductCategoryModel = {
    id: 0,
    name: '',
    catTree: '',
    description: '',
    genre: '',
    icon: '',
    inActive: false,
    isHidden: true,
    isHiddenFooter: true,
    isHiddenSub: true,
    keyWords: '',
    parentId: 0,
    slug: '',
    sortOrder: 0,
    sortOrderFooter: 0,
    sortOrderSub: 0,
}

export const validProductCategorySchema = () => Yup.object().shape({
    name: Yup.string().required('Vui lòng nhập Tên danh mục.'),
    slug: Yup.string().required('Vui lòng nhập link danh mục.'),
});