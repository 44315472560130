import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import logo_cms from '../../assets/images/logo-dark2x.png';
import { BaseForm, Button, PasswordBox, TextBox } from '../../components/base';
import { LoginRequest } from '../../context/models/accounts';
import { eBaseActionStatus } from '../../context/base/eBaseActionStatus';
import { actions } from '../../context/stores/rootActions';
import { AppState } from '../../context/stores/rootReducers';
import { useAuth } from '../../utils/AuthProvider';

const LoginPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { account, login } = useAuth();

	const { loginStatus, loginResponse } = useSelector((state: AppState) => state.account);
	const loginStart = (loginRequest: LoginRequest) => dispatch(actions.account.loginRequest(loginRequest));

	const [initialValues, setInitialValues] = useState<LoginRequest>({
		username: '',
		password: '',
	});

	const validationSchema = Yup.object().shape({
		username: Yup.string().required('Hãy nhập Tài khoản hoặc Email.'),
		password: Yup.string().required('Hãy nhập Mật khẩu.'),
	});

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<LoginRequest>({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: LoginRequest) => {
		loginStart(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues]);

	useEffect(() => {
		if (account != null) navigate('/', { replace: true });
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [account]);

	useEffect(() => {
		if (loginStatus === eBaseActionStatus.complete && loginResponse) login(loginResponse?.account || null);
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus]);

	return (
		<>
			<div className='brand-logo pb-4'>
				<img className='logo-dark logo-img logo-img-md' src={logo_cms} alt='Đăng nhập CMS - Khám bệnh 24h' />
			</div>
			<div className='nk-block-head'>
				<div className='nk-block-head-content'>
					<h4 className='nk-block-title'>Đăng nhập</h4>
				</div>
			</div>
			<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
				<TextBox
					name='username'
					error={errors.username?.message}
					onValueChanged={(value) => {
						setInitialValues((prev) => ({ ...prev, username: value }));
					}}
					value={initialValues.username}
					sizeClass='lg'
					label='Tài khoản hoặc Email'
					placeholder='Nhập Tài khoản hoặc Email'
					hasValid={true}
				/>
				<PasswordBox
					name='password'
					error={errors.password?.message}
					onValueChanged={(value) => {
						setInitialValues((prev) => ({ ...prev, password: value }));
					}}
					value={initialValues.password}
					sizeClass={'lg'}
					label='Mật khẩu'
					placeholder='Nhập mật khẩu'
					hasValid={true}
				/>
				<Button
					type='submit'
					text='Đăng nhập'
					icon='icon ni ni-signin'
					onClick={() => {}}
					isDisabled={loginStatus === eBaseActionStatus.loading}
					isLoading={loginStatus === eBaseActionStatus.loading}
					theme='primary'
					sizeClass='lg'
					className='btn-block'
				/>
			</BaseForm>
		</>
	);
};

export default LoginPage;
