import moment from 'moment';
import * as Yup from 'yup';
import { eArticleStatus } from './eArticleStatus';
import { eArticleGenres } from './eArticleGenres';
import { jwtTokenHelper } from '../../../utils/jwtTokenHelper';

export type ArticleItemResponse = {
    id: string;
    title: string;
    titleSlug: string;
    catId: number;
    initContent: string;
    image: string;
    status: string;
    genre: string;
    publishedOnDate: string;
    lockedBy: string;
    lockedOnDate: string;
    viewCount: number;
    wordCount: number;
    charCount: number;
    createdBy: string;
    createdOnDate: string;
    lastModifiedBy: string;
    lastModifiedOnDate: string;
    categoryName: string;
}

export type ArticleModel = {
    id: string;
    title: string;
    titleSlug: string;
    catId: number;
    initContent: string;
    contentHtml: string;
    author: string;
    authorPublish: string;
    image: string;
    imageCaption: string;
    audio: string;
    metaDescription: string;
    metaTitle: string;
    sourceLink: string;
    status: string;
    genre: string;
    isYoutube: boolean;
    linkYoutube: string;
    videoPath: string;
    publishedOnDate: string;
    relationIds: string;
    relationProductIds: string;
    tags: string;
    lockedBy: string;
    lockedOnDate: string;
    viewCount: number;
    wordCount: number;
    charCount: number;
    langCode: string;
    isHiddenContent: boolean;
    isAMP: boolean;
    actionStatus: string;
}

export const dfArticleModel: ArticleModel = {
    id: '0',
    title: '',
    titleSlug: '',
    catId: 0,
    initContent: '',
    contentHtml: '',
    author: '',
    authorPublish: '',
    image: '',
    imageCaption: '',
    audio: '',
    metaDescription: '',
    metaTitle: '',
    sourceLink: '',
    status: eArticleStatus.DRAFT,
    genre:  eArticleGenres.CONTENT,
    isYoutube: false,
    linkYoutube: '',
    videoPath: '',
    publishedOnDate: moment().format(),
    relationIds: '',
    relationProductIds: '',
    tags: '',
    lockedBy: '',
    lockedOnDate: moment().format(),
    viewCount: 0,
    wordCount: 0,
    charCount: 0,
    langCode: jwtTokenHelper.LANGCODE(),
    isHiddenContent: false,
    isAMP: false,
    actionStatus: eArticleStatus.DRAFT
}

export const validArticleSchema = () => Yup.object().shape({
    title: Yup.string().required("Vui lòng nhập tiêu đề bài viết."),
    titleSlug: Yup.string().required("Vui lòng nhập slug bài viết."),
    catId: Yup.number().required("Vui lòng chọn chuyên mục.").min(1,"Vui lòng chọn chuyên mục"),
    initContent: Yup.string(),
    contentHtml: Yup.string(),
    author: Yup.string(),
    authorPublish: Yup.string(),
    image: Yup.string(),
    imageCaption: Yup.string(),
    audio: Yup.string(),
    metaDescription: Yup.string(),
    metaTitle: Yup.string(),
    sourceLink: Yup.string(),
    status: Yup.string(),
    genre: Yup.string(),
    isYoutube: Yup.bool(),
    linkYoutube: Yup.string(),
    videoPath: Yup.string(),
    publishedOnDate: Yup.string(),
    relationIds: Yup.string(),
    relationProductIds: Yup.string(),
    tags: Yup.string(),
    lockedBy: Yup.string(),
    lockedOnDate: Yup.string(),
    viewCount: Yup.number(),
    wordCount: Yup.number(),
    charCount: Yup.number(),
    langCode: Yup.string(),
    isHiddenContent: Yup.bool(),
    isAMP: Yup.bool(),
});
