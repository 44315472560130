import { BasePagination, BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { CollectionGroupModel } from '../../models/collections/CollectionGroupModel';
import { CollectionGroupRequest } from '../../models/collections/CollectionGroupRequest';
import { CollectionGroupResponse } from '../../models/collections/CollectionGroupResponse';
import {
	CollectionGroupAction_CHANGE_SELECTED_IDS,
	CollectionGroupAction_DELETE_Failure,
	CollectionGroupAction_DELETE_Request,
	CollectionGroupAction_DELETE_Success,
	CollectionGroupAction_GETALL_Failure,
	CollectionGroupAction_GETALL_Request,
	CollectionGroupAction_GETALL_Success,
	CollectionGroupAction_GETPAGINATION_Failure,
	CollectionGroupAction_GETPAGINATION_Request,
	CollectionGroupAction_GETPAGINATION_Success,
	CollectionGroupAction_RELOAD,
	CollectionGroupAction_SAVE_Failure,
	CollectionGroupAction_SAVE_Request,
	CollectionGroupAction_SAVE_Success,
	CollectionGroupAction_SHOW_CONFIRM,
	CollectionGroupAction_SHOW_DETAIL,
	eCollectionGroupActionTypeIds,
} from './ICollectionGroupActionsTypes';

export const CollectionGroupActions = {
	//ALL
	getAllRequest: (data : CollectionGroupRequest): CollectionGroupAction_GETALL_Request => BaseAction(eCollectionGroupActionTypeIds.GET_ALL_REQUEST, data),
	getAllSuccess: (data: CollectionGroupResponse[]): CollectionGroupAction_GETALL_Success =>
		BaseAction(eCollectionGroupActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): CollectionGroupAction_GETALL_Failure =>
		BaseAction(eCollectionGroupActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): CollectionGroupAction_GETPAGINATION_Request =>
		BaseAction(eCollectionGroupActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationRespone<CollectionGroupModel>): CollectionGroupAction_GETPAGINATION_Success =>
		BaseAction(eCollectionGroupActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): CollectionGroupAction_GETPAGINATION_Failure =>
		BaseAction(eCollectionGroupActionTypeIds.GET_PAGINATION_FAILURE, error),
	//SAVE
	saveRequest: (entity: CollectionGroupModel): CollectionGroupAction_SAVE_Request =>
		BaseAction(eCollectionGroupActionTypeIds.SAVE_REQUEST, entity),
	saveSuccess: (message: string): CollectionGroupAction_SAVE_Success => BaseAction(eCollectionGroupActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): CollectionGroupAction_SAVE_Failure => BaseAction(eCollectionGroupActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): CollectionGroupAction_DELETE_Request => BaseAction(eCollectionGroupActionTypeIds.DELETE_REQUEST, ids),
	deleteSuccess: (message: string): CollectionGroupAction_DELETE_Success =>
		BaseAction(eCollectionGroupActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): CollectionGroupAction_DELETE_Failure =>
		BaseAction(eCollectionGroupActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): CollectionGroupAction_RELOAD => BaseAction(eCollectionGroupActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): CollectionGroupAction_CHANGE_SELECTED_IDS =>
		BaseAction(eCollectionGroupActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<CollectionGroupModel>): CollectionGroupAction_SHOW_DETAIL =>
		BaseAction(eCollectionGroupActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): CollectionGroupAction_SHOW_CONFIRM => BaseAction(eCollectionGroupActionTypeIds.SHOW_CONFIRM, show),
};
