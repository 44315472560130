import React, { useMemo } from 'react';

interface IButtonProps {
	onClick: () => void;
	text: string;
	className?: string;
	theme?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'gray';
	preset?: 'dim' | 'outline' | 'dimoutline';
	icon?: string;
	iconMode?: 'left' | 'right';
	sizeClass?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
	type?: 'submit' | 'button' | 'reset';
	textClass?: string;
	isDisabled?: boolean | false;
	isLoading?: boolean | false;
	title?: string;
}

export const Button = (props: IButtonProps) => {
	const { isDisabled, isLoading, text, onClick, type, icon, iconMode, theme, className, preset, sizeClass, textClass, title } = props;

	const classButton = useMemo(() => {
		let _preset = '';
		if (preset === 'dim') {
			_preset = 'btn-dim';
		} else if (preset === 'outline') {
			_preset = `btn-outline-${theme || 'secondary'}`;
		} else if (preset === 'dimoutline') {
			_preset = `btn-dim btn-outline-${theme || 'secondary'}`;
		} else {
			_preset = `btn-${theme || 'secondary'}`;
		}
		return `btn ${_preset} btn-${sizeClass || 'xs'} ${className}`;
	}, [preset, className, theme, sizeClass]);

	const iconViewMode = useMemo(() => {
		if (iconMode) return iconMode;
		return 'left';
	}, [iconMode]);

	return (
		<>
			<button type={type || 'button'} className={classButton} disabled={isDisabled || isLoading} onClick={onClick} title={title}>
				{isLoading && <span className='spinner-border spinner-border-sm' role='status' aria-hidden='true'></span>}
				{icon && iconViewMode === 'left' ? <em className={icon}></em> : ''}
				{text && <span className={textClass}>{text}</span>}
				{icon && iconViewMode === 'right' ? <em className={icon}></em> : ''}
			</button>
		</>
	);
};

interface IButtonCancelProps {
	onClick: () => void;
	text?: string;
	theme?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'gray';
	isDisabled?: boolean | false;
	isLoading?: boolean | false;
	icon?: string;
	sizeClass?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
}

export const ButtonCancel = (props: IButtonCancelProps) => {
	const { isDisabled, isLoading, theme, text, onClick, icon, sizeClass } = props;
	return (
		<>
			<Button
				onClick={onClick}
				text={text || 'Hủy'}
				theme={theme || 'secondary'}
				isDisabled={isDisabled}
				isLoading={isLoading}
				icon={icon || 'icon ni ni-cross-circle'}
				sizeClass={sizeClass}
			/>
		</>
	);
};

interface IButtonSaveProps {
	onClick: () => void;
	text?: string;
	theme?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'gray';
	preset?: 'dim' | 'outline' | 'dimoutline';
	isDisabled?: boolean | false;
	isLoading?: boolean | false;
	icon?: string;
	type?: 'submit' | 'button';
	sizeClass?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
	className?: string;
}

export const ButtonSave = (props: IButtonSaveProps) => {
	const { type, isDisabled, isLoading, theme, text, onClick, icon, sizeClass, preset, className } = props;
	return (
		<>
			<Button
				onClick={onClick}
				text={text || 'Lưu'}
				theme={theme || 'primary'}
				preset={preset}
				isDisabled={isDisabled}
				isLoading={isLoading}
				type={type || 'button'}
				icon={icon || 'icon ni ni-save'}
				sizeClass={sizeClass}
				className={className}
			/>
		</>
	);
};

interface IButtonLinkProps {
	onClick: () => void;
	text?: string;
	className?: string;
	theme?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger' | 'dark' | 'gray' | 'link';
	icon?: string;
	iconMode?: 'left' | 'right';
	sizeClass?: 14 | 16 | 18 | 20 | 22 | 24;
	type?: 'submit' | 'button' | 'reset';
	textClass?: string;
	isDisabled?: boolean | false;
	title?: string;
}

export const ButtonLink = (props: IButtonLinkProps) => {
	const { isDisabled, text, onClick, type, icon, iconMode, theme, className, sizeClass, textClass, title } = props;

	const classButton = useMemo(() => {
		let _preset = '';
		_preset = `text-${theme || 'none'}`;
		return `btn-none btn-link ${_preset} fs-${sizeClass || 14}px ${className}`;
	}, [className, theme, sizeClass]);

	const iconViewMode = useMemo(() => {
		if (iconMode) return iconMode;
		return 'left';
	}, [iconMode]);

	return (
		<>
			<button type={type || 'button'} className={classButton} disabled={isDisabled} onClick={onClick} title={title}>
				{icon && iconViewMode === 'left' ? <em className={icon}></em> : ''}
				{text && <small className={textClass}>{text}</small>}
				{icon && iconViewMode === 'right' ? <em className={icon}></em> : ''}
			</button>
		</>
	);
};
