import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { BaseAction } from '../../base/IBaseActionRespone';
import { BannerModel } from '../../models/banners/BannerModel';
import {
	BannerAction_CHANGE_SELECTED_IDS,
	BannerAction_DELETE_Failure,
	BannerAction_DELETE_Request,
	BannerAction_DELETE_Success,
	BannerAction_GETALL_Failure,
	BannerAction_GETALL_Request,
	BannerAction_GETALL_Success,
	BannerAction_GETPAGINATION_Failure,
	BannerAction_GETPAGINATION_Request,
	BannerAction_GETPAGINATION_Success,
	BannerAction_GET_DETAIL_Failure,
	BannerAction_GET_DETAIL_Request,
	BannerAction_GET_DETAIL_Success,
	BannerAction_RELOAD,
	BannerAction_SAVE_Failure,
	BannerAction_SAVE_Request,
	BannerAction_SAVE_Success,
	BannerAction_SHOW_CONFIRM,
	BannerAction_SHOW_DETAIL,
	eBannerActionTypeIds,
} from './IBannerActionsTypes';

export const bannerActions = {
	//ALL
	getAllRequest: (): BannerAction_GETALL_Request => BaseAction(eBannerActionTypeIds.GET_ALL_REQUEST, undefined),
	getAllSuccess: (data: BannerModel[]): BannerAction_GETALL_Success => BaseAction(eBannerActionTypeIds.GET_ALL_SUCCESS, data),
	getAllFailure: (error: Error | string): BannerAction_GETALL_Failure => BaseAction(eBannerActionTypeIds.GET_ALL_FAILURE, error),
	//LIST
	getPaginationRequest: (request: BaseSelectRequest): BannerAction_GETPAGINATION_Request =>
		BaseAction(eBannerActionTypeIds.GET_PAGINATION_REQUEST, request),
	getPaginationSuccess: (data: BasePaginationRespone<BannerModel>): BannerAction_GETPAGINATION_Success =>
		BaseAction(eBannerActionTypeIds.GET_PAGINATION_SUCCESS, data),
	getPaginationFailure: (error: Error | string): BannerAction_GETPAGINATION_Failure =>
		BaseAction(eBannerActionTypeIds.GET_PAGINATION_FAILURE, error),
	//GET_DETAIL
	detailRequest: (id: any): BannerAction_GET_DETAIL_Request => BaseAction(eBannerActionTypeIds.GET_DETAIL_REQUEST, id),
	detailSuccess: (detail?: BannerModel): BannerAction_GET_DETAIL_Success => BaseAction(eBannerActionTypeIds.GET_DETAIL_SUCCESS, detail),
	detailFailure: (error: Error | string): BannerAction_GET_DETAIL_Failure => BaseAction(eBannerActionTypeIds.GET_DETAIL_FAILURE, error),
	//SAVE
	saveRequest: (entity: BannerModel): BannerAction_SAVE_Request => BaseAction(eBannerActionTypeIds.SAVE_REQUEST, entity),
	saveSuccess: (message: string): BannerAction_SAVE_Success => BaseAction(eBannerActionTypeIds.SAVE_SUCCESS, message),
	saveFailure: (error: Error | string): BannerAction_SAVE_Failure => BaseAction(eBannerActionTypeIds.SAVE_FAILURE, error),
	//DELETE
	deleteRequest: (ids: number[]): BannerAction_DELETE_Request => BaseAction(eBannerActionTypeIds.DELETE_REQUEST, ids),
	deleteSuccess: (message: string): BannerAction_DELETE_Success => BaseAction(eBannerActionTypeIds.DELETE_SUCCESS, message),
	deleteFailure: (error: Error | string): BannerAction_DELETE_Failure => BaseAction(eBannerActionTypeIds.DELETE_FAILURE, error),
	//RELOAD
	needReload: (): BannerAction_RELOAD => BaseAction(eBannerActionTypeIds.NEED_RELOAD, undefined),
	//ACTIONs
	changeSelectedIds: (ids: number[]): BannerAction_CHANGE_SELECTED_IDS => BaseAction(eBannerActionTypeIds.CHANGE_SELECTED_IDS, ids),
	showDetail: (detail: BaseShowDetail<BannerModel>): BannerAction_SHOW_DETAIL => BaseAction(eBannerActionTypeIds.SHOW_DETAIL, detail),
	showConfirm: (show: boolean): BannerAction_SHOW_CONFIRM => BaseAction(eBannerActionTypeIds.SHOW_CONFIRM, show),
};
