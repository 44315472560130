import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	BaseForm,
	ButtonCancel,
	ButtonSave,
	ModalBody,
	ModalDraggable,
	ModalFooter,
	ModalHeader,
	NumberBox,
	TextBox,
} from '../../components/base';
import ComboBoxCollection from '../../components/shared/hoc-collection/ComboBoxCollection';
import {
	CollectionItemModel,
	dfCollectionItemModel,
	validCollectionItemSchema,
} from '../../context/models/collections/CollectionItemModel';
import ComboBoxKeywordSeo from '../../components/shared/hoc-keyword-seo/ComboBoxKeywordSeo';
import { useDispatch } from 'react-redux';
import { actions } from '../../context/stores/rootActions';

type ICollectionItemDetailModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: CollectionItemModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: CollectionItemModel) => void;
};

const CollectionItemDetail = (props: ICollectionItemDetailModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const dispatch = useDispatch();
	const [initialValues, setInitialValues] = useState<CollectionItemModel>(dfCollectionItemModel);
	const [keywordSelected, setkeywordSelected] = useState(0);
	const keywordData = useEffect(() => {
		dispatch(actions.keywordSeo.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const {
		control,
		handleSubmit,
		reset,
		setValue,
		formState: { errors, isSubmitting },
	} = useForm<CollectionItemModel>({
		//@ts-ignore
		resolver: yupResolver(validCollectionItemSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: CollectionItemModel) => {
		onSubmit(values);
	};

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<ComboBoxCollection
							name={'collectID'}
							error={errors.collectID?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, collectID: value }));
							}}
							value={initialValues.collectID}
							hasValid={true}
							label='Bộ sưu tập'
						/>
						<ComboBoxKeywordSeo
							name={'name_selected'}
							onValueChanged={(value) => {
								setkeywordSelected(value || 0);
							}}
							onDataChanged={(data) => {
								setInitialValues((prev) => ({ ...prev, name: data?.name || '', link: data?.link || '' }));
							}}
							value={keywordSelected}
							hasValid={false}
							label='Chọn từ khóa'
						/>
						<TextBox
							name={'name'}
							error={errors.name?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, name: value }));
							}}
							value={initialValues.name}
							hasValid={true}
							label='Tên từ khóa'
						/>
						<TextBox
							name={'link'}
							error={errors.link?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, link: value }));
							}}
							value={initialValues.link}
							hasValid={true}
							label='Đường dẫn'
						/>
						<NumberBox
							name={'stt'}
							error={errors.stt?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, stt: value }));
							}}
							value={initialValues.stt}
							hasValid={true}
							label='Số thứ tự'
						/>
						{/* <SwitchBox
							name='isDelete'
							label='Áp dụng'
							error={errors.isDelete?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, isDelete: value }));
							}}
							value={initialValues.isDelete}
							sizeClass='sm'
						/> */}
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={isSaving}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default CollectionItemDetail;
