import React from 'react';
import { Outlet } from 'react-router-dom';

interface IPageHeaderProps {
	title?: string;
	children?: any;
}

export const PageHeader = ({ children, title }: IPageHeaderProps) => {
	return (
		<>
			<div className='nk-fmg-body-head my-2'>
				{title && (
					<div className='nk-fmg-search'>
						<h3 className='nk-block-title page-title'>{title}</h3>
					</div>
				)}
				{children}
			</div>
		</>
	);
};
