import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { AdminLayout, PublicLayout } from './layouts';
import LoginPage from './pages/login';
import AppRoutes from './AppRoutes';
import { ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { AppState } from './context/stores/rootReducers';
import { actions } from './context/stores/rootActions';
import { eBaseActionStatus } from './context/base/eBaseActionStatus';
import { AuthProvider } from './utils/AuthProvider';
import { Loading } from './components/base';
import { NotFound } from './pages/error';

const App = () => {
	const dispatch = useDispatch();
	const { accountStatus, accountInfo } = useSelector((state: AppState) => state.account);
	useEffect(() => {
		if (accountStatus === eBaseActionStatus.idle) dispatch(actions.account.getAccountRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (accountStatus !== eBaseActionStatus.complete) return <Loading />;

	return (
		<>
			<AuthProvider account={accountInfo}>
				<Routes>
					<Route element={<AdminLayout />}>
						{AppRoutes &&
							AppRoutes.map(({ path, Component }, index) => {
								return <Route key={index}  path={path} element={<Component />} />;
							})}
						<Route path='*' element={<NotFound />} />
					</Route>
					<Route element={<PublicLayout />}>
						<Route path='/login' element={<LoginPage />} />
					</Route>
				</Routes>
			</AuthProvider>
			<ToastContainer
				position='bottom-center'
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss={false}
				draggable={false}
				pauseOnHover
			/>
		</>
	);
};

export default App;
