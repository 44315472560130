import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetail } from '../../base/BaseShowDetail';
import { ProductTypeProductModel } from '../../models/product-type/ProductTypeProductModel';
import { IProductTypeProductState } from './IProductTypeProductState';
import { eProductTypeProductActionTypeIds, ProductTypeProductActionTypes } from './IProductTypeProductActionsTypes';
import { ProductTypeProductResponse } from '../../models/product-type/ProductTypeProductResponse';

const initPaginationResponse: BasePaginationRespone<ProductTypeProductResponse> = { listDatas: [], pagination: dfBasePagination };
const initialState: IProductTypeProductState = {
	status: eBaseActionStatus.idle,
	statusCat: eBaseActionStatus.idle,
	allProductTypeProducts: [],
	allProductByCategory: [],
	selectedIds: [],
	selectedProductIds: [],
	showConfirm: false,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetail,
};
const productTypeProductReducer = (
	state: IProductTypeProductState = initialState,
	action: ProductTypeProductActionTypes
): IProductTypeProductState => {
	switch (action.type) {
		case eProductTypeProductActionTypeIds.GET_ALL_REQUEST:
		case eProductTypeProductActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_BY_CATEGORY_REQUEST:
			return {
				...state,
				statusCat: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allProductTypeProducts: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_BY_CATEGORY_SUCCESS:
			return {
				...state,
				allProductByCategory: action.payload,
				statusCat: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allProductTypeProducts: [],
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_BY_CATEGORY_FAILURE:
			return {
				...state,
				allProductByCategory: [],
				statusCat: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eProductTypeProductActionTypeIds.CHANGE_SELECTED_PRODUCT_IDS:
			return {
				...state,
				selectedProductIds: action.payload,
			};
		case eProductTypeProductActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eProductTypeProductActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eProductTypeProductActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_REQUEST:
			return {
				...state,
				selectedProductIds: [],
				statusCat: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Thêm sản phẩm thành công');
			return {
				...state,
				selectedProductIds: [],
				statusCat: BaseGetActionStatus(action),
                status: eBaseActionStatus.reload
			};
		case eProductTypeProductActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedProductIds: [],
				statusCat: BaseGetActionStatus(action),
			};
		case eProductTypeProductActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default productTypeProductReducer;
