import { combineReducers } from 'redux';
import usersReducer from './users/usersReducer';
import accountReducer from './auth/accountReducer';
import roleScopesReducer from './roles/roleScopesReducer';
import rolesReducer from './roles/rolesReducer';
import articleCategoryReducer from './articles-category/articleCategoryReducer';
import articleReducer from './articles/articleReducer';
import productReducer from './products/productReducer';
import productCategoryReducer from './products-categroy/productCategoryReducer';
import menusReducer from './menus/menusReducer';
import usersGroupReducer from './users-group/userGroupReducer';
import CollectionReducer from './collections/collectionReducer';
import productImageReducer from './products-image/productImageReducer';
import BannerPositionReducer from './banner-position/bannerPositionReducer';
import BannerConfigReducer from './banner-config/bannerConfigReducer';
import bannerReducer from './banner/bannerReducer';
import CollectionTypeReducer from './collections-type/collectionTypeReducer';
import CollectionGroupReducer from './collections-group/collectionGroupReducer';
import CollectionItemReducer from './collections-item/collectionItemReducer';
import keywordReducer from './keyword/keywordReducer';
import KeywordSeoReducer from './KeowordSeo/keywordSeoReducer';
import productTypeReducer from './product-type/productTypeReducer';
import productTypeProductReducer from './product-type-product/productTypeProductReducer';
import categoryReducer from './category/categoryReducer';

const rootReducers = combineReducers({
	users: usersReducer,
	usersGroup: usersGroupReducer,
	menus: menusReducer,
	account: accountReducer,
	roles: rolesReducer,
	roleScopes: roleScopesReducer,
	articleCategory: articleCategoryReducer,
	article: articleReducer,
	product: productReducer,
	productType: productTypeReducer,
	productTypeProduct: productTypeProductReducer,
	keyword: keywordReducer,
	productCategory: productCategoryReducer,
	bannerPosition: BannerPositionReducer,
	banner: bannerReducer,
	bannerConfig: BannerConfigReducer,
	productImage: productImageReducer,
	collection: CollectionReducer,
	collectionType: CollectionTypeReducer,
	collectionGroup: CollectionGroupReducer,
	collectionItem: CollectionItemReducer,
	keywordSeo: KeywordSeoReducer,
	category: categoryReducer,
});

export type AppState = ReturnType<typeof rootReducers>;

export default rootReducers;
