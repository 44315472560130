export enum eProductStatus {
    DELETED = "DELETED",
    DRAFT = "DRAFT",
    EDITED = "EDITED",
    PUBLISHED = "PUBLISHED",
    REMOVED = "REMOVED",
    RESTORE = "RESTORE",
}

export const ProductActionRule = ({ status, isOwner }: { status: eProductStatus, isOwner: boolean }) => {
    let listActions: eProductStatus[] = [];
    if (status === eProductStatus.DRAFT) {
        listActions = [eProductStatus.DRAFT, eProductStatus.DELETED, eProductStatus.PUBLISHED]
    }
    else if (status === eProductStatus.DELETED) {
        if (isOwner) {
            listActions = [eProductStatus.RESTORE, eProductStatus.DELETED]
        } else {
            listActions = [eProductStatus.DELETED, eProductStatus.RESTORE]
        }
    }
    else if (status === eProductStatus.PUBLISHED) {
        listActions = [eProductStatus.REMOVED]
    }
    else if (status === eProductStatus.REMOVED) {
        listActions = [eProductStatus.DELETED, eProductStatus.PUBLISHED]
    }
    return listActions;
}

export const ProductSaveRule = ({ status, isOwner }: { status: string, isOwner: boolean }) => {
    let listActions: eProductStatus[] = [];
    if (status === eProductStatus.DRAFT) {
        listActions = [eProductStatus.DRAFT, eProductStatus.DELETED, eProductStatus.PUBLISHED]
    }
    else if (status === eProductStatus.DELETED) {
        if (isOwner) {
            listActions = [eProductStatus.RESTORE, eProductStatus.DELETED]
        } else {
            listActions = [eProductStatus.DELETED, eProductStatus.RESTORE]
        }
    }
    else if (status === eProductStatus.PUBLISHED) {
        listActions = [eProductStatus.REMOVED]
    }
    else if (status === eProductStatus.REMOVED) {
        listActions = [eProductStatus.DELETED, eProductStatus.PUBLISHED]
    }
    return listActions;
}

export const ProductButtonRule = ({ status, isOwner }: { status: eProductStatus, isOwner: boolean }) => {
    let listActions: eProductStatus[] = [];
    if (status === eProductStatus.DRAFT) {
        listActions = [eProductStatus.EDITED]
    }
    else if (status === eProductStatus.DELETED) {

    }
    else if (status === eProductStatus.PUBLISHED) {
        listActions = [eProductStatus.EDITED]
    }
    else if (status === eProductStatus.REMOVED) {
        listActions = [eProductStatus.EDITED]
    }
    return listActions;
}


const eProductStatusData = [
    {
        Code: "DELETED",
        Name: "Xóa"
    },
    {
        Code: "DRAFT",
        Name: "Nháp"
    },
    {
        Code: "EDITED",
        Name: "Sửa"
    },
    {
        Code: "PUBLISHED",
        Name: "Đăng bán"
    },
    {
        Code: "REMOVED",
        Name: "Ngưng bán"
    },
    {
        Code: "RESTORE",
        Name: "Khôi phục"
    },
]

export const getProductStatusName = (status: string) => {
    const pageName = eProductStatusData.find(x => x.Code === status);
    return pageName ? pageName.Name : 'Sản phẩm';
}