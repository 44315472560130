import * as Yup from 'yup';

export type ProductTypeModel = {
    id: number;
    name: string;
    icon: string;
    url: string;
    stt: number;
    background: string;
}

export const dfProductTypeModel: ProductTypeModel = {
    id: 0,
    name: '',
    icon: '',
    url: '',
    stt: 0,
    background: '',

}

export const validProductTypeSchema = () => Yup.object().shape({
    name: Yup.string().required("Vui lòng nhập tên loại sản phẩm."),
});