import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {
	BannerPositionAction_DELETE_Request,
	BannerPositionAction_GETALL_Request,
	BannerPositionAction_GETPAGINATION_Request,
	BannerPositionAction_SAVE_Request,
	eBannerPositionActionTypeIds,
} from './IBannerPositionActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { BannerPositionModel } from '../../models/banners/BannerPositionModel';

const _sagaApi = rootApi.bannerPosition;

function* onLoadAllBannerPosition(action: BannerPositionAction_GETALL_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllAsync]);
		if (res.isSuccess && res.result) {
			const listBannerPosition = res.result as BannerPositionModel[];
			yield put(actions.bannerPosition.getAllSuccess(listBannerPosition));
		} else {
			yield put(actions.bannerPosition.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.bannerPosition.getAllFailure(error || 'Có lỗi'));
	}
}

function* onLoadPaginationBannerPosition(action: BannerPositionAction_GETPAGINATION_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listBannerPosition = res.result as BannerPositionModel[];
			yield put(actions.bannerPosition.getPaginationSuccess({ listDatas: listBannerPosition, pagination: res.pagination }));
		} else {
			yield put(actions.bannerPosition.getPaginationFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.bannerPosition.getPaginationFailure(error || 'Có lỗi'));
	}
}

function* onSaveBannerPosition(action: BannerPositionAction_SAVE_Request) {
	try {
		if (action.payload.id > 0) {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PutAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.bannerPosition.saveSuccess(res.message));
				yield put(actions.bannerPosition.needReload());
			} else {
				yield put(actions.bannerPosition.saveFailure(res.message));
			}
		} else {
			const res: BaseResponse = yield call([_sagaApi, _sagaApi.PostAsync], action.payload);
			if (res.isSuccess) {
				yield put(actions.bannerPosition.saveSuccess(res.message));
				yield put(actions.bannerPosition.needReload());
			} else {
				yield put(actions.bannerPosition.saveFailure(res.message));
			}
		}
	} catch (error: any) {
		yield put(actions.bannerPosition.saveFailure(error || 'Có lỗi'));
	}
}

function* onDeleteBannerPosition(action: BannerPositionAction_DELETE_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.DeletesAsync], action.payload);
		if (res.isSuccess) {
			yield put(actions.bannerPosition.deleteSuccess(res.message));
			yield put(actions.bannerPosition.needReload());
		} else {
			yield put(actions.bannerPosition.deleteFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.bannerPosition.deleteFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAllBannerPosition() {
	yield takeEvery(eBannerPositionActionTypeIds.GET_ALL_REQUEST, onLoadAllBannerPosition);
}

function* watchOnLoadPaginationBannerPosition() {
	yield takeEvery(eBannerPositionActionTypeIds.GET_PAGINATION_REQUEST, onLoadPaginationBannerPosition);
}

function* watchOnSaveBannerPosition() {
	yield takeEvery(eBannerPositionActionTypeIds.SAVE_REQUEST, onSaveBannerPosition);
}

function* watchOnDeleteBannerPosition() {
	yield takeEvery(eBannerPositionActionTypeIds.DELETE_REQUEST, onDeleteBannerPosition);
}

function* BannerPositionSaga() {
	yield all([fork(watchOnLoadAllBannerPosition)]);
	yield all([fork(watchOnLoadPaginationBannerPosition)]);
	yield all([fork(watchOnSaveBannerPosition)]);
	yield all([fork(watchOnDeleteBannerPosition)]);
}

export default BannerPositionSaga;
