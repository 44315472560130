import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { PageFooter } from '../../../components/container/PageFooter';
import { dfBasePagination } from '../../../context/base/BasePaginationRespone';
import { BaseSelectRequest, defaultBaseSelectRequest } from '../../../context/base/BaseSelectRequest';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { CategoryModel } from '../../../context/models/category/CategoryModel';
import { categoryActions } from '../../../context/stores/category/categoryActions';
import { AppState } from '../../../context/stores/rootReducers';
import { PaginationRender } from '../../../utils/PaginationHelper';
import { createUUID } from '../../../utils/createUUID';

type ICategoryTableProps = {
	titlePage: string;
	status: eBaseActionStatus;
	categories: CategoryModel[];
	selectedIds: number[];
	handleReloadData: (request: BaseSelectRequest) => void;
	onChangeSelectedIds: (ids: number[]) => void;
};

const mapStateToProps = (state: AppState) => ({
	titlePage: 'Category',
	categories: state.category.categories,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
	handleReloadData: (request: BaseSelectRequest) => dispatch(categoryActions.fetchPaginationRequest(request)),
});

const CategoryTable = (props: ICategoryTableProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { titlePage, status, categories, selectedIds, handleReloadData, onChangeSelectedIds } = props;

	// Thêm searchString vào filter
	const [filter, setFilter] = useState<BaseSelectRequest>({
		...defaultBaseSelectRequest,
		pageIndex: 1,
		pageSize: 10,
	});

	const handleAddEditAction = (id?: number) => {
		navigate(`${location.pathname}/edit/${id || 0}`, { replace: false });
	};

	// Hàm để reload dữ liệu với filter hiện tại
	const handleReloadDataWithFilter = useCallback(() => {
		handleReloadData(filter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filter]);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadDataWithFilter();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	// Cột của bảng
	const columns = useMemo((): any => {
		return [
			<DxTableColumn key={createUUID()} dataField='id' caption='ID' width={100} />,
			<DxTableColumn key={createUUID()} dataField='title' caption='Tiêu đề' minWidth={200} />,
			<DxTableColumn
				key={createUUID()}
				width={50}
				caption='Sửa'
				alignment='center'
				cellRender={(cell: any) => (
					<ButtonLink onClick={() => handleAddEditAction(cell.data.id)} title='Sửa' icon='ni ni-edit' theme='link' />
				)}
			/>,
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => handleAddEditAction()}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={categories}
								keyExpr='id'
								columns={columns}
								hasFixed={true}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={10}
								height={window.innerHeight - 300}
								filters={{
									refreshDataGrid: handleReloadDataWithFilter,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
				<PageFooter>
					{status === eBaseActionStatus.complete && (
						<PaginationRender
							onPageChange={(page) => setFilter((prev) => ({ ...prev, pageIndex: page }))}
							pagination={dfBasePagination} // Bạn có thể điều chỉnh để phản ánh pagination thực tế
							siblingCount={2}
						/>
					)}
				</PageFooter>
			</PageContainer>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTable);
