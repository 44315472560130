import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BaseForm, ButtonCancel, ButtonSave, ModalBody, ModalDraggable, ModalFooter, ModalHeader, TextBox } from '../../../components/base';
import { ImageBox } from '../../../components/base/ImageBox';
import { ConfigPartnerModel, dfConfigPartnerModel, validConfigPartnerSchema } from '../../../context/models/config/PartnerModel';

type IPartnerConfigModalProps = {
	isShow: boolean;
	title: string;
	detailModel?: ConfigPartnerModel;
	isSaving: boolean | false;
	onClose: () => void;
	onSubmit: (data: ConfigPartnerModel) => void;
};

const PartnerPage = (props: IPartnerConfigModalProps) => {
	const { isShow, title, detailModel, onSubmit, onClose, isSaving } = props;
	const isAddMode = !detailModel;
	const [initialValues, setInitialValues] = useState<ConfigPartnerModel>(dfConfigPartnerModel);

	const {
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<ConfigPartnerModel>({
		//@ts-ignore
		resolver: yupResolver(validConfigPartnerSchema()),
		mode: 'all',
		defaultValues: initialValues,
	});

	const onSubmitHandler = (values: ConfigPartnerModel) => {
		onSubmit(values);
	};

	console.log('VALUE', errors);

	useEffect(() => {
		if (initialValues) {
			reset(initialValues);
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialValues, reset]);

	useEffect(() => {
		if (detailModel) {
			setInitialValues((prev) => ({ ...prev, ...detailModel }));
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [detailModel]);

	return (
		<>
			<ModalDraggable show={isShow} sizeClass='sm' type='primary' onClose={onClose}>
				<ModalHeader classDragg={true} title={title} onClose={() => onClose()} />
				<ModalBody>
					<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
						<TextBox
							name={'title'}
							error={errors.title?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, title: value }));
							}}
							value={initialValues.title}
							hasValid={true}
							label='Tên đối tác'
						/>
						<TextBox
							name={'link'}
							error={errors.link?.message}
							onValueChanged={(value) => {
								setInitialValues((prev) => ({ ...prev, link: value }));
							}}
							value={initialValues.link}
							hasValid={true}
							label='Link'
						/>

						<ImageBox
							name='image'
							isDisabled={initialValues.title.length <= 0}
							file_name={initialValues.title}
							value={initialValues.image}
							onDataChanged={(image) => {
								setInitialValues((prev) => ({
									...prev,
									image: image?.path || '',
								}));
							}}
							hasValid={true}
							label='Ảnh'
						/>
					</BaseForm>
				</ModalBody>
				<ModalFooter>
					<ButtonCancel onClick={onClose} isDisabled={isSaving} />
					<ButtonSave
						type='button'
						isLoading={isSaving}
						isDisabled={false}
						onClick={handleSubmit(onSubmitHandler)}
						text={`${!isAddMode ? 'Cập nhật' : 'Thêm mới'}`}
					/>
				</ModalFooter>
			</ModalDraggable>
		</>
	);
};

export default PartnerPage;
