import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DxTable, { DxTableColumn } from '../../../components/common/DxTable';
import { createUUID } from '../../../utils/createUUID';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import { BannerModel } from '../../../context/models/banners/BannerModel';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { AppState } from '../../../context/stores/rootReducers';
import { actions } from '../../../context/stores/rootActions';
import { BannerActionTypes } from '../../../context/stores/banner/IBannerActionsTypes';
import { no_img } from '../../../components/shared/hoc-static';
import { history } from '../../../utils/history';
import { useLocation, useNavigate } from 'react-router-dom';

type IBannerTableProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allBanners: BannerModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<BannerModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleGetDetail: (id: string) => void;
	handleSaveData: (data: BannerModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<BannerModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Banner`,
		status: state.banner.status,
		allBanners: state.banner.allBanners,
		selectedIds: state.banner.selectedIds,
		showDetail: state.banner.showDetail,
		showConfirm: state.banner.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<BannerActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.banner.getAllRequest());
	},
	handleSaveData: (data: BannerModel) => {
		dispatch(actions.banner.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.banner.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.banner.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<BannerModel>) => {
		dispatch(actions.banner.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.banner.showConfirm(isShow));
	},
});

const BannerTable = (props: IBannerTableProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		allBanners,
		handleReloadAllData,
		handleDeleteData,
		onChangeSelectedIds,
		onShowConfirm,
	} = props;

	const handleAddEditAction = (id?: string) => {
		history.replace(location.pathname);
		navigate(`${location.pathname}/edit/${id || '0'}`, { replace: false });
	};

	useEffect(() => {
		handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn
				key={createUUID()}
				dataField='inforBanner'
				caption={`Thông tin Banner`}
				minWidth={300}
				cellRender={(cell: any) => {
					const banner = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='banner-avatar bg-white border border-light d-none d-sm-block d-md-block'>
									<img src={banner.path?.length > 0 ? banner.path : no_img} alt='banner' />
								</div>
								<div className='user-info'>
									<h6 className='tb-lead'>
										<span className='fw-bold'>
											<em className='ni ni-banner text-azure fs-14px'></em>
											<span className='bold _neo_banner_TITLE'>{banner.name}</span>
										</span>
									</h6>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Kích thước: <b>{banner.width}</b>x<b>{banner.height}</b>px
									</p>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Dung lượng: <b>{banner.size}</b>
									</p>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Kiểu: <b>{banner.extension}</b>
									</p>
									<p className='tb-sub  p-0 m-0'>
										Tạo bởi: <b>{banner.createdBy}</b>, ngày tạo: <b>{banner.createdOnDate}</b>; Sửa bởi: <b>{banner.lastModifiedBy}</b>,
										ngày sửa: <b>{banner.lastModifiedOnDate}</b>.
									</p>
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='inforAds'
				caption={`Thông tin quảng cáo`}
				minWidth={300}
				cellRender={(cell: any) => {
					const banner = cell.data;
					return (
						<>
							<div className='user-card'>
								<div className='user-info'>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Tiêu đề: <b>{banner.title}</b>
									</p>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Mô tả: <b>{banner.description}</b>
									</p>
									<p className='tb-sub  p-0 m-0 pb-1'>
										Link: <b>{banner.link}</b>
									</p>
									<p className='tb-sub  p-0 m-0'>
										Ngày hiển thị từ <b>{banner.beginDate}</b>đến <b>{banner.endDate}</b>
									</p>
								</div>
							</div>
						</>
					);
				}}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='inActive'
				minWidth={80}
				caption={`Trạng thái`}
				falseText='Áp dụng'
				trueText='Không'
			></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				allowExporting={true}
				cellRender={(cell: any) => {
					return <ButtonLink onClick={() => handleAddEditAction(cell.data.id)} title='Sửa' icon='ni ni-edit' theme='link' />;
				}}
			/>
		);
		return result;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => handleAddEditAction()}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allBanners}
								keyExpr='id'
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								defaultPageSize={50}
								filters={{
									refreshDataGrid: handleReloadAllData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(BannerTable);
