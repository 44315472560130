export enum eArticleStatus {
    APPROVING = "APPROVING",
    BACK = "BACK",
    DELETED = "DELETED",
    DRAFT = "DRAFT",
    EDITED = "EDITED",
    EDITING = "EDITING",
    PUBLISHED = "PUBLISHED",
    REMOVED = "REMOVED",
    RESTORE = "RESTORE",
    RETURN = "RETURN",
    SENTAPPROVING = "SENTAPPROVING",
    SENTEDITING = "SENTEDITING",
}

export const ArticleActionRule = ({ status, isOwner }: { status: eArticleStatus, isOwner: boolean }) => {
    let listActions: eArticleStatus[] = [];
    if (status === eArticleStatus.DRAFT) {
        listActions = [eArticleStatus.DRAFT, eArticleStatus.SENTAPPROVING, eArticleStatus.SENTEDITING, eArticleStatus.DELETED, eArticleStatus.PUBLISHED]
    }
    else if (status === eArticleStatus.DELETED) {
        if(isOwner){
            listActions = [eArticleStatus.RESTORE, eArticleStatus.DELETED]
        }else{
            listActions = [eArticleStatus.DELETED, eArticleStatus.RESTORE]
        }
    }
    else if (status === eArticleStatus.SENTAPPROVING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.APPROVING]
        }
    }
    else if (status === eArticleStatus.SENTEDITING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.EDITING]
        }
    }
    else if (status === eArticleStatus.APPROVING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.PUBLISHED]
        }
    }
    else if (status === eArticleStatus.EDITING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.SENTAPPROVING]
        }
    }
    else if (status === eArticleStatus.PUBLISHED) {
        listActions = [eArticleStatus.REMOVED]
    }
    else if (status === eArticleStatus.REMOVED) {
        listActions = [eArticleStatus.DELETED, eArticleStatus.PUBLISHED]
    }
    return listActions;
}

export const ArticleSaveRule = ({ status, isOwner }: { status: string, isOwner: boolean }) => {
    let listActions: eArticleStatus[] = [];
    if (status === eArticleStatus.DRAFT) {
        listActions = [eArticleStatus.DRAFT, eArticleStatus.SENTAPPROVING, eArticleStatus.SENTEDITING, eArticleStatus.DELETED, eArticleStatus.PUBLISHED]
    }
    else if (status === eArticleStatus.DELETED) {
        if(isOwner){
            listActions = [eArticleStatus.RESTORE, eArticleStatus.DELETED]
        }else{
            listActions = [eArticleStatus.DELETED, eArticleStatus.RESTORE]
        }
    }
    else if (status === eArticleStatus.SENTAPPROVING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.APPROVING]
        }
    }
    else if (status === eArticleStatus.SENTEDITING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.EDITING]
        }
    }
    else if (status === eArticleStatus.APPROVING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.PUBLISHED]
        }
    }
    else if (status === eArticleStatus.EDITING) {
        if(isOwner){
            listActions = [eArticleStatus.RETURN]
        }else{
            listActions = [eArticleStatus.BACK, eArticleStatus.SENTAPPROVING]
        }
    }
    else if (status === eArticleStatus.PUBLISHED) {
        listActions = [eArticleStatus.REMOVED]
    }
    else if (status === eArticleStatus.REMOVED) {
        listActions = [eArticleStatus.DELETED, eArticleStatus.PUBLISHED]
    }
    return listActions;
}

export const ArticleButtonRule = ({ status, isOwner }: { status: eArticleStatus, isOwner: boolean }) => {
    let listActions: eArticleStatus[] = [];
    if (status === eArticleStatus.DRAFT) {
        listActions = [eArticleStatus.EDITED]
    }
    else if (status === eArticleStatus.DELETED) {
       
    }
    else if (status === eArticleStatus.SENTAPPROVING) {
      
    }
    else if (status === eArticleStatus.SENTEDITING) {
      
    }
    else if (status === eArticleStatus.APPROVING) {
        listActions = [eArticleStatus.EDITED]
    }
    else if (status === eArticleStatus.EDITING) {
        listActions = [eArticleStatus.EDITED]
    }
    else if (status === eArticleStatus.PUBLISHED) {
        listActions = [eArticleStatus.EDITED]
    }
    else if (status === eArticleStatus.REMOVED) {
        listActions = [eArticleStatus.EDITED]
    }
    return listActions;
}


const eArticleStatusData = [
    {
        Code: "APPROVING",
        Name: "Nhận duyệt"
    },
    {
        Code: "BACK",
        Name: "Trả lại"
    },
    {
        Code: "DELETED",
        Name: "Xóa"
    },
    {
        Code: "DRAFT",
        Name: "Nháp"
    },
    {
        Code: "EDITED",
        Name: "Sửa"
    },
    {
        Code: "EDITING",
        Name: "Nhận biên tập"
    },
    {
        Code: "PUBLISHED",
        Name: "Xuất bản"
    },
    {
        Code: "REMOVED",
        Name: "Gỡ xuất bản"
    },
    {
        Code: "RESTORE",
        Name: "Khôi phục"
    },
    {
        Code: "RETURN",
        Name: "Lấy lại"
    },
    {
        Code: "SENTAPPROVING",
        Name: "Gửi duyệt"
    },
    {
        Code: "SENTEDITING",
        Name: "Gửi biên tập"
    }
]

export const getArticleStatusName = (status: string) => {
    const pageName = eArticleStatusData.find(x => x.Code === status);
    return pageName ? pageName.Name : 'Bài viết';
}