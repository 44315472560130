import React, { useState } from 'react';
import { IndeterminateCheckbox, TablePagination } from '../../components/common/TablePagination';
import { Modal } from '../../components/base/Modal';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../components/container';
import { Button, LoadingPanel } from '../../components/base';
import { Editor } from '../../components/base/Editor';

const Dashboard = () => {
	return (
		<>
			<PageContainer>
				<PageHeader title={`Dashboard`}></PageHeader>
				<PageBody></PageBody>
			</PageContainer>
		</>
	);
};

export default Dashboard;
