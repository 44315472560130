import { useEffect, useMemo } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { UserModel } from '../../../context/models/users/UserModel';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { UsersActionTypes } from '../../../context/stores/users/IUsersActionsTypes';
import { createUUID } from '../../../utils/createUUID';
import AccountDetail from './account-detail';

type ISYSAccountPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allUsers: UserModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<UserModel>;
	showConfirm: boolean;
	handleReloadData: () => void;
	handleSaveData: (data: UserModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<UserModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Tài khoản quản trị`,
		status: state.users.status,
		allUsers: state.users.allUsers,
		showDetail: state.users.showDetail,
		selectedIds: state.users.selectedIds,
		showConfirm: state.users.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<UsersActionTypes>) => ({
	handleReloadData: () => {
		dispatch(actions.users.getAllRequest());
	},
	handleSaveData: (data: UserModel) => {
		dispatch(actions.users.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.users.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.users.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<UserModel>) => {
		dispatch(actions.users.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.users.showConfirm(isShow));
	},
});

const SYSAccountPage = (props: ISYSAccountPageProps) => {
	const dispatch = useDispatch();
	const { users_group_all, status: userGroupStatus } = useSelector((state: AppState) => state.usersGroup);
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allUsers,
		handleReloadData,
		onChangeSelectedIds,
		handleDeleteData,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadData();
		if (users_group_all.length === 0 || userGroupStatus === eBaseActionStatus.reload) dispatch(actions.usersGroup.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadData();
		if (users_group_all.length === 0 || userGroupStatus === eBaseActionStatus.reload) dispatch(actions.usersGroup.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn key={createUUID()} dataField='userName' caption={`Tài khoản`} width={150}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='fullName' minWidth={150} caption={`Tên đầy đủ`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='email' width={150} caption={`Email`}></DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='userGroupId' width={150} caption={`Vai trò`}>
				<DxLookup dataSource={users_group_all} valueExpr='id' displayExpr='name' />
			</DxTableColumn>,
			<DxTableColumn key={createUUID()} dataField='isActive' caption={`Hoạt động`} alignment={'center'} width={80}></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				caption={'Thao tác'}
				alignment='center'
				width={80}
				allowExporting={false}
				cellRender={(cell: any) => {
					return (
						<ButtonLink onClick={() => onShowDetail({ isShow: true, detailData: cell.data })} title='Sửa' icon='ni ni-edit' theme='link' />
					);
				}}
			/>
		);
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [users_group_all]);

	const toolBars = useMemo(() => {
		let result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<span>
					Tổng số: <b>{allUsers.length}</b>{' '}
				</span>
			</DxToolbarItem>,
		];
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allUsers]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allUsers}
								keyExpr='id'
								columns={columns}
								defaultPageSize={50}
								isLoading={status !== eBaseActionStatus.complete}
								toolbars={toolBars}
								exportTitle={titlePage}
								filters={{
									refreshDataGrid: handleReloadData,
								}}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<AccountDetail
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(SYSAccountPage);
