import { all, call, put, fork, takeEvery } from 'redux-saga/effects';
import { actions } from '../rootActions';
import {

	KeywordSeoAction_GETKEYWORD_Request,
	eKeywordSeoActionTypeIds,
} from './IKeywordSeoActionsTypes';
import { BaseResponse } from '../../base/BaseResponse';
import { rootApi } from '../../api/rootApi';
import { KeywordSeoModel } from '../../models/crm/KeywordSeoModel';

const _sagaApi = rootApi.keywordSeo;

function* onLoadAllKeywordSeo() {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetAllKeywordAsync]);
		if (res.isSuccess && res.result) {
			const listCollection = res.result as KeywordSeoModel[];
			yield put(actions.keywordSeo.getAllSuccess(listCollection));
		} else {
			yield put(actions.keywordSeo.getAllFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.keywordSeo.getAllFailure(error || 'Có lỗi'));
	}
}

function* onLoadKeywordSeo(action: KeywordSeoAction_GETKEYWORD_Request) {
	try {
		const res: BaseResponse = yield call([_sagaApi, _sagaApi.GetKeywordBySearchPaginationAsync], action.payload);
		if (res.isSuccess && res.result) {
			const listKeyword = res.result as KeywordSeoModel[];
			yield put(actions.keywordSeo.getKeywordSuccess(listKeyword));
		} else {
			yield put(actions.keywordSeo.getKeywordFailure(res.message));
		}
	} catch (error: any) {
		yield put(actions.keywordSeo.getKeywordFailure(error || 'Có lỗi'));
	}
}

function* watchOnLoadAll() {
	yield takeEvery(eKeywordSeoActionTypeIds.GET_ALL_REQUEST, onLoadAllKeywordSeo);
}

function* watchOnLoadKeywordSeo() {
	yield takeEvery(eKeywordSeoActionTypeIds.GET_KEYWORD_REQUEST, onLoadKeywordSeo);
}

function* KeywordSeoSaga() {
	yield all([fork(watchOnLoadKeywordSeo)]);
	yield all([fork(watchOnLoadAll)]);
}

export default KeywordSeoSaga;
