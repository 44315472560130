import { ApiBase } from "../../base/_ApiBase";
import { ApiClient } from "../../base/_ApiClient";
import { KeywordModel } from "../../models/keyword/KeywordModel";
import { KeywordRequest } from "../../models/keyword/KeywordRequest";

export const KeywordApi = new class KeywordApi extends ApiBase<KeywordModel>{
    constructor({ baseUrl }: { baseUrl: string; }) {
        super(baseUrl);
    }
    GetKeywordByCategoryAsync(data: KeywordRequest) {
		return ApiClient.GET(`${this._baseUrl}/tu-khoa-theo-danh-muc?catID=${data.catID}`);
	}
}({ baseUrl: '/keyword' });