import { accountActions } from './auth/accountActions';
import { articleCategoryActions } from './articles-category/articleCategoryActions';
import { articleActions } from './articles/articleActions';
import { productCategoryActions } from './products-categroy/productCategoryActions';
import { productActions } from './products/productActions';
import { roleScopesActions } from './roles/roleScopesActions';
import { rolesActions } from './roles/rolesActions';
import { usersActions } from './users/usersActions';
import { menusActions } from './menus/menusActions';
import { userGroupActions } from './users-group/userGroupActions';
import { CollectionActions } from './collections/collectionActions';
import { productImageActions } from './products-image/productImageActions';
import { BannerPositionActions } from './banner-position/bannerPositionActions';
import { bannerActions } from './banner/bannerActions';
import { BannerConfigActions } from './banner-config/bannerConfigActions';
import { CollectionTypeActions } from './collections-type/collectionTypeActions';
import { CollectionGroupActions } from './collections-group/collectionGroupActions';
import { CollectionItemActions } from './collections-item/collectionItemActions';
import { keywordActions } from './keyword/keywordActions';
import { KeywordSeoActions } from './KeowordSeo/keywordSeoActions';
import { productTypeActions } from './product-type/productTypeActions';
import { productTypeProductActions } from './product-type-product/productTypeProductActions';
import { categoryActions } from './category/categoryActions';
export const actions = {
	users: usersActions,
	usersGroup: userGroupActions,
	menus: menusActions,
	account: accountActions,
	roles: rolesActions,
	roleScopes: roleScopesActions,
	articleCategory: articleCategoryActions,
	article: articleActions,
	product: productActions,
	productType: productTypeActions,
	productTypeProduct: productTypeProductActions,
	keyword: keywordActions,
	productCategory: productCategoryActions,
	bannerPosition: BannerPositionActions,
	banner: bannerActions,
	bannerConfig: BannerConfigActions,
	productImage: productImageActions,
	collection: CollectionActions,
	collectionType: CollectionTypeActions,
	collectionGroup: CollectionGroupActions,
	collectionItem: CollectionItemActions,
	keywordSeo: KeywordSeoActions,
	category: categoryActions,
};
