import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { BaseShowDetail } from '../../base/BaseShowDetail';
import { IActionTypeBase } from '../../base/IBaseActionTypes';
import { ProductByCategoryRequest } from '../../models/product-type/ProductByCategoryRequest';
import { ProductByCategoryResponse } from '../../models/product-type/ProductByCategoryResponse';
import { ProductTypeProductModel } from '../../models/product-type/ProductTypeProductModel';
import { ProductTypeProductRequest } from '../../models/product-type/ProductTypeProductRequest';
import { ProductTypeProductResponse } from '../../models/product-type/ProductTypeProductResponse';
import { ProductTypeSaveRequest } from '../../models/product-type/ProductTypeSaveRequest';

export enum eProductTypeProductActionTypeIds {
	GET_ALL_REQUEST = 'ProductTypeProduct_GET_ALL_REQUEST',
	GET_ALL_SUCCESS = 'ProductTypeProduct_GET_ALL_SUCCESS',
	GET_ALL_FAILURE = 'ProductTypeProduct_GET_ALL_FAILURE',

	GET_BY_CATEGORY_REQUEST = 'ProductTypeProduct_GET_BY_CATEGORY_REQUEST',
	GET_BY_CATEGORY_SUCCESS = 'ProductTypeProduct_GET_BY_CATEGORY_SUCCESS',
	GET_BY_CATEGORY_FAILURE = 'ProductTypeProduct_GET_BY_CATEGORY_FAILURE',

	GET_PAGINATION_REQUEST = 'ProductTypeProduct_GET_PAGINATION_REQUEST',
	GET_PAGINATION_SUCCESS = 'ProductTypeProduct_GET_PAGINATION_SUCCESS',
	GET_PAGINATION_FAILURE = 'ProductTypeProduct_GET_PAGINATION_FAILURE',

	SAVE_REQUEST = 'ProductTypeProduct_SAVE_REQUEST',
	SAVE_SUCCESS = 'ProductTypeProduct_SAVE_SUCCESS',
	SAVE_FAILURE = 'ProductTypeProduct_SAVE_FAILURE',

	DELETE_REQUEST = 'ProductTypeProduct_DELETE_REQUEST',
	DELETE_SUCCESS = 'ProductTypeProduct_DELETE_SUCCESS',
	DELETE_FAILURE = 'ProductTypeProduct_DELETE_FAILURE',

    PRODUCT_TYPE_SAVE_REQUEST = 'ProductTypeProduct_PRODUCT_SAVE_REQUEST',
	PRODUCT_TYPE_SAVE_SUCCESS = 'ProductTypeProduct_PRODUCT_SAVE_SUCCESS',
	PRODUCT_TYPE_SAVE_FAILURE = 'ProductTypeProduct_PRODUCT_SAVE_FAILURE',

	NEED_RELOAD = 'ProductTypeProduct_NEED_RELOAD',

	CHANGE_SELECTED_IDS = 'ProductTypeProduct_CHANGE_SELECTED_IDS',
	CHANGE_SELECTED_PRODUCT_IDS = 'ProductTypeProduct_CHANGE_SELECTED_PRODUCT_IDS',
	SHOW_DETAIL = 'ProductTypeProduct_SHOW_DETAIL',
	SHOW_CONFIRM = 'ProductTypeProduct_SHOW_CONFIRM',
}

export interface ProductTypeProductAction_GETALL_Request
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_ALL_REQUEST, ProductTypeProductRequest> {}
export interface ProductTypeProductAction_GETALL_Success
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_ALL_SUCCESS, ProductTypeProductResponse[]> {}
export interface ProductTypeProductAction_GETALL_Failure
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_ALL_FAILURE, Error | string> {}

export interface ProductTypeProductAction_GETBY_CATEGORY_Request
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_BY_CATEGORY_REQUEST, ProductByCategoryRequest> {}
export interface ProductTypeProductAction_GETBY_CATEGORY_Success
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_BY_CATEGORY_SUCCESS, ProductTypeProductResponse[]> {}
export interface ProductTypeProductAction_GETBY_CATEGORY_Failure
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_BY_CATEGORY_FAILURE, Error | string> {}

export interface ProductTypeProductAction_GETPAGINATION_Request
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_PAGINATION_REQUEST, BaseSelectRequest> {}
export interface ProductTypeProductAction_GETPAGINATION_Success
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_PAGINATION_SUCCESS, BasePaginationRespone<ProductTypeProductResponse>> {}
export interface ProductTypeProductAction_GETPAGINATION_Failure
	extends IActionTypeBase<eProductTypeProductActionTypeIds.GET_PAGINATION_FAILURE, Error | string> {}

export interface ProductTypeProductAction_SAVE_Request
	extends IActionTypeBase<eProductTypeProductActionTypeIds.SAVE_REQUEST, ProductTypeProductResponse> {}
export interface ProductTypeProductAction_SAVE_Success extends IActionTypeBase<eProductTypeProductActionTypeIds.SAVE_SUCCESS, string> {}
export interface ProductTypeProductAction_SAVE_Failure
	extends IActionTypeBase<eProductTypeProductActionTypeIds.SAVE_FAILURE, Error | string> {}

export interface ProductTypeProductAction_DELETE_Request
	extends IActionTypeBase<eProductTypeProductActionTypeIds.DELETE_REQUEST, number[]> {}
export interface ProductTypeProductAction_DELETE_Success extends IActionTypeBase<eProductTypeProductActionTypeIds.DELETE_SUCCESS, string> {}
export interface ProductTypeProductAction_DELETE_Failure
	extends IActionTypeBase<eProductTypeProductActionTypeIds.DELETE_FAILURE, Error | string> {}

export interface ProductTypeProductAction_SAVEPRODUCT_Request
	extends IActionTypeBase<eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_REQUEST, ProductTypeSaveRequest> {}
export interface ProductTypeProductAction_SAVEPRODUCT_Success extends IActionTypeBase<eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_SUCCESS, string> {}
export interface ProductTypeProductAction_SAVEPRODUCT_Failure extends IActionTypeBase<eProductTypeProductActionTypeIds.PRODUCT_TYPE_SAVE_FAILURE, Error | string> {}

export interface ProductTypeProductAction_RELOAD extends IActionTypeBase<eProductTypeProductActionTypeIds.NEED_RELOAD, undefined> {}

export interface ProductTypeProductAction_SHOW_DETAIL
	extends IActionTypeBase<eProductTypeProductActionTypeIds.SHOW_DETAIL, BaseShowDetail<ProductTypeProductResponse>> {}
export interface ProductTypeProductAction_CHANGE_SELECTED_IDS
	extends IActionTypeBase<eProductTypeProductActionTypeIds.CHANGE_SELECTED_IDS, number[]> {}
export interface ProductTypeProductAction_CHANGE_SELECTED_PRODUCT_IDS
	extends IActionTypeBase<eProductTypeProductActionTypeIds.CHANGE_SELECTED_PRODUCT_IDS, number[]> {}
export interface ProductTypeProductAction_SHOW_CONFIRM extends IActionTypeBase<eProductTypeProductActionTypeIds.SHOW_CONFIRM, boolean> {}

export type ProductTypeProductActionTypes =
	| ProductTypeProductAction_GETALL_Request
	| ProductTypeProductAction_GETALL_Success
	| ProductTypeProductAction_GETALL_Failure
	| ProductTypeProductAction_GETBY_CATEGORY_Request
	| ProductTypeProductAction_GETBY_CATEGORY_Success
	| ProductTypeProductAction_GETBY_CATEGORY_Failure
	| ProductTypeProductAction_GETPAGINATION_Request
	| ProductTypeProductAction_GETPAGINATION_Success
	| ProductTypeProductAction_GETPAGINATION_Failure
	| ProductTypeProductAction_SAVE_Request
	| ProductTypeProductAction_SAVE_Success
	| ProductTypeProductAction_SAVE_Failure
	| ProductTypeProductAction_DELETE_Request
	| ProductTypeProductAction_DELETE_Success
	| ProductTypeProductAction_DELETE_Failure
    | ProductTypeProductAction_SAVEPRODUCT_Request
	| ProductTypeProductAction_SAVEPRODUCT_Success
	| ProductTypeProductAction_SAVEPRODUCT_Failure
	| ProductTypeProductAction_RELOAD
	| ProductTypeProductAction_SHOW_DETAIL
	| ProductTypeProductAction_CHANGE_SELECTED_IDS
	| ProductTypeProductAction_CHANGE_SELECTED_PRODUCT_IDS
	| ProductTypeProductAction_SHOW_CONFIRM;
