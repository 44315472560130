import axios, { AxiosError, AxiosResponse } from "axios";

export const axiosClient = axios.create({
});
axiosClient.interceptors.request.use((config) => {
    return config;
},
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

axiosClient.interceptors.response.use(
    (res: AxiosResponse) => {
        return res.data;
    },
    async (error: AxiosError) => {
        return Promise.reject(error);
    }
);