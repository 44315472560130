import { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { Button, ButtonLink, LoadingTable } from '../../../components/base';
import { ConfirmModal } from '../../../components/common/ConfirmModal';
import DxTable, { DxLookup, DxTableColumn, DxToolbarItem } from '../../../components/common/DxTable';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { BadgesRoleScopeName } from '../../../components/shared/system';
import { BaseShowDetail } from '../../../context/base/BaseShowDetail';
import { eBaseActionStatus } from '../../../context/base/eBaseActionStatus';
import { RoleModel, RoleResponse } from '../../../context/models/roles/RoleModel';
import { RoleScopeModel } from '../../../context/models/roles/RoleScopeModel';
import { RolesActionTypes } from '../../../context/stores/roles/IRolesActionsTypes';
import { actions } from '../../../context/stores/rootActions';
import { AppState } from '../../../context/stores/rootReducers';
import { createUUID } from '../../../utils/createUUID';
import RoleDetailModal from './detail-modal';

type IRolesPageProps = {
	titlePage: string;
	status: eBaseActionStatus;
	allDatas: RoleResponse[];
	allRoleScopes: RoleScopeModel[];
	selectedIds: number[];
	showDetail: BaseShowDetail<RoleModel>;
	showConfirm: boolean;
	handleReloadAllData: () => void;
	handleSaveData: (data: RoleModel) => void;
	handleDeleteData: (data: number[]) => void;
	onChangeSelectedIds: (ids: number[]) => void;
	onShowDetail: (data: BaseShowDetail<RoleModel>) => void;
	onShowConfirm: (isShow: boolean) => void;
};

const mapStateToProps = (state: AppState) => {
	return {
		titlePage: `Tác nhân`,
		status: state.roles.status,
		allDatas: state.roles.allRoles,
		allRoleScopes: state.roleScopes.allRoleScopes,
		selectedIds: state.roles.selectedIds,
		showDetail: state.roles.showDetail,
		showConfirm: state.roles.showConfirm,
	};
};

const mapDispatchToProps = (dispatch: Dispatch<RolesActionTypes>) => ({
	handleReloadAllData: () => {
		dispatch(actions.roles.getAllRequest());
	},
	handleSaveData: (data: RoleModel) => {
		dispatch(actions.roles.saveRequest(data));
	},
	handleDeleteData: (ids: number[]) => {
		dispatch(actions.roles.deleteRequest(ids));
	},
	onChangeSelectedIds: (ids: number[]) => {
		dispatch(actions.roles.changeSelectedIds(ids));
	},
	onShowDetail: (data: BaseShowDetail<RoleModel>) => {
		dispatch(actions.roles.showDetail(data));
	},
	onShowConfirm: (isShow: boolean) => {
		dispatch(actions.roles.showConfirm(isShow));
	},
});

const RolesPage = (props: IRolesPageProps) => {
	const dispatch = useDispatch();
	const {
		titlePage,
		status,
		selectedIds,
		showConfirm,
		showDetail,
		allDatas,
		allRoleScopes,
		handleReloadAllData,
		onChangeSelectedIds,
		handleDeleteData,
		handleSaveData,
		onShowDetail,
		onShowConfirm,
	} = props;

	useEffect(() => {
		handleReloadAllData();
		if (allRoleScopes.length === 0) dispatch(actions.roleScopes.getAllRequest());
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (status === eBaseActionStatus.reload) handleReloadAllData();
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status]);

	const calculateFilterExpression = (filterValue: any, selectedFilterOperation: any, target: any) => {
		if (target === 'search' && typeof filterValue === 'string') {
			return [target.dataField, 'contains', filterValue];
		}
		return function (data: any) {
			return (data.roleScopes || []).indexOf(filterValue) !== -1;
		};
	};

	const columns = useMemo((): any => {
		let result: any = [
			<DxTableColumn allowHeaderFiltering={false} key={createUUID()} dataField='name' caption={`Tên`} width={150}></DxTableColumn>,
			<DxTableColumn
				allowHeaderFiltering={false}
				key={createUUID()}
				dataField='description'
				minWidth={150}
				caption={`Mô tả`}
			></DxTableColumn>,
			<DxTableColumn
				key={createUUID()}
				dataField='roleScopes'
				width={250}
				caption={`Quyền hạn`}
				cellRender={(cell: any) => {
					return <BadgesRoleScopeName value={cell.value} />;
				}}
				calculateFilterExpression={calculateFilterExpression}
			>
				<DxLookup dataSource={allRoleScopes} valueExpr='name' displayExpr='name' />
			</DxTableColumn>,
			<DxTableColumn width={80} allowHeaderFiltering={false} key={createUUID()} dataField='isActive' caption={`Áp dụng`}></DxTableColumn>,
			<DxTableColumn
				width={80}
				allowHeaderFiltering={false}
				key={createUUID()}
				dataField='isProtected'
				caption={`Không sửa đổi`}
			></DxTableColumn>,
		];
		result.push(
			<DxTableColumn
				visibleIndex={1}
				key={createUUID()}
				width={50}
				caption={'Sửa'}
				alignment='center'
				cellRender={(cell: any) => {
					const isProtected = cell.data.isProtected;
					return (
						<>
							{!isProtected && (
								<ButtonLink
									onClick={() => onShowDetail({ isShow: true, detailData: cell.data })}
									title='Sửa'
									icon='ni ni-edit'
									theme='link'
								/>
							)}
						</>
					);
				}}
			/>
		);
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoleScopes]);

	const toolBars = useMemo(() => {
		let result: any = [
			<DxToolbarItem key={createUUID()} location='before'>
				<span>
					Tổng số: <b>{allRoleScopes.length}</b>{' '}
				</span>
			</DxToolbarItem>,
		];
		return result;
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allRoleScopes]);

	return (
		<>
			<PageContainer>
				<PageHeader title={`Danh sách ${titlePage}`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<li>
								<Button
									text={`Xóa (${selectedIds.length})`}
									icon='icon ni ni-trash'
									className='d-md-inline-flex'
									theme='danger'
									isDisabled={selectedIds.length <= 0}
									onClick={() => onShowConfirm(true)}
								/>
							</li>

							<li>
								<Button
									text='Tạo mới'
									icon='icon ni ni-plus'
									theme='primary'
									className='d-md-inline-flex'
									onClick={() => onShowDetail({ isShow: true })}
								/>
							</li>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						{status === eBaseActionStatus.loading && <LoadingTable />}
						{status !== eBaseActionStatus.loading && (
							<DxTable
								dataSource={allDatas}
								keyExpr='id'
								filters={{
									refreshDataGrid: () => handleReloadAllData(),
								}}
								exportTitle={titlePage}
								isHeaderFilter={false}
								columns={columns}
								isLoading={status !== eBaseActionStatus.complete}
								toolbars={toolBars}
								selection={{
									mode: 'multiple',
									onSelectionChanged: (e: any) => {
										onChangeSelectedIds(e.selectedRowKeys || []);
									},
									selectedRowKeys: selectedIds,
								}}
							/>
						)}
					</BlockCard>
				</PageBody>
			</PageContainer>
			{showDetail && showDetail.isShow && (
				<>
					<RoleDetailModal
						isSaving={status === eBaseActionStatus.saving}
						isShow={showDetail.isShow}
						detailModel={showDetail.detailData}
						onClose={() => onShowDetail({ isShow: false })}
						onSubmit={(data) => handleSaveData(data)}
						title={`${showDetail.detailData ? `Cập nhật` : `Thêm mới`} ${titlePage}`}
					/>
				</>
			)}
			{showConfirm && (
				<>
					<ConfirmModal
						show={showConfirm}
						innerText={`Bạn chắc chắn muốn xóa ${selectedIds.length} ${titlePage.toLowerCase()} đã chọn ?`}
						type='danger'
						onClose={() => onShowConfirm(false)}
						onConfirmed={() => handleDeleteData(selectedIds)}
						isLoading={status === eBaseActionStatus.loading || status === eBaseActionStatus.saving}
						btnConfirmText='Xóa'
						btnCloseText='Không xóa'
					/>
				</>
			)}
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(RolesPage);
