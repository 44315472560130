import MenuPage from './pages/accounts/menus';
import RolesPage from './pages/accounts/roles';
import SYSAccountPage from './pages/accounts/sys-accounts';
import UsersGroupPage from './pages/accounts/users-group';
import ArticleCategoryPage from './pages/articles-category';
import ArticleEditPage from './pages/articles/edit-page';
import {
	APPROVINGPage,
	DELETEDPage,
	DRAFTPage,
	EDITINGPage,
	PUBLISHEDPage,
	REMOVEDPage,
	SENTAPPROVINGPage,
	SENTEDITINGPage,
	WAITAPPROVINGPage,
	WAITEDITINGPage,
} from './pages/articles/status-page';
import BannerPositionPage from './pages/banner-position';
import BannerTable from './pages/banner/banner-table';
import BannerEditPage from './pages/banner/edit-page';
import CategoryTable from './pages/category/category-table';
import CategoryEditPage from './pages/category/edit-page';
import CollectionPage from './pages/collections';
import CollectionGroupPage from './pages/collections-group';
import CollectionItemPage from './pages/collections-item';
import CollectionTypePage from './pages/collections-type';
import FooterEditPage from './pages/footer/edit-page';
import FooterTable from './pages/footer/footer-table';
import { Dashboard } from './pages/home';
import KeywordPage from './pages/keyword';
import StoreLocationPage from './pages/location-store/StoreLocationPage';
import PartnerTable from './pages/partner/partner-table';
import ProductTypePage from './pages/product-type';
import ProductTypeProductPage from './pages/product-type-product/ProductTypeProductPage';
import ProductCategoryPage from './pages/products-category';
import ProductEditPage from './pages/products/edit-page';
import { ProductDeletedPage, ProductDraftPage, ProductPublishedPage, ProductRemovedPage } from './pages/products/status-page';
import PromotionPage from './pages/promotion/PromotionPage';
export type AppComponent = {
	title: string;
	path: string;
	component: any;
};

const AppRoutes = [
	{
		title: 'Dashboard',
		path: '/',
		component: Dashboard,
	},
	{
		title: 'Tài khoản',
		path: '/accounts',
		component: SYSAccountPage,
	},
	{
		title: 'Phan quyền chức năng',
		path: '/menu-roles',
		component: MenuPage,
	},
	{
		title: 'Tác nhân',
		path: '/users-group',
		component: UsersGroupPage,
	},
	{
		title: 'Quyền',
		path: '/roles',
		component: RolesPage,
	},
	{
		title: 'Chuyên mục',
		path: '/articles-category',
		component: ArticleCategoryPage,
	},
	{ title: 'Tin bài', path: '/articles-template', component: DRAFTPage },
	{ title: 'Tin bài', path: '/articles-published', component: PUBLISHEDPage },
	{ title: 'Tin bài', path: '/articles-deleted', component: DELETEDPage },
	{ title: 'Tin bài', path: '/articles-editing', component: EDITINGPage },
	{ title: 'Tin bài', path: '/articles-removed', component: REMOVEDPage },
	{ title: 'Tin bài', path: '/articles-sent-approve', component: SENTAPPROVINGPage },
	{ title: 'Tin bài', path: '/articles-sent-edit', component: SENTEDITINGPage },
	{ title: 'Tin bài', path: '/articles-wait-approving', component: WAITAPPROVINGPage },
	{ title: 'Tin bài', path: '/articles-wait-editing', component: WAITEDITINGPage },
	{ title: 'Tin bài', path: '/articles-approving', component: APPROVINGPage },
	{
		title: 'Soạn nội dung bài viết lưu tạm',
		path: '/articles-template/edit/:id',
		component: ArticleEditPage,
	},
	{
		title: 'Soạn nội dung bài viết lưu tạm',
		path: '/articles-published/edit/:id',
		component: ArticleEditPage,
	},
	{
		title: 'Danh mục',
		path: '/products-category',
		component: ProductCategoryPage,
	},
	{ title: 'SP', path: '/products-template', component: ProductDraftPage },
	{ title: 'SP', path: '/products-published', component: ProductPublishedPage },
	{ title: 'SP', path: '/products-deleted', component: ProductDeletedPage },
	{ title: 'SP', path: '/products-removed', component: ProductRemovedPage },
	{ title: 'SP', path: '/keyword', component: KeywordPage },

	{ title: 'SP', path: '/products-template/edit/:id', component: ProductEditPage },
	{ title: 'SP', path: '/products-published/edit/:id', component: ProductEditPage },
	{ title: 'SP', path: '/products-deleted/edit/:id', component: ProductEditPage },
	{ title: 'SP', path: '/products-removed/edit/:id', component: ProductEditPage },

	{ title: 'SP', path: '/product-type/', component: ProductTypePage },
	{ title: 'SP', path: '/product-type-product/', component: ProductTypeProductPage },

	{ title: 'Bộ sưu tập', path: '/collections', component: CollectionPage },
	{ title: 'Bộ sưu tập', path: '/collections-type', component: CollectionTypePage },
	{ title: 'Bộ sưu tập', path: '/collections-group', component: CollectionGroupPage },
	{ title: 'Bộ sưu tập', path: '/collections-item', component: CollectionItemPage },

	{
		title: 'Banner',
		path: '/ads-items',
		component: BannerTable,
	},
	{
		title: 'Banner',
		path: '/ads-items/edit/:id',
		component: BannerEditPage,
	},
	{
		title: 'Vị trí banner',
		path: '/ads-position',
		component: BannerPositionPage,
	},

	{
		title: 'Footer',
		path: '/footers',
		component: FooterTable,
	},
	{
		title: 'Footer',
		path: '/footers/edit/:id',
		component: FooterEditPage,
	},

	{
		title: 'Category',
		path: '/categories',
		component: CategoryTable,
	},
	{
		title: 'Category',
		path: '/categories/edit/:id',
		component: CategoryEditPage,
	},
	{
		title: 'Partner',
		path: '/config/partner',
		component: PartnerTable,
	},
	{
		title: 'Store Location',
		path: '/config/store-location',
		component: StoreLocationPage,
	},
	{
		title: 'Khuyến mại',
		path: '/config/promotion',
		component: PromotionPage,
	},
] as AppComponent[];
export default AppRoutes.map((route) => {
	return {
		...route,
		Component: route.component as React.FC,
	};
});
