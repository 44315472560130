import { CategoryModel } from '../../models/category/CategoryModel';

export interface ICategoryState {
  categories: CategoryModel[];
  loading: boolean;
  error: string | null;
}

export const dfCategoryState: ICategoryState = {
  categories: [],
  loading: false,
  error: null,
};
