import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dispatch } from 'redux';
import { BaseForm, ButtonSave, SwitchBox, TextBox } from '../../../components/base';
import { BlockCard, PageBody, PageContainer, PageHeader } from '../../../components/container';
import { useDispatch } from 'react-redux';

type FooterModel = {
	id?: number;
	categoryName: string;
	categoryLink: string;
	subcategoryName: string;
	subcategoryLink: string;
	inActive: boolean;
};

const mapStateToProps = () => {
	return {
	
	};
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
	
});

const FooterEditPage = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const footerId = useMemo(() => parseInt(id || '0'), [id]);
	const navigate = useNavigate();

	const fakeFooterData: FooterModel[] = [
		{
			id: 1,
			categoryName: 'Liên hệ',
			categoryLink: '/contact',
			subcategoryName: 'Email',
			subcategoryLink: '/contact/email',
			inActive: false,
		},
		{
			id: 2,
			categoryName: 'Giới thiệu',
			categoryLink: '/about',
			subcategoryName: 'Tầm nhìn',
			subcategoryLink: '/about/vision',
			inActive: true,
		},
		{
			id: 3,
			categoryName: 'Hỗ trợ',
			categoryLink: '/support',
			subcategoryName: 'FAQ',
			subcategoryLink: '/support/faq',
			inActive: false,
		}
	];

	const initialData: FooterModel = fakeFooterData.find((footer) => footer.id === footerId) || {
		categoryName: '',
		categoryLink: '',
		subcategoryName: '',
		subcategoryLink: '',
		inActive: false,
	};

	const {
		handleSubmit,
		control,
		setValue,
		formState: { errors },
	} = useForm<FooterModel>({
		mode: 'onChange',
		defaultValues: initialData,
	});

	useEffect(() => {
		if (initialData) {
			(Object.keys(initialData) as (keyof FooterModel)[]).forEach((key) => {
				setValue(key, initialData[key]);
			});
		}
		//eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialData]);

	const onSubmitHandler = (values: FooterModel) => {
		console.log('Đã lưu dữ liệu:', values);
		navigate('/');
	};

	return (
		<>
			<PageContainer>
				<PageHeader title={`Chỉnh sửa Footer`}>
					<div className='nk-fmg-actions'>
						<ul className='nk-block-tools g-3'>
							<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
								<ButtonSave type='button' onClick={handleSubmit(onSubmitHandler)} />
							</BaseForm>
						</ul>
					</div>
				</PageHeader>
				<PageBody>
					<BlockCard>
						<BaseForm onSubmit={handleSubmit(onSubmitHandler)}>
							<div className='row g-4'>
								<div className='col-xl-9 col-sm-12'>
									<div className='nk-block g-4'>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='name'>Tên Category</h6>
											</div>
											<div className='col-9'>
												<Controller
													name='categoryName'
													control={control}
													render={({ field }) => (
														<TextBox
															{...field}
															value={field.value}
															error={errors.categoryName?.message}
															onValueChanged={(value) => field.onChange(value)}
														/>
													)}
												/>
											</div>
										</div>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='catLink'>Link Category</h6>
											</div>
											<div className='col-9'>
												<Controller
													name='categoryLink'
													control={control}
													render={({ field }) => (
														<TextBox
															{...field}
															value={field.value}
															error={errors.categoryLink?.message}
															onValueChanged={(value) => field.onChange(value)}
														/>
													)}
												/>
											</div>
										</div>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='subcatName'>Tên Subcategory</h6>
											</div>
											<div className='col-9'>
												<Controller
													name='subcategoryName'
													control={control}
													render={({ field }) => (
														<TextBox
															{...field}
															value={field.value}
															error={errors.subcategoryName?.message}
															onValueChanged={(value) => field.onChange(value)}
														/>
													)}
												/>
											</div>
										</div>
										<div className='flex flex-row align-center'>
											<div className='col-3 nk-block-head nk-block-head-sm nk-block-between'>
												<h6 className='subcatLink'>Link Subcategory</h6>
											</div>
											<div className='col-9'>
												<Controller
													name='subcategoryLink'
													control={control}
													render={({ field }) => (
														<TextBox
															{...field}
															value={field.value}
															error={errors.subcategoryLink?.message}
															onValueChanged={(value) => field.onChange(value)}
														/>
													)}
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='col-xl-3'>
									<div className='nk-block'>
										<div className='nk-block-head nk-block-head-sm nk-block-between'>
											<h6 className='metaTitle'>Trạng thái</h6>
										</div>
										<Controller
											name='inActive'
											control={control}
											render={({ field }) => (
												<SwitchBox
													{...field}
													label='Áp dụng'
													error={errors.inActive?.message}
													onValueChanged={(value) => field.onChange(value)}
													value={field.value}
													sizeClass='sm'
												/>
											)}
										/>
									</div>
								</div>
							</div>
						</BaseForm>
					</BlockCard>
				</PageBody>
			</PageContainer>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterEditPage);
