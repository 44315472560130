import { CollectionActionTypes, eCollectionActionTypeIds } from "./ICollectionActionsTypes"
import { BaseGetActionStatus, eBaseActionStatus } from "../../base/eBaseActionStatus"
import { NotifyHelper } from "../../../utils/NotifyHelper"
import { ICollectionState } from "./ICollectionState"
import { CollectionModel } from "../../models/collections/CollectionModel";
import { BasePaginationRespone, dfBasePagination } from "../../base/BasePaginationRespone";
import { dfBaseShowDetail } from "../../base/BaseShowDetail";

const initPaginationResponse: BasePaginationRespone<CollectionModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: ICollectionState = {
    status: eBaseActionStatus.idle,
    allCollections: [],
    selectedIds: [],
    showConfirm: false,
    paginationResponse: initPaginationResponse,
    showDetail: dfBaseShowDetail
}
const CollectionReducer = (state: ICollectionState = initialState, action: CollectionActionTypes): ICollectionState => {
    switch (action.type) {
        case eCollectionActionTypeIds.GET_ALL_REQUEST:
        case eCollectionActionTypeIds.GET_PAGINATION_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.GET_ALL_SUCCESS:
            return {
                ...state,
                allCollections: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.GET_PAGINATION_SUCCESS:
            return {
                ...state,
                paginationResponse: action.payload,
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.GET_ALL_FAILURE:
            return {
                ...state,
                allCollections: [],
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.GET_PAGINATION_FAILURE:
            return {
                ...state,
                paginationResponse: initPaginationResponse,
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.CHANGE_SELECTED_IDS:
            return {
                ...state,
                selectedIds: action.payload,
            }
        case eCollectionActionTypeIds.SHOW_DETAIL:
            return {
                ...state,
                showDetail: action.payload
            }
        case eCollectionActionTypeIds.SHOW_CONFIRM:
            return {
                ...state,
                showConfirm: action.payload
            }
        case eCollectionActionTypeIds.SAVE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.DELETE_REQUEST:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        case eCollectionActionTypeIds.SAVE_SUCCESS:
            NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eCollectionActionTypeIds.DELETE_SUCCESS:
            NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eCollectionActionTypeIds.SAVE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                showDetail: dfBaseShowDetail,
                status: BaseGetActionStatus(action),
            }
        case eCollectionActionTypeIds.DELETE_FAILURE:
            NotifyHelper.Error(action.payload.toString());
            return {
                ...state,
                selectedIds: [],
                showConfirm: false,
                status: BaseGetActionStatus(action),
            }
        case eCollectionActionTypeIds.NEED_RELOAD:
            return {
                ...state,
                status: BaseGetActionStatus(action)
            }
        default:
            return state;
    }
}
export default CollectionReducer;