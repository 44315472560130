import { BannerActionTypes, eBannerActionTypeIds } from './IBannerActionsTypes';
import { BaseGetActionStatus, eBaseActionStatus } from '../../base/eBaseActionStatus';
import { NotifyHelper } from '../../../utils/NotifyHelper';
import { IBannerState } from './IBannerState';
import { BasePaginationRespone, dfBasePagination } from '../../base/BasePaginationRespone';
import { dfBaseShowDetail } from '../../base/BaseShowDetail';
import { BannerModel } from '../../models/banners/BannerModel';

const initPaginationResponse: BasePaginationRespone<BannerModel> = { listDatas: [], pagination: dfBasePagination };
const initialState: IBannerState = {
	status: eBaseActionStatus.idle,
	allBanners: [],
	selectedIds: [],
	showConfirm: false,
	paginationResponse: initPaginationResponse,
	showDetail: dfBaseShowDetail,
	detailResponse: undefined,
};
const bannerReducer = (state: IBannerState = initialState, action: BannerActionTypes): IBannerState => {
	switch (action.type) {
		case eBannerActionTypeIds.GET_ALL_REQUEST:
		case eBannerActionTypeIds.GET_PAGINATION_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_ALL_SUCCESS:
			return {
				...state,
				allBanners: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_PAGINATION_SUCCESS:
			return {
				...state,
				paginationResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_ALL_FAILURE:
			return {
				...state,
				allBanners: [],
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_PAGINATION_FAILURE:
			return {
				...state,
				paginationResponse: initPaginationResponse,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.CHANGE_SELECTED_IDS:
			return {
				...state,
				selectedIds: action.payload,
			};
		case eBannerActionTypeIds.SHOW_DETAIL:
			return {
				...state,
				showDetail: action.payload,
			};
		case eBannerActionTypeIds.SHOW_CONFIRM:
			return {
				...state,
				showConfirm: action.payload,
			};
		case eBannerActionTypeIds.SAVE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_DETAIL_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.DELETE_REQUEST:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.SAVE_SUCCESS:
			NotifyHelper.Success(action.payload || 'Thêm chuyên mục thành công.');
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_DETAIL_SUCCESS:
			return {
				...state,
				detailResponse: action.payload,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.DELETE_SUCCESS:
			NotifyHelper.Success(action.payload.toString() || 'Xóa chuyên mục thành công');
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.SAVE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				showDetail: dfBaseShowDetail,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.GET_DETAIL_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				detailResponse: undefined,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.DELETE_FAILURE:
			NotifyHelper.Error(action.payload.toString());
			return {
				...state,
				selectedIds: [],
				showConfirm: false,
				status: BaseGetActionStatus(action),
			};
		case eBannerActionTypeIds.NEED_RELOAD:
			return {
				...state,
				status: BaseGetActionStatus(action),
			};
		default:
			return state;
	}
};
export default bannerReducer;
