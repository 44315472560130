import { BaseAction } from '../../base/IBaseActionRespone';
import { BasePaginationRespone } from '../../base/BasePaginationRespone';
import { BaseSelectRequest } from '../../base/BaseSelectRequest';
import { CategoryModel } from '../../models/category/CategoryModel';
import { CategoryRequest } from '../../models/category/CategoryRequest';
import {
	CategoryActionTypes,
	CategoryAction_CreateOrUpdate_Request,
	CategoryAction_CreateOrUpdate_Success,
	CategoryAction_CreateOrUpdate_Failure,
	CategoryAction_FetchPagination_Request,
	CategoryAction_FetchPagination_Success,
	CategoryAction_FetchPagination_Failure,
} from './ICategoryActionTypes';

export const categoryActions = {
	// CREATE OR UPDATE
	createOrUpdateRequest: (data: CategoryRequest): CategoryAction_CreateOrUpdate_Request =>
		BaseAction(CategoryActionTypes.CREATE_OR_UPDATE_REQUEST, data),

	createOrUpdateSuccess: (category: CategoryModel): CategoryAction_CreateOrUpdate_Success =>
		BaseAction(CategoryActionTypes.CREATE_OR_UPDATE_SUCCESS, category),

	createOrUpdateFailure: (error: string): CategoryAction_CreateOrUpdate_Failure =>
		BaseAction(CategoryActionTypes.CREATE_OR_UPDATE_FAILURE, error),

	// FETCH PAGINATION
	fetchPaginationRequest: (request: BaseSelectRequest): CategoryAction_FetchPagination_Request =>
		BaseAction(CategoryActionTypes.FETCH_PAGINATION_REQUEST, request),

	fetchPaginationSuccess: (data: BasePaginationRespone<CategoryModel>): CategoryAction_FetchPagination_Success =>
		BaseAction(CategoryActionTypes.FETCH_PAGINATION_SUCCESS, data),

	fetchPaginationFailure: (error: string): CategoryAction_FetchPagination_Failure =>
		BaseAction(CategoryActionTypes.FETCH_PAGINATION_FAILURE, error),

	showDetail: (detail: CategoryModel): CategoryAction_CreateOrUpdate_Success =>
		BaseAction(CategoryActionTypes.CREATE_OR_UPDATE_SUCCESS, detail),

	showConfirm: (show: boolean): CategoryAction_FetchPagination_Failure =>
		BaseAction(CategoryActionTypes.FETCH_PAGINATION_FAILURE, show ? 'Xác nhận' : 'Không xác nhận'),
};
